import { Component, EventEmitter, Input, Output } from '@angular/core';
import { farEllipsisH, farEllipsisV } from 'magma/common/icons';
import { ContextMenu } from 'magma/components/shared/directives/contextMenu';
import { UserService } from 'services/user.service';

@Component({
  selector: 'apikey-action-toolbar',
  templateUrl: './apikey-action-toolbar.component.html',
  styleUrls: ['./apikey-action-toolbar.component.scss'],
})
export class ApiKeyActionToolbarComponent {
  farEllipsisH = farEllipsisH;
  farEllipsisV = farEllipsisV;

  constructor(private userService: UserService) { }

  @Input() apiKeyId!: string;

  @Output() onAction = new EventEmitter<void>();

  async remove(menu: ContextMenu) {
    await this.userService.revokeApiKey(this.apiKeyId).toPromise();
    this.onAction.next();
    menu.close();
  }

  async refresh(menu: ContextMenu) {
    await this.userService.regenerateApiKey(this.apiKeyId).toPromise();
    this.onAction.next();
    menu.close();
  }
}
