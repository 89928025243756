import { BillingService } from 'services/billing.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faInfoCircle } from 'magma/generated/fa-icons';
import { ModalService } from 'services/modal.service';
import { TeamData, TeamType } from 'shared/interfaces';

@Component({
  selector: 'switching-to-public-modal',
  templateUrl: 'switching-to-public-modal.component.pug',
  styleUrls: ['switching-to-public-modal.component.scss'],
})
export class SwitchingToPublicModalComponent {
  readonly infoIcon = faInfoCircle;

  constructor(private modalService: ModalService, private billingService: BillingService) { }

  @Output() close = new EventEmitter<boolean | undefined>();
  @Input() data!: { team: TeamData };

  onClose() {
    this.close.emit(undefined);
  }

  ok() {
    this.close.emit(true);
  }

  async upgrade() {
    await this.modalService.upgradeTeamSubscriptionModal({
      teamId: this.data.team._id, teamSlug: this.data.team.slug,
      alreadyOnProPlan: this.billingService.getTeamType(this.data.team) === TeamType.Professional
    });
  }

  async infoEducationalPlan() {
    await this.billingService.infoEducationalPlan();
  }

  async infoCommunityPlan() {
    await this.billingService.infoCommunityPlan();
  }
}
