import { ToastService } from 'magma/services/toast.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamInvite, TeamInviteToken } from 'shared/interfaces';
import { TeamService } from 'services/team.service';
import { faInfoCircle } from 'magma/common/icons';
import { storageSetString } from 'magma/services/storage';
import { AFTER_LOGIN_REDIRECT_URL_KEY } from 'components/login-signup-page.component';

export interface TeamJoinModalInput {
  invite: TeamInvite;
}

@Component({
  selector: 'team-join-modal',
  templateUrl: './team-join-modal.component.pug',
  styleUrls: ['./team-join-modal.component.scss'],
})
export class TeamJoinModalComponent {
  faInfoCircle = faInfoCircle;
  invite: TeamInviteToken | undefined;

  @Input() data!: TeamJoinModalInput;
  @Output() close = new EventEmitter();

  constructor(private teamService: TeamService, private toastService: ToastService) { }

  async joinTeam() {
    try {
      await this.teamService.joinTeam(this.data.invite);
      this.toastService.success({ message: `You have joined "${this.data.invite.team.name}"` });
      this.close.emit();
    } catch (e) {
      this.toastService.error({ message: 'Failed to accept invite', subtitle: e.message });
    }
  }

  onClose() {
    this.close.emit();
  }

  reauthenticate() {
    storageSetString(AFTER_LOGIN_REDIRECT_URL_KEY, `/invite/${this.data.invite.token}`);
    window.location.href = `/auth/organization/${this.data.invite.team._id}`;
    return;
  }
}
