import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { uniq } from 'lodash';
import { blazeIcon } from 'magma/common/icons';
import { faPlus } from 'magma/generated/fa-icons';
import { ToastService } from 'magma/services/toast.service';
import { BillingService } from 'services/billing.service';
import { InvitationService } from 'services/invitation.service';
import { TeamService } from 'services/team.service';
import {
  CC_PRODUCT_EDITOR_PRICE_M, CC_PRODUCT_REVIEWER_PRICE_M, CC_PRODUCT_VIEWER_PRICE_M,
  MAX_FREE_PRIVATE_ARTSPACE_MEMBERS
} from 'shared/billing';
import { TEAM_NAME_MAX_LENGTH } from 'shared/constants';
import { ProductPrice, TeamData, TeamInviteToken } from 'shared/interfaces';
import { routeToTeam } from 'shared/utils';

@Component({
  selector: 'create-team-modal',
  templateUrl: './create-team-modal.component.pug',
  styleUrls: ['./create-team-modal.component.scss'],
})
export class CreateTeamModalComponent implements OnInit {
  readonly proIcon = blazeIcon;
  readonly plusIcon = faPlus;
  readonly usingWithOptions = ['Just me', 'Community', 'Friends', 'Students', 'Coworkers', 'None of the above'];
  readonly TEAM_NAME_MAX_LENGTH = TEAM_NAME_MAX_LENGTH;
  readonly MAX_FREE_PRIVATE_ARTSPACE_MEMBERS = MAX_FREE_PRIVATE_ARTSPACE_MEMBERS;
  prices: Map<string, ProductPrice> = new Map();
  @Output() close = new EventEmitter<undefined>();
  step = 0;
  name = '';
  type = '';
  usingWith = '';
  isSubmitting = false;
  emails = [{ email: '' }, { email: '' }, { email: '' }];
  error: string | undefined = undefined;
  invite: TeamInviteToken | undefined = undefined;
  team: TeamData | undefined = undefined;
  intervalShort = 'mo';
  constructor(
    private teamService: TeamService,
    private router: Router,
    private invitationService: InvitationService,
    private toastService: ToastService,
    private billingService: BillingService,
  ) {
  }
  async ngOnInit() {
    const allPrices = await this.billingService.getAllProductsPrices();
    this.prices = new Map(allPrices.map(p => [p.id, p]));
    if (IS_HOSTED) this.type = 'private';
  }
  get canCreate() {
    return !!this.name && !!this.type;
  }
  get inviteUrl() {
    return this.invite ? `${location.protocol}//${location.host}/invite/${this.invite.token}` : '';
  }
  get editorPrice() {
    return this.prices.get(CC_PRODUCT_EDITOR_PRICE_M)?.amount ?? 0;
  }
  get reviewerPrice() {
    return this.prices.get(CC_PRODUCT_REVIEWER_PRICE_M)?.amount ?? 0;
  }
  get viewerPrice() {
    return this.prices.get(CC_PRODUCT_VIEWER_PRICE_M)?.amount ?? 0;
  }
  onClose() {
    this.close.emit();
  }
  async create() {
    if (!this.canCreate) return;

    this.isSubmitting = true;

    try {
      this.team = await this.teamService.createTeam(this.name, this.type === 'public', this.usingWith);
      this.invite = await this.invitationService.getInviteToken(this.team._id);
      void this.router.navigate(routeToTeam(this.team.slug));
      if (IS_HOSTED) this.onClose();
      else this.step++;
    } catch (e) {
      DEVELOPMENT && console.error(e);
      this.error = e.message;
    } finally {
      this.isSubmitting = false;
    }
  }
  skip() {
    this.step++;
  }
  async sendInvitations() {
    const emails = uniq(this.emails.map(x => x.email.trim().toLowerCase()).filter(x => x));

    if (this.team && emails.length) {
      this.isSubmitting = true;

      try {
        await this.invitationService.inviteTeamsMembers(this.team?._id, emails.map(email => ({ email }))).toPromise();
      } catch (e) {
        DEVELOPMENT && console.error(e);
        this.toastService.error({ message: e.message });
      } finally {
        this.isSubmitting = false;
      }
    }

    this.step++;
  }
  finish() {
    this.onClose();
  }
  async upgrade() {
    try {
      const items = [{ plan: CC_PRODUCT_EDITOR_PRICE_M, quantity: 1 }];
      await this.billingService.upgradeCurrentTeam(this.team!._id, items);
    } catch (e) {
      this.toastService.error({ message: 'Failed to open stripe', subtitle: e.message });
    }
  }
}
