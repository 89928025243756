import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { defer } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ResourcesLinksService } from 'services/resources-links.service';

@UntilDestroy()
@Component({
  selector: 'resources-links',
  templateUrl: './resources-links.component.pug',
  styleUrls: ['./resources-links.component.scss'],
})
export class ResourcesLinksComponent {
  @Input() limit: number | undefined = undefined;
  @Input() responsive = false;

  resourcesLinks$ = defer(() => this.resourcesLinksService.getResourcesLinks({ page: 1, ...this.limit && { pageSize: this.limit } }))
    .pipe(
      map(res => res?.data), // got null here for some reson in production
      startWith(undefined),
    );

  constructor(private resourcesLinksService: ResourcesLinksService) {
  }
}
