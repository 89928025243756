import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { faKey, faLink, farCheckCircle } from 'magma/common/icons';
import { EntitiesService } from 'services/entities.service';
import { UserService } from 'services/user.service';
import { EntityData, TeamData } from 'shared/interfaces';
import { Analytics, MsgBackground } from 'magma/common/interfaces';
import { getEntityAbsoluteUrlWithPassword } from 'shared/product-info';
import { Model } from 'magma/services/model';
import { getEntityPassword, setEntityPassword } from 'magma/common/clientUtils';
import { ToastService } from 'magma/services/toast.service';
import { ITrackService } from 'magma/services/track.service.interface';
import { TeamService } from 'services/team.service';
import { isEntityOwner } from 'components/utils';

const discordInviteLink = PRODUCT_INFO.discordInviteLinks?.drawingBuddiesPage;

export interface ShareEntityModalInput {
  entity: EntityData;
  openedBy: string;
  model?: Model;
  team: TeamData | undefined;
}

@Component({
  selector: 'share-entity-modal',
  templateUrl: './share-entity-modal.component.pug',
  styleUrls: ['./share-entity-modal.component.scss'],
})
export class ShareEntityModalComponent implements OnInit {
  readonly faKey = faKey;
  readonly faLink = faLink;
  readonly discordInviteLink = discordInviteLink;

  password: string | undefined = undefined;
  maskedPassword = true;

  private _data!: ShareEntityModalInput;
  @Input() get data() {
    return this._data;
  }
  set data(value) {
    this._data = value;

    if (value.entity.hasPassword) {
      this.password = value.entity.password ?? getEntityPassword(value.entity.shortId);

      if (!this.password) {
        this.entitiesService.getPassword(value.entity._id)
          .then(password => this.password = password)
          .catch(() => { }); // ignore if we don't have access to the password
      }
    } else {
      this.password = undefined;
    }
  }

  get hasPassword() {
    return this.canChangePassword ? !!this.password : !!this.data.entity.hasPassword;
  }

  get entityUrl() {
    return getEntityAbsoluteUrlWithPassword({
      ...this._data.entity,
      hasPassword: !this.maskedPassword,
      password: (this.password && !this.maskedPassword) ? this.password : undefined,
    });
  }

  get proBlock() {
    const user = this.userService.user;
    if (IS_HOSTED || user?.isSuperAdmin || user?.pro) return false;
    if (this.teamService.isTeamPro(this.data.entity.team)) return false;
    return true;
  }

  get canChangePassword() {
    const { entity, model } = this.data;
    if (this.userService.isSuperAdmin()) return true;
    if (this.userService.user && isEntityOwner(entity, this.userService.user)) return true;
    if (model?.isOwnerOrAdmin(entity.shortId)) return true;
    if (entity.userRole === 'admin') return true;
    return false;
  }

  @Output() close = new EventEmitter();

  constructor(
    private entitiesService: EntitiesService,
    private userService: UserService,
    private track: ITrackService,
    private toastService: ToastService,
    private teamService: TeamService,
  ) {
  }

  ngOnInit() {
    this.track.event(Analytics.OpenShareModal, { eventSource: this.data.openedBy });
  }

  copyEvent(method: string) {
    this.track.event(Analytics.CopyDrawingLink, { eventSource: 'share-modal', method });
  }

  onClose() {
    this.close.emit();
  }

  maskPassword(masked: boolean) {
    this.maskedPassword = masked;
  }

  async updatePassword(password: string | null | undefined) {
    this.password = password || undefined;

    try {
      if (!this.password) {
        await this.updatePasswordValue(undefined);
      } else if (this.password !== this.data.entity.password) {
        await this.updatePasswordValue(this.password);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to update password', subtitle: e.message });
      DEVELOPMENT && console.error(e);
    }
  }

  private async updatePasswordValue(password: string | undefined) {
    const { entity, model } = this.data;

    if (model) {
      await model.server.updateDrawingPassword(model.connId, password ?? '');
    } else {
      if (password === undefined) {
        await this.entitiesService.resetPassword(entity._id);
      } else {
        await this.entitiesService.updatePassword(entity._id, password);
      }
    }

    setEntityPassword(entity.shortId, password);

    this.toastService.info({ message: 'Sharing permissions updated', background: MsgBackground.Dark, icon: farCheckCircle });
  }
}
