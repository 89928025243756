import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[btnSpinner]',
  host: {
    class: 'btn-with-spinner',
  },
})
export class BtnSpinnerDirective {
  private spinner: HTMLElement | undefined = undefined;
  @Input() get btnSpinner() {
    return !this.spinner;
  }
  set btnSpinner(value) {
    if (value && !this.spinner) {
      this.spinner = document.createElement('span');
      this.spinner.className = 'spinner spinner-inline';
      this.element.nativeElement.appendChild(this.spinner);
      this.element.nativeElement.disabled = true;
    } else if (!value && this.spinner) {
      this.element.nativeElement.removeChild(this.spinner);
      this.spinner = undefined;
      this.element.nativeElement.disabled = false;
    }
  }
  constructor(private element: ElementRef<HTMLButtonElement>) {
  }
}
