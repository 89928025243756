import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Component } from '@angular/core';
import { TeamsQuery } from 'services/team.query';
import { ModalService } from 'services/modal.service';
import { farEllipsisH, farEllipsisV, faTrash } from 'magma/common/icons';
import { faStar } from 'magma-editor/src/ts/generated/fa-icons/faStar';
import { faUserPlus } from 'magma-editor/src/ts/generated/fa-icons/faUserPlus';
import { filter, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { TeamService } from 'services/team.service';
import { TeamData, TeamRole } from '../../../../../shared/interfaces';
import { TeamMembersQuery } from '../../../services/team-members.query';

@UntilDestroy()
@Component({
  selector: 'team-settings-contributors',
  templateUrl: './team-settings-contributors.component.pug',
  styleUrls: ['../../account-common.component.scss',
    './team-settings-contributors.component.scss'],
  host: { class: 'use-magma-styles' },
})
export class TeamSettingsContributorsComponent {
  farEllipsisH = farEllipsisH;
  farEllipsisV = farEllipsisV;
  faTrash = faTrash;
  faUserPlus = faUserPlus;
  faStar = faStar;

  activeTeam$ = this.teamsQuery.selectActive();
  activeTeam: TeamData | undefined;

  members$ = this.teamMembersQuery.selectAll();
  reload$ = new BehaviorSubject(null);

  contributors$ = combineLatest([
    this.teamsQuery.selectActive(),
    this.reload$,
  ]).pipe(filter(([t]) => !!t), switchMap(([team]) => this.teamService.getContributors(team!._id)));

  constructor(
    private teamsQuery: TeamsQuery,
    private teamMembersQuery: TeamMembersQuery,
    private modalService: ModalService,
    private teamService: TeamService,
  ) {
    this.activeTeam$.pipe(untilDestroyed(this)).subscribe(team => {
      this.activeTeam = team;
    });
  }

  get teamId() {
    return this.activeTeam!._id;
  }

  async deleteRole(role: TeamRole) {
    // TODO add confirmation modal
    await this.teamService.deleteRole(this.teamId, role._id).toPromise();
    this.reload$.next(null);
  }

  async updatePermissionFlags(role: TeamRole) {
    if (!this.activeTeam) return;
    const res = await this.modalService.editRole({
      _id: role._id,
      team: this.activeTeam,
      flags: role.flags,
      name: role.name,
      projects: role.projects ?? [],
      entities: role.entities ?? [],
      type: role.type,
    });
    if (res) {
      await this.teamService.editRole(this.teamId, role._id, res).toPromise();
    }
    this.reload$.next(null);
  }
}
