import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TeamSelectorService {
  toggled$ = new Subject<void>();
  toggle() {
    this.toggled$.next();
  }
}
