import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { PresentationActionData, PresentationActions } from 'magma/common/interfaces';
import moment from 'moment';


@Component({
  selector: 'presentation-action-modal',
  templateUrl: './presentation-action-modal.component.pug',
  styleUrls: ['./presentation-action-modal.component.scss'],
})
export class PresentationActionModal {
  invitedUsers: { [id: number]: boolean } = {};
  selectAllUsers = false;
  timeLeft = '';
  interval: NodeJS.Timer | undefined;
  buttonAction: { cancel?: string, submit?: string, submitCallback?: () => void, cancelCallback?: () => void, cancelDisabled?: boolean } = {};

  @Output() close = new EventEmitter<boolean>();
  @Input() data: PresentationActionData = { title: '', mode: PresentationActions.InvitedToPresentation, viewers: 0, state: undefined};

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    
    if (this.data.timer) {
      const endDate = moment(new Date().getTime() + this.data.timer);
      this.timeLeft = this.getTime(endDate);
      this.interval = setInterval(() => {
        this.timeLeft = this.getTime(endDate);
        if (this.timeLeft === '00' || this.timeLeft === '00:00' || moment().isAfter(endDate)) this.onClose(true);
      }, 1000);
    }

    this.buttonAction = this.actionButtons(this.actionMode);
    this.cdr.detectChanges();
  }

  get title() {
    return this.data.title;
  }

  get actionMode() {
    return this.data.mode;
  }

  get username() {
    return this.data.user;
  }

  get isActionForced() {
    return this.data.forced;
  }

  get viewers() {
    return this.data.viewers;
  }

  get invitedViewers() {
    const viewers = this.data.viewers || 0;
    return viewers > 0 ? viewers - 1 : viewers;
  }

  get isFollowingHostActive() {
    const { state } = this.data;
    return state?.followingHostLocationEnforced || state?.followingHostViewportEnforced;
  }

  actionButtons(actionMode: PresentationActions) {
    switch (actionMode) {
      case PresentationActions.inactivePresentationHost:
        return {
          submit: 'OK',
          submitCallback: () => this.onClose(false)
        };
      case PresentationActions.InvitedToPresentation:
        return {
          cancel: 'Cancel',
          cancelCallback: () => this.onClose(false),
          cancelDisabled: this.isActionForced,
          submit: this.isActionForced ? 'Join now' : 'Join',
          submitCallback: () => this.onClose(true),
        };
      case PresentationActions.JoinPresentation:
        return {
          cancel: 'Cancel',
          cancelCallback: () => this.onClose(false),
          submit: 'Join',
          submitCallback: () => this.onClose(true)
        };
      case PresentationActions.LeavePresentation:
        return {
          cancel: 'Cancel',
          cancelCallback: () => this.onClose(false),
          submit: 'Leave',
          submitCallback: () => this.onClose(true)
        };
      case PresentationActions.EndPresentation:
        return {
          cancel: 'Cancel',
          cancelCallback: () => this.onClose(false),
          submit: 'End',
          submitCallback: () => this.onClose(true)
        };
      case PresentationActions.presentationHostDisconnected:
        return {
          submit: 'Leave',
          submitCallback: () => this.onClose(true)
        };
      case PresentationActions.takeOverPresentation:
        return {
          cancel: 'End',
          cancelCallback: () => this.onClose(false),
          submit: 'Take over',
          submitCallback: () => this.onClose(true)
        };
      case PresentationActions.reclaimPresentation:
        return {
          cancel: 'Cancel',
          cancelCallback: () => this.onClose(false),
          submit: 'Reclaim',
          submitCallback: () => this.onClose(true)
        };
      case PresentationActions.presentationIsOver:
        return {
          submit: 'OK',
          submitCallback: () => this.onClose(true)
        };
      case PresentationActions.givenPresenterRole:
        return {
          submit: 'Take over',
          submitCallback: () => this.onClose(true),
          cancel: 'Decline',
          cancelCallback: () => this.onClose(false),
        };
      default:
        return {};
    }
  }

  private getTimeFormat() {
    switch (this.actionMode) {
      case PresentationActions.InvitedToPresentation:
      case PresentationActions.JoinPresentation:
      case PresentationActions.presentationHostDisconnected:
        return 'ss';
      default:
        return 'mm:ss';
    }
  }

  private getTime(endDate: moment.Moment) {
    const format = this.getTimeFormat();
    const timeLeft = moment(endDate.diff(moment()));
    const formatted = timeLeft.format(format);
    return formatted;
  }
  
  onClose(confirmed = false) {
    if (confirmed) {
      this.close.emit(true);
    } else if (confirmed === false) {
      this.close.emit(false);
    } else {
      this.close.emit(undefined);
    }
  }
}
