<section class="error-page-wrapper">
  <div class="container">
    <svg-icon class="lock-bg" [icon]="faLock"></svg-icon>
    <div class="title-wrapper">
      <div class="img-wrapper"><img id="logo-embed" src="/assets/c2f-logo.8621a181.svg" /></div>
      <span>Your subscription has expired</span>
    </div>

    <span class="extra">
      Please visit <a routerLink="/">{{vars.appOrigin}}</a> and renew your subscription
    </span>
  </div>
</section>
