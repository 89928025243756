import { ToastService } from 'magma/services/toast.service';
import { Component, Input } from '@angular/core';
import { faInfoCircle } from 'magma/common/icons';
import { TeamData, TeamType } from 'shared/interfaces';
import { ModalService } from 'services/modal.service';
import { TeamMembersQuery } from 'services/team-members.query';
import { BillingService } from 'services/billing.service';
import { MAX_FREE_PRIVATE_ARTSPACE_MEMBERS } from 'shared/billing';
import { TeamService } from 'services/team.service';
import { Feature } from 'magma/common/interfaces';

@Component({
  selector: 'team-invite-member-warning',
  templateUrl: './team-invite-member-warning.component.pug',
  styleUrls: ['./team-invite-member-warning.component.scss'],
})
export class TeamInviteMembersWarningComponent {
  readonly faInfoCircle = faInfoCircle;
  maxMembers = MAX_FREE_PRIVATE_ARTSPACE_MEMBERS;

  @Input() boxed = false;
  @Input() light = false;
  @Input() team!: TeamData;

  constructor(
    private modalService: ModalService,
    private teamMembersQuery: TeamMembersQuery,
    private billingService: BillingService,
    private teamService: TeamService,
    private toastService: ToastService
  ) { }

  get reachedMaxUsersOnFreePlan() {
    return this.billingService.getTeamType(this.team) === TeamType.Private &&
      this.teamMembersQuery.getCount() >= MAX_FREE_PRIVATE_ARTSPACE_MEMBERS &&
      !this.team.featureFlags?.includes(Feature.NoUserLimit);
  }

  async upgrade() {
    if (this.team) {
      await this.modalService.upgradeTeamSubscriptionModal({
        teamId: this.team._id,
        teamSlug: this.team.slug,
        alreadyOnProPlan: this.billingService.getTeamType(this.team) === TeamType.Professional
      });
    } else {
      DEVELOPMENT && console.error('Missing active team');
    }
  }

  async switchToPublicMode() {
    const confirmed = await this.modalService.switchingToPublic(this.team);
    if (confirmed) {
      try {
        await this.teamService.updateTeam(this.team, { isPublic: true });
        this.toastService.success({ message: `Team mode changed to Public` });
      } catch (e) {
        this.toastService.success({ message: e.message });
      }
    }
  }
}
