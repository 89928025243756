import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'entity-drag-ghost',
  template: `
    <div class="drag-ghost" *ngIf="visible" [@ghostAnimation]>
      <span class="count">{{ count }}</span>
      <span class="title">{{ title }}{{ count > 1 ? '...' : ''}}</span>
    </div>
  `,
  styleUrls: ['./entity-drag-ghost.component.scss'],
  animations: [
    trigger('ghostAnimation', [
      transition(':enter', [
        animate('700ms', keyframes([
          style({ transform: 'scale(1,1) translateY(0)' }),
          style({ transform: 'scale(1.1, 0.9) translateY(0)' }),
          style({ transform: 'scale(0.9, 1.1) translateY(-10px)' }),
          style({ transform: 'scale(1.05, 0.95) translateY(0)' }),
          style({ transform: 'scale(1,1) translateY(-7px)' }),
          style({ transform: 'scale(1,1) translateY(0)' }),
        ])),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class EntityDragGhostComponent {
  @Input() visible = false;
  @Input() count = 0;
  @Input() title = '';
}
