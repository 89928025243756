import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PRESENTATION_INACTIVE_HOST_TIME } from 'magma/common/constants';
import { PresentationModeStartState, PresentationModeState, User } from 'magma/common/interfaces';
import { hasPermission } from 'magma/common/userRole';

@Component({
  selector: 'start-presentation-modal',
  templateUrl: './start-presentation-modal.component.pug',
  styleUrls: ['./start-presentation-modal.component.scss'],
})
export class StartPresentationModal {
  readonly url = window.location.href;
  hideUiInitially = false;
  followHostLocationInitially = true;
  followHostViewInitially = true;
  // makeProjectPublic = true;
  invitedUsers: { [id: string]: boolean; } = {};
  selectAllUsers = false;
  isForced = false;

  @Output() close = new EventEmitter<PresentationModeStartState>();
  @Input() data: any = {};

  constructor() { }

  get users(): User[] {
    return this.data.currentCanvasUsers;
  }

  get isSuperAdmin() {
    return this.data.isSuperAdmin;
  }

  hasCoPresenterPermission(user: User) {
    return hasPermission(this.data.drawing, user, 'takeOverPresentation');
  }

  toggleUsersSelection() {
    this.users.forEach(u => this.invitedUsers[u.uniqId] = this.selectAllUsers);
  }

  onClose(confirmed = false) {
    if (confirmed) {
      this.close.emit({
        participantsUiHidden: this.hideUiInitially,
        invitedUsers: this.users.filter(u => this.invitedUsers[u.uniqId]).map(u => u.uniqId),
        isForced: this.isForced,
        followingHostLocationEnforced: this.followHostLocationInitially,
        followingHostViewportEnforced: this.followHostViewInitially
      });
    } else {
      this.close.emit(undefined);
    }
  }

  get hostTimeout() {
    return PRESENTATION_INACTIVE_HOST_TIME;
  }
}
