import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COUNTRIES_LIST } from 'shared/constants';
import { CustomerBillingInfo } from 'shared/interfaces';


@Component({
  selector: 'billing-info',
  templateUrl: 'billing-info.component.pug',
  styleUrls: ['./billing-info.component.scss'],
})
export class BillingInfoComponent {
  @Output() submitBillingInfo = new EventEmitter<CustomerBillingInfo>();
  @Input() billingInfo!: CustomerBillingInfo;

  countries = COUNTRIES_LIST;
  updatingBillingInfo = false;
  isSubmitting = false;

  open() {
    this.updatingBillingInfo = true;
  }

  close() {
    this.updatingBillingInfo = false;
  }

  submit() {
    this.submitBillingInfo.emit(this.billingInfo);
    this.close();
  }
}
