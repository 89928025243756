import { Component } from '@angular/core';
import { signUps } from 'magma/common/data';
import { Permission } from 'magma/common/interfaces';
import { BillingService } from 'services/billing.service';
import { TeamMembersService } from 'services/team-members.service';

@Component({
  selector: 'team-settings',
  templateUrl: './team-settings.component.pug',
  styleUrls: ['./team-settings.component.scss'],
  host: { class: 'use-magma-styles' },
})
export class TeamSettingsComponent {
  readonly samlActive = signUps.includes('saml');
  isSelfHosted = IS_HOSTED;

  constructor(private teamMembersService: TeamMembersService, private billingService: BillingService) { 
    // prefetch prices
    this.billingService.getAllProductsPrices().catch(e => DEVELOPMENT && console.error(e));
  }

  get canManageTeam() {
    return this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeam]);
  }

  get canManageTeamRoles() {
    return this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeamRoles]);
  }

  get canManageTeamBilling() {
    return this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeamBilling]);
  }

  get canAddTeamMembers() {
    return this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeamMembers]);
  }
}
