import { farExclamationCircle } from 'magma/common/icons';
import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface LeaveTeamModalInput {
  teamName: string;
  isLastOwner: boolean;
}

@Component({
  selector: 'leave-team-modal',
  templateUrl: 'leave-team-modal.component.pug',
  styleUrls: ['leave-team-modal.component.scss'],
})
export class LeaveTeamModalComponent {
  farExclamationCircle = farExclamationCircle;
  @Output() close = new EventEmitter<boolean | undefined>();
  @Input() data!: LeaveTeamModalInput;

  onYes() {
    this.close.emit(true);
  }

  onClose() {
    this.close.emit(undefined);
  }
}
