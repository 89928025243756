import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { farCheckCircle } from 'magma/generated/fa-icons';
import { UserService } from 'services/user.service';

@UntilDestroy()
@Component({
  selector: 'oauth',
  templateUrl: './oauth.component.pug',
  host: {
    class: 'use-magma-styles',
    '[class.no-team-selector]': 'noTeamSelector',
  },
  styleUrls: ['./oauth.component.scss'],
})
export class OauthComponent implements OnInit {
  faCheckCircle = farCheckCircle;

  loadComplete = false;
  showPage = false;
  showLoading = false;

  private client_id: string | null = null;
  private redirect_uri: string | null = null;
  private state: string | null = null;
  private code: string | null = null;

  constructor(
    private userService: UserService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    const params = new URLSearchParams(window.location.search);
    this.client_id = params.get('client_id');
    this.redirect_uri = params.get('redirect_uri');
    this.state = params.get('state');
    if (!this.client_id || !this.redirect_uri || !this.state) this.goHome();
    else this.showPage = true;
  }

  close() {
    if (this.code) window.location.href = `${this.redirect_uri}?code=${this.code}&state=${this.state}`;
    else this.redirect_uri ? window.location.href = `${this.redirect_uri}?error=access_denied&error_description=The+user+denied+the+request&state=${this.state}` : this.goHome();
  }
  
  async allow() {
    this.showLoading = true;
    if (!this.client_id || !this.state || !this.redirect_uri) return;
    try {
      this.code = await this.userService.createOauthCode(this.client_id, this.state, this.redirect_uri);
      this.showLoading = false;
      if (this.code) this.loadComplete = true;
      setTimeout(() => this.close(), 2000);
    } catch (error) {
      this.close();
    }
  }

  goHome() {
    void this.router.navigate(['my']);
  }
}
