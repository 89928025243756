import { Injectable } from '@angular/core';
import { defer, NEVER, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RpcService } from './rpc.service';
import { DreamboothInput, AsyncJobStatusUpdate, AiModel, AiModelUpdate, AsyncJobStatusClientUpdate } from 'magma/common/aiInterfaces';

@Injectable({ providedIn: 'root' })
export class AiService {
  constructor(private rpc: RpcService) { }

  async uploadAilFile(file: Uint8Array) {
    return this.rpc.ai.uploadAilFile(file); // TODO confirm how big files are working
  }

  async createAiModel(model: AiModel, isFromJob: boolean): Promise<void> {
    return this.rpc.ai.createAiModel(model, isFromJob);
  }

  enableAiModel(rId: string, enabled: boolean): Promise<void> {
    return this.rpc.ai.enableAiModel(rId, enabled);
  }

  updateAiModel(rId: string, update: AiModelUpdate): Promise<void> {
    return this.rpc.ai.updateAiModel(rId, update);
  }

  deleteAiModel(rId: string): Promise<void> {
    return this.rpc.ai.deleteAiModel(rId);
  }

  getAiModels(teamId: string | null) {
    return this.rpc.ai.getAiModels(teamId);
  }

  queueDreamboothJob(name: string, input: DreamboothInput, inputImageMetadata: { [key: string]: string }, teamId: string | null) {
    return this.rpc.ai.queueDreamboothJob(name, input, inputImageMetadata, teamId);
  }

  getJobs(teamId: string | null) {
    return this.rpc.ai.getJobs(teamId);
  }

  cancelJob(jobId: string) {
    return this.rpc.ai.cancelJob(jobId);
  }

  removeJob(jobId: string) {
    return this.rpc.ai.removeJob(jobId);
  }

  observeJobs(teamId: string | null): Observable<AsyncJobStatusClientUpdate> {
    return this.rpc.isConnected$.pipe(switchMap(status => {
      if (status) {
        return defer(async () => this.rpc.ai.observeJobs(teamId)).pipe(switchMap(obs => obs));
      } else {
        return NEVER;
      }
    }));
  }
}
