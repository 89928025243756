import { Component, EventEmitter, Input, Output } from '@angular/core';
import { farExclamationTriangle, blazeIcon } from 'magma/common/icons';
import { BillingInterval } from 'shared/interfaces';

@Component({
  selector: 'change-plan-modal',
  templateUrl: 'change-plan-modal.component.pug',
  styleUrls: ['change-plan-modal.component.scss'],
})
export class ChangePlanModal {
  readonly warningIcon = farExclamationTriangle;
  readonly proIcon = blazeIcon;
  @Input() data: { currentInterval: BillingInterval | undefined; isTrial: boolean } = { currentInterval: undefined, isTrial: false };
  @Output() close = new EventEmitter<BillingInterval | undefined>();

  monthlyOff = 999;
  yearlyOff = 9999;

  cancel() {
    this.close.emit(undefined);
  }

  switchTo(interval: BillingInterval) {
    this.close.emit(interval);
  }
}
