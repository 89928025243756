import { TEAM_PRODUCTS } from 'shared/billing';
import { Participant, ROLE_TYPES_READ_ONLY, RoleType, TeamData, TeamMember, TeamRole } from '../../../../../shared/interfaces';
import { Permission } from 'magma/common/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { faPen, faTrash, faUsers, farEllipsisH, farEllipsisV } from 'magma/common/icons';
import { isEqual } from 'lodash';

import { Component } from '@angular/core';
import { ModalService } from 'services/modal.service';
import { TeamMembersQuery } from '../../../services/team-members.query';
import { TeamMembersService } from 'services/team-members.service';
import { TeamService } from 'services/team.service';
import { TeamsQuery } from 'services/team.query';
import { ToastService } from 'magma/services/toast.service';
import { UserService } from 'services/user.service';
import { faBullseyeArrow } from './../../../../../../magma/src/ts/generated/fa-icons/faBullseyeArrow';
import { faStar } from 'magma-editor/src/ts/generated/fa-icons/faStar';
import { faUserPlus } from 'magma-editor/src/ts/generated/fa-icons/faUserPlus';
import { getMinimumProductRequired } from 'shared/utils';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'team-settings-member-roles',
  templateUrl: './team-settings-member-roles.component.pug',
  styleUrls: ['../../account-common.component.scss',
    './team-settings-member-roles.component.scss'],
  host: { class: 'use-magma-styles' },
})
export class TeamSettingsMemberRolesComponent {
  RoleType = RoleType;
  farEllipsisH = farEllipsisH;
  farEllipsisV = farEllipsisV;
  faTrash = faTrash;
  faUserPlus = faUserPlus;
  faStar = faStar;
  faPen = faPen;
  faUsers = faUsers;
  faBullseyeArrow = faBullseyeArrow;

  teamMembers$ = this.teamMembersQuery.selectAll();
  activeTeam$ = this.teamsQuery.selectActive();
  activeTeam: TeamData | undefined;

  members$ = this.teamMembersQuery.selectAll();

  teamMembers: TeamMember[] = [];

  roles$ = this.teamService.teamRoles$.pipe(
    map(roles => roles.filter(r => r.type !== RoleType.Owner && r.type !== RoleType.Blocked).sort((a, b) => a.type === RoleType.Everyone ? -1 : 1))
  );

  constructor(
    private teamsQuery: TeamsQuery,
    private teamMembersQuery: TeamMembersQuery,
    private teamMembersService: TeamMembersService,
    private toastService: ToastService,
    private modalService: ModalService,
    private userService: UserService,
    private teamService: TeamService,
  ) {
    this.activeTeam$.pipe(untilDestroyed(this)).subscribe(team => {
      this.activeTeam = team;
    });
    this.teamMembers$.pipe(untilDestroyed(this)).subscribe(members => {
      this.teamMembers = members;
    });
  }

  getMinimumProductRequired(role: TeamRole) {
    const productId = getMinimumProductRequired(role);
    if (!productId) {
      return undefined;
    }
    return TEAM_PRODUCTS.get(productId)?.name ?? 'Invalid plan';
  }

  get canRemoveMembers() {
    return this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeamMembers]);
  }

  isMe(member: TeamMember) {
    return this.userService.userId === member.user._id;
  }

  get teamId() {
    return this.activeTeam!._id;
  }

  canDeleteRole(role: TeamRole) {
    if (role.type === null) return true;
    return !ROLE_TYPES_READ_ONLY.includes(role.type);
  }

  async createRole() {
    const res = await this.modalService.createRole();
    if (res) {
      try {
        await this.teamService.createRole(this.activeTeam!._id, res).toPromise();
        this.teamService.reloadTeamRoles$.next(false);
      } catch (e) {
        this.toastService.error({ message: 'Failed to create role', subtitle: e.message });
      }
    }
  }

  async deleteRole(role: TeamRole) {
    // TODO consider updating style of this modal
    const res = await this.modalService.question('Are you sure want to delete this role ? This action is permanent and cannot be reverted.');
    if (res) {
      try {
        await this.teamService.deleteRole(this.teamId, role._id).toPromise();
        this.teamService.reloadTeamRoles$.next(false);
      } catch (e) {
        this.toastService.error({ message: 'Failed to remove role', subtitle: e.message });
      }
    }
  }

  async updatePermissionFlags(role: TeamRole) {
    if (!this.activeTeam) {
      DEVELOPMENT && console.error('No active team');
      return;
    }
    const res = await this.modalService.editRole({
      _id: role._id,
      team: this.activeTeam,
      flags: role.flags,
      name: role.name,
      projects: role.projects ?? [],
      entities: role.entities ?? [],
      type: role.type,
    });
    if (res && !isEqual(res, {})) {
      try {
        await this.teamService.editRole(this.teamId, role._id, res).toPromise();
      } catch (e) {
        this.toastService.error({ message: 'Failed to update role', subtitle: e.message });
      }
    }
    this.teamService.reloadTeamRoles$.next(false);

  }

  getMembersForRole(role: TeamRole): Participant[] {
    return this.teamMembers.filter(m => m.roles.find(r => r._id === role._id)).map(member => {
      return {
        isOnline: false, // TODO
        role: member.roles.find(r => r.type === RoleType.Owner) ? 'owner' : undefined,
        _id: member.user._id,
        name: member.user.name,
        avatar: member.user.avatar,
        userType: member.user.userType,
      };
    });
  }
}
