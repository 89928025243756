<div class="error-page">
  <div class="error-page-content"><a class="logo" href="/">
      <div></div>
    </a>
    <h1>{{ header }}</h1>
    <div class="title">{{ title }}</div>

    <ng-container *ngIf="(user$ | async) as user; else noUser">
      <div class="subtitle text-muted">
        <span>You're currently signed in as
          <span class="user-name">{{ user }}</span> · <button class="btn-link" (click)="signOut()">Switch account</button>
        </span>
      </div>
      <div><a class="btn btn-outline-light error-page-btn" routerLink="/">Return to dashboard</a></div>
    </ng-container>
    <ng-template #noUser>
      <div>
        <a class="btn btn-outline-light btn-lg btn-wide error-page-btn" routerLink="/login">Sign in</a>
      </div>
    </ng-template>
  </div>
</div>
