import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AccordionComponent } from 'components/accordion/accordion.component';
import { AccountApiComponent } from 'components/account-api/account-api.component';
import { AccountBillingComponent } from 'components/account-billing/account-billing.component';
import { AccountFeedbackComponent } from 'components/account-feedback.component';
import { AccountNotificationsComponent } from 'components/account-notifications/account-notifications.component';
import { AccountPageGuard } from 'guards/account-page.guard';
import { AccountPasswordComponent } from 'components/account-password/account-password.component';
import { AccountPrivacyComponent } from 'components/account-privacy/account-privacy.component';
import { AccountSettingsPageComponent } from 'components/account-settings.component';
import { AddUsersComponent } from 'components/add-users.component';
import { AgTooltip } from 'magma/components/shared/directives/tooltip';
import { AnnouncementModalComponent } from 'components/modals/announcement-modal/announcement-modal.component';
import { ApiInterceptor } from 'http-interceptors/api-interceptor';
import { ApiKeyActionToolbarComponent } from 'components/apikey-action-toolbar/apikey-action-toolbar.component';
import { AppComponent } from 'components/app.component';
import { AppErrorHandler } from 'magma/services/appErrorHandler';
import { AppGuard } from 'guards/app.guard';
import { AppNotificationService } from 'services/app-notification.service';
import { AppNotificationsComponent } from 'components/app-notifications/app-notifications.component';
import { AppRoutingModule } from 'components/app-routing.module';
import { AppService } from './services/app.service';
import { BillingService } from 'services/billing.service';
import { BreadcrumbComponent } from 'components/breadcrumb/breadcrumb.component';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BtnSpinnerDirective } from 'directives/btn-spinner.directive';
import { CancelSubscriptionModal } from 'components/modals/cancel-subscription-modal/cancel-subscription-modal.component';
import { CardImagesComponent } from 'components/card-images.component';
import { ChangePlanModal } from 'components/modals/change-plan-modal/change-plan-modal.component';
import { ClarityModule } from '@clr/angular';
import { CommonModule } from '@angular/common';
import { ContainerScrollEventService } from 'services/container-scroll-event.service';
import { CookieService } from 'ngx-cookie-service';
import { CouponService } from 'magma/services/couponService';
import { CreateProjectModalComponent } from 'components/modals/create-project-modal/create-project-modal.component';
import { CreatorModalComponent } from 'components/modals/creator-modal/creator-modal.component';
import { CurrencyPipe } from './pipes/currency.pipe';
import { DeleteConfirmationComponent } from 'components/delete-confirmation.component';
import { DeleteConfirmationModalComponent } from 'components/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { DeleteProjectModalComponent } from 'components/modals/delete-project-modal/delete-project-modal.component';
import { DeleteTeamMemberModalComponent } from './components/modals/delete-team-member-modal/delete-team-member-modal.component';
import { DgContainsFilter } from 'components/datagrid-filters/contains-filter.component';
import { DocumentStatusComponent } from 'components/document-status/document-status.component';
import { DocumentStatusDropdownComponent } from 'components/document-status/document-status-dropdown/document-status-dropdown.component';
import { DrawingBuddiesPageComponent } from 'components/drawing-buddies-page/drawing-buddies-page.component';
import { EmptyListPlaceholder } from './empty-list-placeholder/empty-list-placeholder.component';
import { EntitiesPageComponent } from 'components/entities-page/entities-page.component';
import { EntityActionToolbarComponent } from 'components/entity-grid/entity-action-toolbar/entity-action-toolbar.component';
import { EntityActionsMenuComponent } from 'components/entity-grid/entity-actions-menu/entity-actions-menu.component';
import { EntityDragDropNotificationService } from 'services/entity-drag-drop-notification.service';
import { EntityDragGhostComponent } from 'components/entity-grid/entity-drag-ghost/entity-drag-ghost.component';
import { EntityGridComponent } from 'components/entity-grid/entity-grid.component';
import { ErrorModule } from 'magma/error-module';
import { ErrorPageComponent } from 'components/error-page.component';
import { ExportEntityModal } from 'components/modals/export-entity-modal/export-entity-modal.component';
import { FeatureFlagService } from 'services/feature-flag.service';
import { FlowchartStatusBox } from 'components/flowchart-status-box.component';
import { FolderNamingModal } from 'components/modals/folder-naming-modal/folder-naming-modal.component';
import { GuidedTourModule } from 'ngx-guided-tour';
import { HeadersInterceptor } from './http-interceptors/headers-interceptor';
import { HttpParamsEncoderInterceptor } from 'http-interceptors/params-encoder.interceptor';
import { IAppNotificationService } from 'magma/services/app-notification.service.interface';
import { IFeatureFlagService } from 'magma/services/feature-flag.service.interface';
import { IThreadService } from 'magma/services/thread.service.interface';
import { ITrackService } from 'magma/services/track.service.interface';
import { InViewportModule } from 'ng-in-viewport';
import { InvitationErrorModal } from 'components/invitation-error-modal.component';
import { LeaveTeamModalComponent } from 'components/modals/leave-team-member-modal/leave-team-modal.component';
import { LiveEntityPreviewComponent } from 'components/live-entity-preview/live-entity-preview.component';
import { LoadingPageComponent } from 'components/loading-page.component';
import { LocalForage } from 'services/localforage.class';
import { LogInterceptor } from 'http-interceptors/log-interceptor';
import { LoginSignupPageComponent } from 'components/login-signup-page.component';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { MagmaModel } from 'services/magma-model.service';
import { ManageEntitiesGuard } from './guards/manage-entities.guard';
import { ManagePageComponent } from 'components/manage-page/manage-page.component';
import { ManageService } from 'magma/services/manageService';
import { MembersDatagridListComponent } from 'components/member-list.component';
import { ModalService } from 'services/modal.service';
import { Modals } from 'magma/services/modals';
import { Model } from 'magma/services/model';
import { MomentModule } from 'ngx-moment';
import { MoveFlowchartModalComponent } from 'components/move-flowchart.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { NoAccessOrNotFoundPageComponent } from 'components/no-access-or-not-found/no-access-or-not-found.component';
import { NotFoundPageComponent } from 'components/404/not-found-page.component';
import { NotifyMembersModal } from 'components/notify-member-modal.component';
import { OnLoadSrcDirective } from 'directives/on-load-src.directive';
import { OutsideClickDirective } from './directives/click-outside.directive';
import { PageHeaderComponent } from 'components/page-header.component';
import { ParticipantComponent } from 'components/participant/participant.component';
import { ParticipantsListComponent } from 'components/participants-list/participants-list.component';
import { PaymentValidatorComponent } from 'components/payment-validator.component';
import { PaymentWaitGuard } from './guards/payment-wait.guard';
import { PendingStatusValidationPipe } from 'pipes/pending-invitation-status.pipe';
import { PermissionFlagsModal } from 'components/permission-flags-modal/permission-flags-modal.component';
import { PortalCouponService } from 'services/portal-coupon.service';
import { PortalLoginSignupService } from 'services/portal-login-signup.service';
import { PortalManageService } from 'services/portal-manage.service';
import { PortalModalsBoxComponent } from 'components/portal-modals-box/portal-modals-box.component';
import { PortalSharedModule } from 'magma/components/shared/portal-shared.module';
import { PortalSubscriptionService } from 'services/subscription.service';
import { PortalThreadService } from 'services/portal-thread.service';
import { PortalToastService } from 'services/toast.service';
import { PresenceService } from 'services/presence.service';
import { ProjectBreadcrumb } from 'components/project-breadcrumb/project-breadcrumb.component';
import { ProjectService } from 'services/projects.service';
import { RecentEntitiesPageComponent } from 'components/recent-entities-page/recent-entities-page.component';
import { RecentEntityActionToolbarComponent } from 'components/entity-grid/recent-entity-action-toolbar/recent-entity-action-toolbar.component';
import { ResourcesLinksComponent } from 'components/resources-links.component';
import { ResourcesLinksModal } from 'components/modals/resources-links-modal/resources-links-modal.component';
import { RouterService } from './services/router.service';
import { RpcService } from 'services/rpc.service';
import { SafeHtmlPipe } from 'components/safe-html.pipe';
import { SalesModalsModule } from 'components/sales/sales-modals.module';
import { ScrollTrackerDirective } from 'directives/scroll-tracker.directive';
import { ScrolledOutOfViewportDirective } from 'directives/out-of-viewport.directive';
import { SearchBarService } from 'services/search-bar.service';
import { SearchResultsComponent } from 'components/search-results/search-results.component';
import { SelectContentDirective } from 'components/select-content.directive';
import { SelectFolderModalComponent } from 'components/modals/select-folder-modal/select-folder-modal.component';
import { ShareEntityAsImageModalComponent } from 'components/modals/share-entity-as-image-modal/share-entity-as-image-modal.component';
import { ShareEntityModalComponent } from 'components/modals/share-entity-modal/share-entity-modal.component';
import { SharedLinkComponent } from 'components/shared-link.component';
import { SharedModule } from 'components/shared.module';
import { SplitEmailNamePipe } from './pipes/splitBy.pipe';
import { SubscriptionService } from 'magma/services/subscription';
import { TeamGuard } from 'guards/team.guard';
import { TeamInviteGuard } from 'guards/team-invite.guard';
import { TeamInviteMembersComponent } from 'components/modals/team-invite-members/team-invite-members.component';
import { TeamInviteMembersModalComponent } from './components/modals/team-invite-members-modal/team-invite-members-modal.component';
import { TeamJoinModalComponent } from './components/modals/team-join-modal/team-join-modal.component';
import { TeamPageComponent } from 'components/team-page.component';
import { TeamPickerComponent } from 'components/team-picker/team-picker.component';
import { TeamSelectorComponent } from 'components/team-selector/team-selector.component';
import { TeamService } from 'services/team.service';
import { TeamSettingsAccessComponent } from 'components/team-settings/team-settings-access/team-settings-access.component';
import { TeamSettingsBillingComponent } from 'components/team-settings/team-settings-billing/team-settings-billing.component';
import { TeamSettingsComponent } from 'components/team-settings/team-settings.component';
import { TeamSettingsContributorsComponent } from 'components/team-settings/team-settings-contributors/team-settings-contributors.component';
import { TeamSettingsDetailsComponent } from 'components/team-settings/team-settings-details/team-settings-details.component';
import { TeamSettingsGuard } from 'guards/team-settings-page.guard';
import { TeamSettingsMemberRolesComponent } from './components/team-settings/team-settings-member-roles/team-settings-member-roles.component';
import { TeamSettingsMembersComponent } from './components/team-settings/team-settings-members/team-settings-members.component';
import { ToastService } from 'magma/services/toast.service';
import { TopLevelDirective } from 'directives/top-level.directive';
import { TrackService } from 'services/track.service';
import { TransferTeamOwnershipModalComponent } from 'components/modals/transfer-team-ownership-modal/transfer-team-ownership-modal.component';
import { UpgradeModalComponent } from 'components/modals/upgrade-modal/upgrade-modal.component';
import { UserRolesModal } from 'components/user-roles-modal/user-roles-modal.component';
import { UtcSecondsTransformPipe } from 'pipes/utc-seconds.pipe';
import { VoiceChatService } from 'magma/services/voiceChatService';
import localforage from 'localforage';
import { persistStorage } from 'services/persist-storage';
import { InvoicePreviewModalComponent } from 'components/modals/invoice-preview-modal/invoice-preview-modal.component';
import { StephenSilverShapesModal } from 'components/modals/stephen-silver-shapes-modal/stephen-silver-shapes-modal.component';
import { SwitchingToPrivateModalComponent } from 'components/modals/switching-to-private-modal/switching-to-private-modal.component';
import { BanTeamMemberModalComponent } from 'components/modals/ban-team-member-modal/ban-team-member-modal.component';
import { TeamSettingsBansComponent } from 'components/team-settings/team-settings-bans/team-settings-bans.component';
import { UnbanTeamMemberModalComponent } from 'components/modals/unban-team-member-modal/unban-team-member-modal.component';
import { StartPresentationModal } from 'components/modals/start-presentation-modal/start-presentation-modal.component';
import { UpgradeTeamSubscriptionModalComponent } from 'components/modals/upgrade-team-subscription-modal/upgrade-team-subscription-modal.component';
import { SwitchingToPublicModalComponent } from 'components/modals/switching-to-public-modal/switching-to-public-modal.component';
import { CreateTeamSubscriptionModalComponent } from 'components/modals/create-team-subscription-modal/create-team-subscription-modal.component';
import { TeamBillingPendingChangesModalComponent } from 'components/modals/team-billing-pending-changes-modal/team-billing-pending-changes-modal.component';
import { TeamInviteMembersWarningComponent } from 'components/modals/team-invite-member-warning/team-invite-member-warning.component';
import { TeamProjectsGuard } from 'guards/team-projects.guard';
import { NoAccessComponent } from 'components/no-access/no-access.component';
import { BillingCardComponent } from 'components/billing-card/billing-card.component';
import { BillingInfoComponent } from 'components/billing-info/billing-info.component';
import { DefaultPathGuard } from 'guards/default-path.guard';
import { EmptyComponent } from 'components/empty/empty.component';
import { OauthComponent } from 'components/oauth/oauth.component';
import { CreateTeamModalComponent } from 'components/modals/create-team-modal/create-team-modal.component';
import { TeamDetailsFormComponent } from 'components/team-settings/team-details-form/team-details-form.component';
import { TeamBillingChangeIntervalModalComponent } from 'components/modals/team-billing-change-interval-modal/team-billing-change-interval-modal.component';
import { ProSourcesComponent } from 'components/pro-sources/pro-sources.component';
import { BinFolderComponent } from 'components/bin-folder/bin-folder.component';
import { MoveToBinConfirmationModalComponent } from 'components/modals/move-to-bin-confirmation-modal/move-to-bin-confirmation-modal.component';
import { OpenDeletedFolderConfirmationModalComponent } from 'components/modals/open-deleted-folder-confirmation-modal/open-deleted-folder-confirmation-modal.component';
import { TeamBinFolderGuard } from 'guards/team-bin-folder.guard';
import { ReauthenticateComponent } from 'components/reauthenticate/reauthenticate.component';
import { RasterizeFlowModal } from 'components/modals/rasterize-flow-modal/rasterize-flow-modal.component';
import { UpdateProjectDescriptionModalComponent } from 'components/modals/update-project-description-modal/update-project-description-modal.component';
import { OpenProjectDescriptionModalComponent } from 'components/modals/open-project-description-modal/open-project-description-modal.component';
import { DeleteTeamConfirmationModalComponent } from 'components/modals/delete-team-confirmation-modal/delete-team-confirmation-modal.component';
import { TeamDeletionReasonModalComponent } from 'components/modals/team-deletion-reason-modal/team-deletion-reason-modal.component';
import { AiJobsComponent } from 'components/ai-jobs/ai-jobs.component';
import { AiService } from 'services/ai.service';
import { PresentationActionModal } from 'components/modals/presentation-action-modal/presentation-action-modal.component';
import { ManagePresentationViewersModal } from 'components/modals/manage-presentation-viewers-modal/manage-presentation-viewers-modal.component';
import { QueueAsyncJobModalComponent } from 'components/modals/queue-async-job-modal/queue-async-job-modal.component';
import { AiModelsComponent } from 'components/ai-models/ai-models.component';
import { EditAiModelModalComponent } from './components/modals/edit-ai-model-modal/edit-ai-model-modal.component';
import { StorageLimitExceededModalComponent } from 'components/modals/storage-limit-exceeded/storage-limit-exceeded-modal.component';
import { WhatsNewComponent } from 'components/whats-new/whats-new.component';
import { NewBrushesModal } from 'components/modals/new-brushes-modal/new-brushes-modal.component';
import { AiComponent } from 'components/ai/ai.component';
import { BlogService } from 'services/blog.service';
import { CommunityHubComponent } from './components/community-hub/community-hub.component';
import { ContentPageComponent } from './components/content-page/content-page.component';
import { PostsGridComponent } from './components/content-page/posts-grid.component';
import { PostModal } from './components/modals/post-modal/post-modal.component';

AgTooltip.DEFAULT_CONTAINER = '#portal-tooltip-outlet';

@NgModule({
  declarations: [
    AppComponent,
    ScrollTrackerDirective,
    SafeHtmlPipe,
    SplitEmailNamePipe,
    CurrencyPipe,
    TeamPageComponent,
    AccountSettingsPageComponent,
    AccountFeedbackComponent,
    AddUsersComponent,
    TeamDetailsFormComponent,
    LoginSignupPageComponent,
    EntitiesPageComponent,
    PageHeaderComponent,
    SelectContentDirective,
    BtnSpinnerDirective,
    TeamPageComponent,
    CreateProjectModalComponent,
    UpdateProjectDescriptionModalComponent,
    OpenProjectDescriptionModalComponent,
    DeleteProjectModalComponent,
    OutsideClickDirective,
    DeleteConfirmationComponent,
    MoveFlowchartModalComponent,
    InvitationErrorModal,
    PaymentValidatorComponent,
    OauthComponent,
    MembersDatagridListComponent,
    CardImagesComponent,
    UtcSecondsTransformPipe,
    ErrorPageComponent,
    NoAccessOrNotFoundPageComponent,
    NoAccessComponent,
    DocumentStatusComponent,
    StartPresentationModal,
    PresentationActionModal,
    ManagePresentationViewersModal,
    RasterizeFlowModal,
    DocumentStatusDropdownComponent,
    DgContainsFilter,
    NotifyMembersModal,
    FlowchartStatusBox,
    PendingStatusValidationPipe,
    BreadcrumbComponent,
    ProjectBreadcrumb,
    SearchResultsComponent,
    FolderNamingModal,
    ExportEntityModal,
    ResourcesLinksComponent,
    ResourcesLinksModal,
    EntityGridComponent,
    EntityActionToolbarComponent,
    EntityActionsMenuComponent,
    RecentEntityActionToolbarComponent,
    EntityDragGhostComponent,
    ScrolledOutOfViewportDirective,
    DeleteConfirmationModalComponent,
    DeleteTeamConfirmationModalComponent,
    TeamDeletionReasonModalComponent,
    SwitchingToPrivateModalComponent,
    SwitchingToPublicModalComponent,
    TopLevelDirective,
    ShareEntityModalComponent,
    ShareEntityAsImageModalComponent,
    RecentEntitiesPageComponent,
    DrawingBuddiesPageComponent,
    ParticipantComponent,
    ParticipantsListComponent,
    LiveEntityPreviewComponent,
    CancelSubscriptionModal,
    ChangePlanModal,
    NavigationBarComponent,
    PortalModalsBoxComponent,
    NotFoundPageComponent,
    AccountPasswordComponent,
    AccountBillingComponent,
    AiComponent,
    AiJobsComponent,
    AiModelsComponent,
    AccountNotificationsComponent,
    AccountPrivacyComponent,
    LoadingPageComponent,
    EmptyListPlaceholder,
    OnLoadSrcDirective,
    ManagePageComponent,
    AccountApiComponent,
    ApiKeyActionToolbarComponent,
    TeamSelectorComponent,
    AppNotificationsComponent,
    TeamSettingsComponent,
    TeamSettingsDetailsComponent,
    TeamSettingsMembersComponent,
    TeamSettingsBansComponent,
    TeamSettingsBillingComponent,
    TeamSettingsMemberRolesComponent,
    TeamSettingsAccessComponent,
    TeamSettingsContributorsComponent,
    DeleteTeamMemberModalComponent,
    CreateTeamModalComponent,
    BanTeamMemberModalComponent,
    UnbanTeamMemberModalComponent,
    TransferTeamOwnershipModalComponent,
    LeaveTeamModalComponent,
    TeamInviteMembersModalComponent,
    TeamJoinModalComponent,
    TeamPickerComponent,
    SelectFolderModalComponent,
    AccordionComponent,
    TeamInviteMembersComponent,
    TeamInviteMembersWarningComponent,
    PermissionFlagsModal,
    UserRolesModal,
    AnnouncementModalComponent,
    UpgradeModalComponent,
    SharedLinkComponent,
    CreatorModalComponent,
    CreateTeamSubscriptionModalComponent,
    UpgradeTeamSubscriptionModalComponent,
    StephenSilverShapesModal,
    EmptyComponent,
    NewBrushesModal,
    InvoicePreviewModalComponent,
    TeamBillingChangeIntervalModalComponent,
    TeamBillingPendingChangesModalComponent,
    BillingCardComponent,
    BillingInfoComponent,
    ProSourcesComponent,
    MoveToBinConfirmationModalComponent,
    BinFolderComponent,
    WhatsNewComponent,
    OpenDeletedFolderConfirmationModalComponent,
    ReauthenticateComponent,
    QueueAsyncJobModalComponent,
    StorageLimitExceededModalComponent,
    EditAiModelModalComponent,
    StorageLimitExceededModalComponent,
    ReauthenticateComponent,
    CommunityHubComponent,
    ContentPageComponent,
    PostsGridComponent,
    PostModal,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ClarityModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    InViewportModule,
    MomentModule,
    GuidedTourModule.forRoot(),
    SalesModalsModule,
    SharedModule,
    PortalSharedModule,
    ErrorModule,
  ],
  providers: [
    { provide: LocalForage, useValue: localforage },
    { provide: 'persistStorage', useValue: persistStorage },
    AppService,
    BillingService,
    ManageEntitiesGuard,
    TeamSettingsGuard,
    TeamProjectsGuard,
    TeamInviteGuard,
    DefaultPathGuard,
    AppGuard,
    AccountPageGuard,
    PaymentWaitGuard,
    TeamGuard,
    TeamBinFolderGuard,
    CookieService,
    TeamService,
    ProjectService,
    RpcService,
    PresenceService,
    EntityDragDropNotificationService,
    SearchBarService,
    BreadcrumbService,
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpParamsEncoderInterceptor, multi: true },
    ContainerScrollEventService,
    { provide: SubscriptionService, useClass: PortalSubscriptionService },
    { provide: CouponService, useClass: PortalCouponService },
    { provide: VoiceChatService, useClass: VoiceChatService },
    { provide: Model, useClass: MagmaModel },
    { provide: ToastService, useClass: PortalToastService },
    { provide: ITrackService, useClass: TrackService },
    RouterService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: IThreadService, useClass: PortalThreadService },
    { provide: IFeatureFlagService, useClass: FeatureFlagService },
    { provide: IAppNotificationService, useClass: AppNotificationService },
    { provide: LoginSignupService, useClass: PortalLoginSignupService },
    { provide: ManageService, useClass: PortalManageService },
    { provide: Modals, useClass: ModalService },
    AiService,
    BlogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
