<div class="breadcrumb" *ngIf="crumbs.length > 0">
  <ul>
    <ng-container *ngIf="(showFirstCrumb || crumbs.length === 1) && firstCrumb">
      <li
        class="crumb"
        *ngIf="firstCrumb"
        [class.drag-highlight]="highlightDropZones && (crumbs.length !== 1)"
        [class.drag-enter]="highlightDropZones && dragOverCrumb === firstCrumb && (crumbs.length !== 1)"
        (mouseenter)="dragOverCrumb = firstCrumb"
        (mouseleave)="dragOverCrumb = null"
      >
        <div class="crumb-container">
          <ng-container *ngTemplateOutlet="crumbIcon; context: { $implicit: firstCrumb }"></ng-container>
          <a
            *ngIf="crumbs.length > 1 || anchorLeaf"
            (mouseup)="dropped(firstCrumb)"
            [routerLink]="firstCrumb.link?.url"
            [queryParams]="firstCrumb.link?.query">{{firstCrumb.name}}</a>
          <span *ngIf="crumbs.length === 1 && !anchorLeaf">{{firstCrumb.name}}</span>
        </div>
        <span class="accept-entity-indicator">
          <svg-icon [icon]="faPlus" size="xs" style="display: flex;"></svg-icon>
        </span>
      </li>
    </ng-container>

    <li class="delimiter" *ngIf="showFirstCrumb && crumbs.length > 1">
      <svg-icon [icon]="faChevronRight" size="xs"></svg-icon>
    </li>

    <ng-container *ngIf="crumbsForDropdown.length > 0">
      <li class="crumb" (outsideClick)="showDropdown = false">
        <clr-dropdown>
          <button *ngIf="belowBreakpointSM()" clrDropdownTrigger (mouseover)="showDropdown = true"
            class="use-magma-styles mobile-dropdown-button" [class.mobile-dropdown-button-open]="showDropdown">
            <svg-icon *ngIf="!showDropdown" [icon]="farEllipsisH" size="xs"></svg-icon>
            <svg-icon *ngIf="showDropdown" [icon]="farEllipsisV" size="xs"></svg-icon>
          </button>
          
          <div *ngIf="!belowBreakpointSM()" clrDropdownTrigger (mouseover)="showDropdown = true">...</div>
          <clr-dropdown-menu *clrIfOpen="showDropdown">
            <div
              clrDropdownItem
              *ngFor="let crumb of crumbsForDropdown"
              [class.drag-highlight]="highlightDropZones"
              [class.drag-enter]="highlightDropZones && dragOverCrumb === crumb"
              (mouseenter)="dragOverCrumb = crumb"
              (mouseleave)="dragOverCrumb = null"
            >
              <div class="crumb-container">
                <ng-container *ngTemplateOutlet="crumbIcon; context: { $implicit: crumb }"></ng-container>
                <a (mouseup)="dropped(crumb)"
                  [routerLink]="crumb.link?.url"
                  [queryParams]="crumb.link?.query">{{crumb.name}}
                </a>
              </div>
            </div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </li>
    </ng-container>

    <li class="delimiter" *ngIf="crumbsForDropdown.length > 0">
      <svg-icon [icon]="faChevronRight" size="xs"></svg-icon>
    </li>

    <ng-container *ngIf="visibleCrumbs">
      <ng-container *ngFor="let crumb of visibleCrumbs; let i = index;">
        <li
          class="crumb"
          [class.highlight]="i === visibleCrumbs.length - 1"
          [class.drag-highlight]="highlightDropZones && (i !== visibleCrumbs.length - 1)"
          [class.drag-enter]="highlightDropZones && dragOverCrumb === crumb && (i !== visibleCrumbs.length - 1)"
          (mouseenter)="dragOverCrumb = crumb"
          (mouseleave)="dragOverCrumb = null"
        >
          <div class="crumb-container">
            <ng-container *ngTemplateOutlet="crumbIcon; context: { $implicit: crumb }"></ng-container>
            <a (mouseup)="dropped(crumb)" *ngIf="i !== visibleCrumbs.length - 1 || anchorLeaf"
              [routerLink]="crumb.link?.url" [queryParams]="crumb.link?.query">{{crumb.name}}</a>
            <span *ngIf="i === visibleCrumbs.length - 1 && !anchorLeaf" class="crumb-name">{{crumb.name}}</span>
          </div>
          <span class="accept-entity-indicator">
            <svg-icon [icon]="faPlus"></svg-icon>
          </span>
        </li>

        <li class="delimiter" *ngIf="i !== visibleCrumbs.length - 1">
          <svg-icon [icon]="faChevronRight" size="xs"></svg-icon>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #crumbIcon let-crumb>
  <ng-container *ngIf="showIcons && crumb.type">
    <svg-icon *ngIf="crumb.type === 'my-drafts'" class="icon-folder" [icon]="faPencil"></svg-icon>
    <span *ngIf="crumb.type === 'team'" class="icon-team">{{crumb.name?.substring(0, 1) || ''}}</span>
    <span *ngIf="crumb.type === 'project'" class="icon-project"></span>
    <svg-icon *ngIf="crumb.type === 'Folder'" class="icon-folder" [icon]="faFolder"></svg-icon>
  </ng-container>
</ng-template>
