import { Pipe, PipeTransform } from '@angular/core';
import { memoize } from 'lodash';
import { unix } from 'moment';

@Pipe({ name: 'UtcToDatePipe'})
export class UtcSecondsTransformPipe implements PipeTransform  {

  constructor() { }

  transform(value: number) {
    return transformUTCSecondsToDate(value);
  }

}

const transformUTCSecondsToDate = memoize((utcSeconds: number) => {
  return unix(utcSeconds).format('MMMM Do, YYYY');
});
