import { Component, Input } from '@angular/core';
import { disableMyArtdesk } from 'magma/common/data';
import { Permission } from 'magma/common/interfaces';
import { Modals } from 'magma/services/modals';
import { TeamMembersService } from 'services/team-members.service';
import { EntityData, ProjectData } from 'shared/interfaces';

@Component({
  selector: 'empty-list-placeholder',
  templateUrl: './empty-list-placeholder.component.pug',
  styleUrls: ['./empty-list-placeholder.component.scss'],
})
export class EmptyListPlaceholder {
  @Input() image!: 'participated' | 'artworks' | 'empty-folder' | 'empty-live-now';
  @Input() message?: string;
  @Input() subtitle?: string;
  @Input() folder?: EntityData;
  @Input() team?: string;
  @Input() project?: ProjectData;

  constructor(private modals: Modals, private teamMemberService: TeamMembersService) { }

  get buttonLabel() {
    if (this.image === 'empty-folder') {
      return 'Create new canvas';
    } else if (this.image === 'empty-live-now') {
      return 'Start a new session';
    } else {
      return 'Create your first canvas';
    }
  }

  createCanvas() {
    const { folder, team, project } = this;
    this.modals.createDrawing({ folder: folder?._id, team, project: project?._id });
  }

  get canCreateEntities() {
    if (!this.project && disableMyArtdesk) return false;

    return this.teamMemberService.isPermissionFlagSet(Permission.CanCreateEntities, this.project, this.folder);
  }
}
