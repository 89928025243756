import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamInviteMembersInput } from '../team-invite-members/team-invite-members.component';
import { TeamMembersService } from 'services/team-members.service';
import { Permission } from 'magma/common/interfaces';

@Component({
  selector: 'team-invite-members-modal',
  templateUrl: './team-invite-members-modal.component.pug',
  styleUrls: ['./team-invite-members-modal.component.scss'],
})
export class TeamInviteMembersModalComponent {
  @Input() data!: TeamInviteMembersInput;
  @Output() close = new EventEmitter();

  constructor(private teamMembersService: TeamMembersService) {}

  get canManageBilling() {
    return this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeamBilling]);
  }

  onClose() {
    this.close.emit();
  }
}
