import { ProductData, RoleType } from './../../../../../shared/interfaces';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { ROLE_TYPES_READ_ONLY, TeamData, TeamMember, TeamRole, TeamType } from '../../../../../shared/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BillingService } from 'services/billing.service';
import { Component } from '@angular/core';
import { ModalService } from 'services/modal.service';
import { Feature, Permission } from 'magma/common/interfaces';
import { MAX_FREE_PRIVATE_ARTSPACE_MEMBERS, TEAM_PRODUCTS } from 'shared/billing';
import { TeamMembersQuery } from '../../../services/team-members.query';
import { TeamMembersService } from 'services/team-members.service';
import { TeamService } from 'services/team.service';
import { TeamsQuery } from 'services/team.query';
import { ToastService } from 'magma/services/toast.service';
import { faBan, faInfoCircle, faPen, faPlus, farEllipsisH, farEllipsisV, faStar, faTimes, faTrash, faUserPlus, farExclamationTriangle } from 'magma/common/icons';
import { UserService } from 'services/user.service';
import { getMinimumProductRequired, getPlanForMember } from 'shared/utils';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'team-settings-members',
  templateUrl: './team-settings-members.component.pug',
  styleUrls: ['../../account-common.component.scss',
    './team-settings-members.component.scss'],
  host: { class: 'use-magma-styles' },
})
export class TeamSettingsMembersComponent {
  readonly showSearchRolesThreadshold = 10;
  MAX_FREE_PRIVATE_ARTSPACE_MEMBERS = MAX_FREE_PRIVATE_ARTSPACE_MEMBERS;
  ROLE_TYPES_READ_ONLY = ROLE_TYPES_READ_ONLY;
  RoleType = RoleType;
  farEllipsisH = farEllipsisH;
  farEllipsisV = farEllipsisV;
  faTrash = faTrash;
  faUserPlus = faUserPlus;
  faStar = faStar;
  faPen = faPen;
  faPlus = faPlus;
  faTimes = faTimes;
  faBan = faBan;
  faInfoCircle = faInfoCircle;
  farExclamationTriangle = farExclamationTriangle;

  filter = '';
  teamMembers$ = this.teamMembersQuery.selectAll();
  activeTeam$ = this.teamsQuery.selectActive();
  activeTeam: TeamData | undefined;
  roles: TeamRole[] = [];
  roles$ = this.teamService.teamRoles$;

  members$ = this.teamMembersQuery.selectAll().pipe(map(members => members.map(m => ({ ...m, roles: m.roles.filter(r => !r.user) }))));
  reload$ = new BehaviorSubject(null);

  reachedMaxUsersOnFreePlan$ = combineLatest([this.members$, this.activeTeam$]).pipe(map(([members, team]) =>
    team && !team.isPublic && !team.featureFlags?.includes(Feature.NoUserLimit) &&
    this.billingService.getTeamType(team) === TeamType.Private && members.length >= MAX_FREE_PRIVATE_ARTSPACE_MEMBERS));

  requiredProduct = new Map<string, string | null>();
  memberWithTransferrableSeat: TeamMember[] = [];

  constructor(
    private teamsQuery: TeamsQuery,
    private teamMembersQuery: TeamMembersQuery,
    private teamMembersService: TeamMembersService,
    private toastService: ToastService,
    private modalService: ModalService,
    private userService: UserService,
    private teamService: TeamService,
    private billingService: BillingService,
  ) {
    this.activeTeam$.pipe(untilDestroyed(this)).subscribe(async (team) => {
      this.activeTeam = team;
    });

    this.teamService.teamRoles$.pipe(untilDestroyed(this)).subscribe(async (roles) => {
      this.roles = roles.filter(r => !ROLE_TYPES_READ_ONLY.includes(r.type));

      for (const role of roles) {
        const product = getMinimumProductRequired(role);
        this.requiredProduct.set(role._id, product);
      }
    });

    this.teamMembers$.pipe(untilDestroyed(this)).subscribe(members => {
      this.memberWithTransferrableSeat = members.filter(m => !m.roles.find(r => r.type === RoleType.Blocked || r.type === RoleType.Owner));
    });
  }

  async invite() {
    if (this.activeTeam) {
      await this.modalService.inviteTeamMembers({
        team: this.activeTeam,
        canManageInvites: this.teamMembersService.isPermissionFlagSet([Permission.CanManageInvites]),
      });
    }
  }

  filteredRoles(member: TeamMember) {
    const userRoleIds = member.roles.map(r => r._id);
    return this.roles.filter(r => !userRoleIds.includes(r._id) && r.name.toLowerCase().includes(this.filter.toLowerCase()));
  }

  requiredUserTypeRoles(member: TeamMember, plan: ProductData) {
    return member.roles.filter(r => this.requiredProduct.get(r._id) === plan.productId);
  }

  isBlocked(member: TeamMember) {
    return member.roles.find(r => r.type === RoleType.Blocked);
  }

  get canManageRoles() {
    return this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeamRoles]);
  }

  get canManageBilling() {
    return this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeamBilling]);
  }

  get canRemoveMembers() {
    return this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeamMembers]);
  }

  get showContextMenu() {
    return this.canManageRoles || this.canRemoveMembers;
  }

  isMe(member: TeamMember) {
    return this.userService.userId === member.user._id;
  }

  get teamId() {
    return this.activeTeam!._id;
  }

  async upgrade() {
    if (this.activeTeam) {
      await this.modalService.upgradeTeamSubscriptionModal({
        teamId: this.activeTeam._id, teamSlug: this.activeTeam.slug,
        alreadyOnProPlan: this.billingService.getTeamType(this.activeTeam) === TeamType.Professional
      });
    } else {
      DEVELOPMENT && console.error('Missing active team');
    }
  }

  async switchToPublicMode() {
    if (!this.activeTeam) return;
    const confirmed = await this.modalService.switchingToPublic(this.activeTeam);
    if (confirmed) {
      try {
        await this.teamService.updateTeam(this.activeTeam, { isPublic: true });
        this.toastService.success({ message: `Team mode changed to Public` });
      } catch (e) {
        this.toastService.success({ message: e.message });
      }
    }
  }

  async transferOwnership(member: TeamMember) {
    try {
      if (await this.modalService.transferTeamOwnership({
        teamId: this.activeTeam!._id,
        teamName: this.activeTeam!.name,
        userId: member.user._id,
        userName: member.user.name,
      })) {
        await this.teamService.updateOwner(this.activeTeam!, member);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to transfer ownership', subtitle: e.message });
    }
  }

  async removeTeamMember(member: TeamMember) {
    try {
      if (await this.modalService.removeTeamMember({ teamName: this.activeTeam!.name, userName: member.user.name })) {
        await this.teamMembersService.removeMemberTeam(this.activeTeam!._id, member.user._id);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to remove artspace member', subtitle: e.message });
    }
  }

  async removeAndBanTeamMember(member: TeamMember) {
    try {
      if (await this.modalService.banTeamMember({ teamName: this.activeTeam!.name, userName: member.user.name })) {
        await this.teamMembersService.removeMemberTeam(this.activeTeam!._id, member.user._id, true);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to remove & ban artspace member', subtitle: e.message });
    }
  }

  async addRole(member: TeamMember, role: TeamRole) {
    if (!this.activeTeam) {
      DEVELOPMENT && console.error('No active team');
      return;
    }

    const roles = [...member.roles.map(r => r._id), role._id];

    if (roles) {
      const toast = this.toastService.loading({ message: 'Updating roles...' });
      try {
        await this.teamMembersService.updateTeamMember(this.teamId, member.user._id, roles);
        this.toastService.updateToSuccess(toast, { message: 'Successfully updated roles' });
      } catch (e) {
        this.toastService.updateToError(toast, { message: 'Failed to update roles' });
      }
    }
  }

  async reassignRoles(sourceMember: TeamMember, targetMember: TeamMember) {
    const toast = this.toastService.loading({ message: 'Updating roles...' });
    try {
      await this.teamMembersService.transferTeamMemberRoles(this.teamId, sourceMember._id, targetMember._id);
      this.toastService.updateToSuccess(toast, { message: 'Successfully updated roles' });
    } catch (e) {
      this.toastService.updateToError(toast, { message: 'Failed to update roles' });
    }
  }

  async removeRole(member: TeamMember, role: TeamRole) {
    if (!this.activeTeam) {
      DEVELOPMENT && console.error('No active team');
      return;
    }

    const roles = member.roles.map(r => r._id).filter(id => id !== role._id);

    if (roles) {
      const toast = this.toastService.loading({ message: 'Updating roles...' });
      try {
        await this.teamMembersService.updateTeamMember(this.teamId, member.user._id, roles);
        this.toastService.updateToSuccess(toast, { message: 'Successfully updated roles' });
      } catch (e) {
        this.toastService.updateToError(toast, { message: 'Failed to update roles' });
      }
    }
  }

  async setRoles(member: TeamMember) {
    if (!this.activeTeam) {
      DEVELOPMENT && console.error('No active team');
      return;
    }
    const roles = await this.modalService.setRoles({
      userId: member.user._id,
      team: this.activeTeam,
      roles: member.roles.map(r => r._id),
    });

    if (roles) {
      try {
        await this.teamMembersService.updateTeamMember(this.teamId, member.user._id, roles);
      } catch (e) {
        this.toastService.error({ message: 'Failed to update roles', subtitle: e.message });
      }
    }
  }

  getPlanForMember(member: TeamMember) {
    const productId = getPlanForMember(member);
    if (!productId) {
      return undefined;
    }
    return TEAM_PRODUCTS.get(productId);
  }
}
