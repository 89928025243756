import { generateCacheId, routeToTeam } from 'shared/utils';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { faBell, faBells, faExclamationTriangle } from 'magma/common/icons';
import { Component, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { AppNotification, AppNotificationAction, AppNotificationStatus } from 'magma/common/interfaces';
import { IAppNotificationService } from 'magma/services/app-notification.service.interface';
import { drawingsPath } from 'magma/common/data';
import { Router } from '@angular/router';
import { getThumbPath } from 'magma/common/clientUtils';
import { Subscription } from 'rxjs';
import { TeamsQuery } from 'services/team.query';
import { Dropdown } from 'magma/components/shared/directives/dropdown';

@UntilDestroy()
@Component({
  selector: 'app-notifications',
  templateUrl: './app-notifications.component.pug',
  styleUrls: ['./app-notifications.component.scss'],
})
export class AppNotificationsComponent {
  @ViewChild(Dropdown) menu!: Dropdown;
  @ViewChild('notificationsElement') notificationsElement: ElementRef<HTMLDivElement> | undefined = undefined;
  AppNotificationStatus = AppNotificationStatus;
  readonly icon = faBell;
  readonly faBells = faBells;
  readonly exclamationTriangle = faExclamationTriangle;
  readonly NOTIFICATION_HEIGHT = 84;
  isOpen = false;
  hasUnread = false;
  scrollTop = 0;
  isLoading = false;
  allNotificationsDownloaded = false;
  @Input('teamId') teamId: string | null = null;

  notifications: AppNotification[] = [];
  private thumbnails = new Map<string, string>();
  private subscription: Subscription | undefined;
  notificationCounters: Map<string, number> = new Map();

  constructor(public appNotificationService: IAppNotificationService, private router: Router, private teamsQuery: TeamsQuery) {
    this.appNotificationService.notificationCounters$.pipe(untilDestroyed(this)).subscribe((counters) => {
      this.notificationCounters = counters;
    });
  }

  async ngOnInit() {
    if (!this.teamId) {
      this.appNotificationService.notifications$.pipe(untilDestroyed(this)).subscribe(async (notifications) => {
        await this.handleNotifications(notifications);
      });
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.teamId.currentValue !== changes.teamId.previousValue) {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = undefined;
      }
      if (this.teamId) {
        this.subscription = this.appNotificationService
          .observeTeamNotifications(this.teamId)
          .pipe(untilDestroyed(this))
          .subscribe(async (notifications) => {
            await this.handleNotifications(notifications);
          });
      }
    }
  }

  private async handleNotifications(notifications: AppNotification[]) {
    this.notifications = notifications;
    if (notifications.length > 0) {
      this.notifications.sort((n1, n2) => n2.createdAt.getTime() - n1.createdAt.getTime());
      this.hasUnread = await this.appNotificationService.hasUnread(this.teamId);
      this.refreshThumbnails();
    }
    this.isLoading = false;
  }

  private refreshThumbnails() {
    this.notifications.forEach(n => {
      if (n.trigger.entity?.shortId) {
        this.thumbnails.set(
          n.trigger.entity.shortId,
          getThumbPath(n.trigger.entity.shortId, generateCacheId(n.trigger.entity.cacheId, n.team?.cacheId), false, undefined, false, 200),
        );
      }
    });
  }

  async open(notification: AppNotification) {
    this.appNotificationService.updateNotificationStatus(notification.shortId, AppNotificationStatus.Read).catch(e => {
      DEVELOPMENT && console.error(e);
    });

    await this.markAllUnreadViewedAsViewed();

    const entityId = notification.trigger.entity?.shortId;

    if (entityId) {
      if ((notification.trigger.action === AppNotificationAction.CommentAdded
        || notification.trigger.action === AppNotificationAction.CommentMentioned)) {
        return this.router.navigate([drawingsPath, entityId], {
          queryParams: {
            openedFrom: 'notification-dropdown',
            threadId: notification.trigger.data.threadShortId,
            commentId: notification.trigger.data.commentShortId,
          },
        });
      }

      return this.router.navigate([drawingsPath, entityId], { queryParams: { openedFrom: 'notification-dropdown' } });
    } else if (
      notification.trigger.action === AppNotificationAction.UserJoinedTeam ||
      notification.trigger.action === AppNotificationAction.UserJoinedTeamLimitExceeded ||
      notification.trigger.action === AppNotificationAction.UserJoinedTeamLimitReached ||
      notification.trigger.action === AppNotificationAction.TeamMarkedForDeletion
    ) {
      const team = this.teamsQuery.getAll().find(t => t._id === notification.team?._id);
      if (team) {
        this.menu.close();
        if (notification.trigger.action === AppNotificationAction.TeamMarkedForDeletion )
          return this.router.navigate(routeToTeam(team.slug, undefined), { queryParams: { openedFrom: 'notification-dropdown' } });
        else 
          return this.router.navigate(routeToTeam(team.slug, ['settings', 'members']), { queryParams: { openedFrom: 'notification-dropdown' } });
      }
    }
  }

  async markAllAsRead() {
    await this.appNotificationService.markAllAsRead(this.teamId);
  }

  async markAllUnreadViewedAsViewed() {
    if (!this.notificationsElement) return;

    const itemsSeen = (this.notificationsElement.nativeElement.clientHeight + this.scrollTop) / this.NOTIFICATION_HEIGHT;
    const seenIds = this.notifications.filter(n => n.status === AppNotificationStatus.Unread).slice(0, itemsSeen).map(n => n.shortId);
    await this.appNotificationService.markUnreadAsViewed(this.teamId, seenIds);
  }

  async onScroll() {
    if (!this.notificationsElement) return;

    const ne = this.notificationsElement.nativeElement;
    if (this.scrollTop < ne.scrollTop) {
      this.scrollTop = ne.scrollTop;
    }

    if (!this.allNotificationsDownloaded && !this.isLoading && ne.scrollHeight - ne.scrollTop < ne.clientHeight + this.NOTIFICATION_HEIGHT) {
      this.isLoading = true;
      const newNotificationsCount = await this.appNotificationService.loadMore(this.notifications.length, this.teamId);
      if (newNotificationsCount === 0) {
        this.allNotificationsDownloaded = true;
      }
    }
  }

  async onOpenChange(isOpen: boolean) {
    this.isOpen = isOpen;
    if (!isOpen) {
      await this.markAllUnreadViewedAsViewed();
    }
  }

  getThumbnail(n: AppNotification) {
    const teamAvatar = n.team?.avatar;
    return (n.trigger.entity && this.thumbnails.get(n.trigger.entity.shortId)) || (n.trigger.action === AppNotificationAction.TeamMarkedForDeletion &&  this.thumbnails.get(teamAvatar!));
  }

  byShortId(_index: number, item: AppNotification) {
    return item.shortId;
  }

  teamIsDeletedNotification(n: AppNotification) {
    const action = n.trigger.action;
    return (action === AppNotificationAction.TeamMarkedForDeletion || action === AppNotificationAction.TeamUnmarkedForDeletion) && n.team;
  }

  teamAvatar(n: AppNotification) {
    return n.team?.avatar;
  }
}
