import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectedFolder } from 'magma/common/interfaces';

export interface SelectFolderModalInput {
  pickedFolderIds?: string[];
  teamId: string | undefined;
  showArtdesk: boolean;
  isCreateDrawingModal?: boolean;
  preselectedFolder?: SelectedFolder;
}

@Component({
  selector: 'select-folder-modal',
  templateUrl: './select-folder-modal.component.pug',
  styleUrls: ['./select-folder-modal.component.scss'],
})
export class SelectFolderModalComponent {
  @Input() data!: SelectFolderModalInput;
  @Output() close = new EventEmitter<SelectedFolder>();
  selectedFolder: SelectedFolder | undefined;

  onClose() {
    this.close.emit();
  }

  onSelected(folderData: SelectedFolder) {
    this.selectedFolder = folderData;
  }

  confirm() {
    this.close.emit(this.selectedFolder);
  }

  get headerLabel() {
    return this.data.isCreateDrawingModal ? 'Change location' : 'Move to';
  }

  get confirmButtonLabel() {
    return this.data.isCreateDrawingModal ? 'OK' : 'Move';
  }
}
