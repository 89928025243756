import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';
import { ErrorReporter } from 'magma/services/errorReporter';
import { BehaviorSubject, combineLatest, NEVER, Observable } from 'rxjs';
import { distinctUntilChanged, map, pairwise, switchMap, tap } from 'rxjs/operators';
import { EntityPresence, UserPresence } from 'shared/rpc-interface';
import { updateParticipantsFromPresence } from 'shared/utils';
import { EntitiesStore } from './entities.store';
import { PresenceService } from './presence.service';
import { TeamsQuery } from './team.query';
import { UserService } from './user.service';

@Injectable()
@UntilDestroy()
export class PresenceStateService {
  constructor(
    private teamsQuery: TeamsQuery,
    private userService: UserService,
    private presenceService: PresenceService,
    private entitiesStore: EntitiesStore,
    private errorReporter: ErrorReporter,
  ) {
    combineLatest([this.teamsQuery.selectActive(), this.userService.user$])
      .pipe(
        map(([team, user]) => {
          return (team?._id || user?._id);
        }),
        distinctUntilChanged(),
        switchMap((id) => {
          if (!id) { return NEVER; }

          // TEMP: remove when we find out if this is an issue
          if (typeof id !== 'string') {
            this.errorReporter.reportError('Invalid id in PresenceStateService', new Error('Invalid id'), { id });
            return NEVER;
          }

          return this.monitorPresenceOnTeam(id);
        }),
        untilDestroyed(this),
      ).subscribe();

    const updatePresenceInStore = (entityId: string, presence: UserPresence[]) => {
      this.entitiesStore.update(entityId, entity => {
        const newEntity = cloneDeep(entity);
        updateParticipantsFromPresence(newEntity.participants || [], presence);
        return newEntity;
      });
    };

    this.state$.pipe(
      pairwise(),
      untilDestroyed(this),
    ).subscribe(([oldState, state]) => {
      applyTransaction(() => {
        for (const entityId of Object.keys(oldState)) {
          if (!state[entityId]) {
            updatePresenceInStore(entityId, []);
          }
        }
        for (const entityId of Object.keys(state)) {
          const presence = state[entityId];
          updatePresenceInStore(entityId, presence);
        }
      });
    });
  }

  state$ = new BehaviorSubject<Record<string, UserPresence[]>>({});

  private monitorPresenceOnTeam(teamId: string) {
    return this.presenceService.monitorPresenceOnTeam(teamId).pipe(tap(([_, data]) => {
      this.state$.next({
        ...Object.values(data).reduce((acc, session) => {
          const userPresence = [...(acc[session.entityId] || []), session];
          return { ...acc, [session.entityId]: userPresence };
        }, {} as { [id: string]: UserPresence[] })
      });
    }));
  }

  monitorPresenceOnEntities(entityIds: string[]): Observable<EntityPresence> {
    if ((!entityIds || entityIds.length === 0)) {
      return NEVER;
    }

    return this.presenceService.monitorPresenceOnEntities(entityIds).pipe(
      tap(([entityId, presenceState]) => {
        const participants = Object.values(presenceState);
        this.publish({ [entityId]: participants });
      }),
      map((([, presenceState]) => presenceState)),
    );
  }

  private publish(presenceState: { [entityId: string]: UserPresence[] }) {
    const existingValue = this.state$.value;
    const newValue = {
      ...existingValue,
      ...presenceState,
    };

    this.state$.next(newValue);
  }

  getEntityPresence(entityId: string) {
    return this.state$.value[entityId] || [];
  }

  watchEntityPresence(entityId: string) {
    return this.state$.pipe(
      map(state => state[entityId] || []),
      distinctUntilChanged((a, b) => a.length === 0 && b.length === 0),
    );
  }
}
