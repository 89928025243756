import { NgModule } from '@angular/core';
import { ErrorReporter } from './services/errorReporter';

@NgModule({
  providers: [
    {
      provide: ErrorReporter,
      useClass: ErrorReporter,
    }
  ],
})
export class ErrorModule {
}
