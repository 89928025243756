import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AI_MODEL_BASES, AiModel, AiModelBase, AiModelType } from 'magma-editor/src/ts/common/aiInterfaces';
import { faFolderOpen, faTimes } from 'magma/common/icons';
import { cloneDeep, fromBase64Url } from 'magma/common/utils';
import { ToastService } from 'magma/services/toast.service';
import { AiService } from 'services/ai.service';
import { TeamsQuery } from 'services/team.query';
import { generateAiThumbnailImageUrl } from 'util/util';


@Component({
  selector: 'edit-ai-model-modal',
  templateUrl: 'edit-ai-model-modal.component.pug',
  styleUrls: ['./edit-ai-model-modal.component.scss'],
})
export class EditAiModelModalComponent {
  readonly closeIcon = faTimes;
  readonly openIcon = faFolderOpen;
  readonly baseModels = AI_MODEL_BASES;

  isSubmitting = false;

  @Output() close = new EventEmitter<boolean>();
  @Input() data!: {
    data?: AiModel,
    file?: File,
    mode: 'create' | 'upload' | 'edit'
  };

  modelData: AiModel = {
    rId: '', // this will be updated later
    name: '',
    description: '',
    owner: '', // this will be updated by server
    tag: '',
    type: AiModelType.Lora,
    enabled: false,
    thumbnails: [],
    baseModel: AiModelBase.SD_1_5,
    permissions: {
      users: [],
      teams: [],
    },
    createdAt: new Date(),
    team: this.teamsQuery.getActive()?._id ?? null
  };
  types = [AiModelType.Embedding, AiModelType.Lora, AiModelType.Model];

  thumbnails: {
    rId: string | null;
    data: string
  }[] = [];

  get isEditing() {
    return !!this.data.data;
  }

  constructor(private aiService: AiService, private toastService: ToastService, private teamsQuery: TeamsQuery) { }

  ngOnInit() {
    if (this.data.data) {
      this.modelData = cloneDeep(this.data.data);
      this.thumbnails = this.modelData.thumbnails.map(rId => ({ rId, data: generateAiThumbnailImageUrl(rId) }));
    }
  }

  async updateModel() {
    try {
      this.isSubmitting = true;
      const { rId, name, description, tag, type, enabled, baseModel } = this.modelData;

      const thumbnails = await Promise.all(this.thumbnails.map(t => t.rId ? t.rId : this.aiService.uploadAilFile(this.b64ToArray(t.data))));

      await this.aiService.updateAiModel(rId, { name, description, tag, type, enabled, baseModel, thumbnails });
      this.close.emit(true);
    } catch (e) {
      this.toastService.error({ message: 'Failed to update model', subtitle: e.message });
    }
    this.isSubmitting = false;
  }

  async createModel() {
    try {
      this.isSubmitting = true;
      // TODO add sending in chunks for bigger files
      await this.aiService.createAiModel({ ...this.modelData }, true);
      this.close.emit(true);
    } catch (e) {
      this.toastService.error({ message: 'Failed to create model', subtitle: e.message });
    }
    this.isSubmitting = false;
  }

  private b64ToArray(data: string) {
    return fromBase64Url(data.replace(/^data:image\/(png|jpeg|webp);base64,/, ''));
  }

  async uploadModel() {
    try {
      this.isSubmitting = true;
      if (!this.data.file) throw new Error('Missing model file');
      // TODO add sending in chunks for bigger files
      // TODO maybe combine this requests
      const rId = await this.aiService.uploadAilFile(new Uint8Array(await this.data.file.arrayBuffer()));
      const thumbnails = await Promise.all(this.thumbnails.map(t => this.aiService.uploadAilFile(this.b64ToArray(t.data))));
      await this.aiService.createAiModel({ ...this.modelData, thumbnails, rId }, false);
      this.close.emit(true);
    } catch (e) {
      this.toastService.error({ message: 'Failed to upload model', subtitle: e.message });
    }
    this.isSubmitting = false;
  }

  onClose() {
    this.close.emit(false);
  }

  async selectThumbnail(files: File[]) {
    files.forEach(f => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result?.toString();
        if (data) this.thumbnails.push({ rId: null, data });
      };
      reader.readAsDataURL(f);
    });
  }

  isValid() {
    return this.modelData.name.length > 0 && this.modelData.baseModel.length > 0 && this.modelData.tag.length > 0;
  }
}
