import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface BanTeamMemberModalInput {
  userName: string;
  teamName: string;
}

@Component({
  selector: 'unban-team-member-modal',
  templateUrl: 'unban-team-member-modal.component.pug',
})
export class UnbanTeamMemberModalComponent {
  @Output() close = new EventEmitter<boolean | undefined>();
  @Input() data!: BanTeamMemberModalInput;

  onUnban() {
    this.close.emit(true);
  }

  onClose() {
    this.close.emit(undefined);
  }
}
