import { Component, EventEmitter, Input, Output } from '@angular/core';
import { t } from '@deepkit/type';
import { faInfoCircle } from 'magma/common/icons';
import { ToastService } from 'magma/services/toast.service';
import { BillingService } from 'services/billing.service';
import { TEAM_PRODUCTS } from 'shared/billing';
import { BillingData, BillingInterval, ProductPrice, ScheduledTeamBillingUpdate, UpdateBillingItem } from 'shared/interfaces';
import Stripe from 'stripe';

export interface TeamBillingPendingChangesModalInput {
  teamId: string;
  teamSlug: string;
  billingInterval: BillingInterval;
  billingData: BillingData;

  currentItems: Map<string, number>; // stripeProductId: user
  afterItems: Map<string, number>; // stripeProductId: user

  billingPendingChanges: ScheduledTeamBillingUpdate;
}

@Component({
  selector: 'team-billing-pending-changes-modal',
  templateUrl: 'team-billing-pending-changes-modal.component.pug',
  styleUrls: [
    '../create-team-subscription-modal/create-team-subscription-modal.component.scss',
    './team-billing-pending-changes-modal.component.scss'
  ],
})
export class TeamBillingPendingChangesModalComponent {
  faInfoCircle = faInfoCircle;
  TEAM_PRODUCTS = TEAM_PRODUCTS;
  items: {
    name: string;
    quantity: number;
    quantityAfter: number;
    price: ProductPrice,
  }[] = [];
  prices: Map<string, ProductPrice> = new Map();
  openingStripe = false;
  invoiceLoaded = false;
  isAnnual = false;

  sumNow = 0;
  sumAfter = 0;
  upcomingInvoice: Stripe.Invoice | undefined;

  afterItems: UpdateBillingItem[] = [];

  @Output() close = new EventEmitter<BillingInterval>();
  @Input() data!: TeamBillingPendingChangesModalInput;

  constructor(protected billingService: BillingService, private toastService: ToastService) { }

  async ngOnInit() {
    const allPrices = await this.billingService.getAllProductsPrices();
    this.prices = new Map(allPrices.map(p => [p.id, p]));

    for (const [productId, product] of TEAM_PRODUCTS) {
      const priceId = product.stripePriceId[this.data.billingInterval];
      const quantity = this.data.currentItems.get(priceId) ?? 0;
      const quantityAfter = this.data.afterItems.get(priceId) ?? 0;
      const price = this.prices.get(priceId);

      if (!price) throw new Error(`Unable to find price ${priceId}`);

      if (quantity || quantityAfter) {
        this.items.push({ name: product?.name, quantity, quantityAfter, price });
      }

      this.sumNow += quantity * price.amount;
      this.sumAfter += quantityAfter * price.amount;
    }

    this.upcomingInvoice = await this.billingService.getTeamUpcomingInvoice(this.data.teamId, {
      customerId: this.data.billingData.stripe?.customerId,
      subscriptionId: this.data.billingData.stripe?.subscriptionId,
      items: this.data.billingPendingChanges.items,
    });
    this.invoiceLoaded = true;
  }

  get nextChargeDate() {
    // it will set to now if billing interval is changed
    const nextBilling = new Date();
    if (this.afterInterval === 'month') {
      nextBilling.setMonth(nextBilling.getMonth() + 1);
    } else {
      nextBilling.setFullYear(nextBilling.getFullYear() + 1);
    }
    return nextBilling;
  }

  isChangingInterval = false;

  get chargeNow() {
    const charge = (this.upcomingInvoice?.total ?? 0) + (this.upcomingInvoice?.starting_balance ?? 0);
    return charge > 0 ? charge : 0;
  }

  get nextCharge() {
    const amount = this.sumAfter - this.balanceAfterChange;
    return amount > 0 ? amount : 0;
  }

  get proration() {
    return this.sumAfter - (this.upcomingInvoice?.total ?? 0);
  }

  get balance() {
    return -(this.upcomingInvoice?.starting_balance ?? 0);
  }

  get balanceAfterChange() {
    return -(this.upcomingInvoice?.ending_balance ?? 0);
  }

  get nowInterval() {
    return this.data.billingInterval;
  }

  get afterInterval() {
    return this.data.billingInterval === 'month' ? 'year' : 'month';
  }

  get intervalShort() {
    return this.nowInterval === 'month' ? 'mo' : 'yr';
  }

  get intervalLong() {
    return this.nowInterval === 'month' ? 'month' : 'year';
  }

  get balanceTooltip() {
    return `Your balance will be used to reduce upcoming invoices. Your current balance is $${this.balance / 100}, balance after change $${this.balanceAfterChange / 100}`;
  }

  cancel() {
    this.close.emit();
  }
}
