import { Component } from '@angular/core';
import { options } from 'magma/common/data';
import { faEnvelope, faInfoCircle, farExclamationCircle, socialIcons } from 'magma/common/icons';
import { ErrorReporter } from 'magma/services/errorReporter';
import { ToastService } from 'magma/services/toast.service';
import { UserService } from 'services/user.service';

@Component({
  selector: 'account-password',
  templateUrl: './account-password.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './account-password.component.scss',
  ],
})
export class AccountPasswordComponent {
  warningIcon = faInfoCircle;
  errorIcon = farExclamationCircle;
  isSubmitting = false;
  newEmail = '';
  confirmPassword = '';
  password = '';
  newPassword = '';
  repeatNewPassword = '';
  changingEmail = false;
  changingPassword = false;
  settingEmail = false;
  error: string | undefined = undefined;
  constructor(private userService: UserService, private toastService: ToastService, private errorReporter: ErrorReporter) {
  }
  get disabled() {
    return !!options.disableChangingProfile && !this.user?.isSuperAdmin;
  }
  get user() {
    return this.userService.user;
  }
  get oauthIcon() {
    return socialIcons[this.user?.oauth as any];
  }
  get IsSaml() {
    return this.user?.oauth === 'okta';
  }

  // email
  changeEmail() {
    this.hideForms();
    this.changingEmail = true;
    this.newEmail = this.user?.email ?? '';
    this.confirmPassword = '';
  }
  cancelChangingEmail() {
    this.changingEmail = false;
  }

  isEmailChanged() {
    return this.user!.email === this.newEmail;
  }

  async submitEmail() {
    if (this.isEmailChanged()) return;

    await this.submitForm(async () => {
      await this.userService.changeEmail({ email: this.newEmail, password: this.confirmPassword });
      this.changingEmail = false;
    }, 'Email successfully updated');
  }

  // password
  changePassword() {
    this.hideForms();
    this.changingPassword = true;
    this.password = '';
    this.newPassword = '';
    this.repeatNewPassword = '';
  }
  cancelChangingPassword() {
    this.changingPassword = false;
  }
  async submitPassword() {
    await this.submitForm(async () => {
      await this.userService.changePassword({
        password: this.password,
        newPassword: this.newPassword,
        repeatNewPassword: this.repeatNewPassword,
      });
      this.changingPassword = false;
    }, 'Password successfully updated');
  }

  // email & password

  async resendEmail() {
    if (!this.user?.unverifiedEmail) return;

    await this.submitForm(
      () => this.userService.resendEmailVerification(),
      `We've send a link to verify your email to ${this.user.unverifiedEmail}, please check your inbox`,
      'Failed to resend email', faEnvelope);
  }
  async resetPassword() {
    const email = this.user?.email ?? this.user?.unverifiedEmail;
    if (!email) return;

    await this.submitForm(
      () => this.userService.resetPassword(email),
      `We've send a link to reset your password to ${email}, please check your inbox`,
      'Failed to reset password', faEnvelope);
  }
  setupEmail() {
    this.newEmail = '';
    this.settingEmail = true;
  }
  cancelSettingEmail() {
    this.settingEmail = false;
  }
  async submitSetupEmail() {
    await this.submitForm(
      async () => {
        await this.userService.initEmail({ email: this.newEmail });
        this.settingEmail = false;
      },
      `We've send a link to reset your password to ${this.newEmail}, please check your inbox`,
      'Failed to set an email address', faEnvelope);
  }

  // utils

  private hideForms() {
    this.error = undefined;
    this.cancelChangingEmail();
    this.cancelChangingPassword();
    this.cancelSettingEmail();
  }
  private async submitForm(action: () => Promise<void>, successMessage: string, errorMessage?: string, successIcon?: any) {
    try {
      this.error = undefined;
      this.isSubmitting = true;
      await action();
      this.toastService.success({ message: successMessage, icon: successIcon });
    } catch (e) {
      if (!e.userError) {
        this.errorReporter.reportError(e.message, e);
      }

      if (errorMessage) {
        this.toastService.error({ message: errorMessage, subtitle: e.message });
      } else {
        this.error = e.message;
      }
    } finally {
      this.isSubmitting = false;
    }
  }
}
