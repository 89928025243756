import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { infoIcon } from 'magma/common/icons';
import { Analytics } from 'magma/common/interfaces';
import { ITrackService } from 'magma/services/track.service.interface';
import { TeamData } from 'shared/interfaces';

@Component({
  selector: 'storage-limit-exceeded-modal',
  templateUrl: 'storage-limit-exceeded-modal.component.pug',
  styleUrls: ['./storage-limit-exceeded-modal.component.scss'],
})
export class StorageLimitExceededModalComponent implements AfterViewInit {
  readonly infoIcon = infoIcon;
  readonly storageLimitLink = 'https://magm.ai/cloud-storage';
  readonly blazePlanLink = 'https://magm.ai/blaze-features';
  readonly pricingLink = 'https://magm.ai/pricing';
  @Input() data!: { team: TeamData | undefined, userPro: boolean | undefined, userId: string };
  @Output() close = new EventEmitter<boolean | undefined>();
  readMoreTooltipIsOpen = false;
  @ViewChild('storageInfoTooltip', { static: false }) storageInfoTooltip!: ElementRef;


  constructor(private track: ITrackService) {
  }

  ngOnInit() {
    this.track.event(Analytics.StorageLimitExceeded, {
      type: this.data.team ? 'Artspace' : 'Artdesk',
      userId: this.data.userId,
      artspaceId: this.data.team?._id
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.storageInfoTooltip?.nativeElement?.addEventListener('mouseenter', () => {
        this.readMoreTooltipIsOpen = true;
      });
    }, 0);
  }

  onClose(response = false) {
    this.close.emit(response);
  }

  hideToolTip() {
    this.readMoreTooltipIsOpen = false;
  }

  getStorageAction() {
    if (this.data.team) {
      if (this.data.team.pro) return 'Contact us';
      else return 'Get more storage';
    } else {
      if (this.data.userPro) return 'Contact us';
      else return 'Get more storage';
    }
    return '';
  }
}
