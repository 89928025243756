import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { InvitationService } from 'services/invitation.service';
import { TeamService } from 'services/team.service';

@Injectable()
export class TeamInviteGuard implements CanActivate {
  constructor(private router: Router, private invitationService: InvitationService, private teamService: TeamService) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    const inviteId = route.params.id;
    try {
      const invite = await this.invitationService.getInviteTokenById(inviteId);
      if (invite) {
        if (route.queryParams.fromLogin) {
          try {
            await this.teamService.joinTeam(invite);
            return true;
          } catch(e) {
            DEVELOPMENT && console.error(e);
          }
        }
        return this.router.navigate(['my'], { state: { invite } });
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
    }
    return true;
  }
}
