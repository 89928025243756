import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Permission } from 'magma/common/interfaces';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ProjectQuery } from 'services/projects.query';
import { TeamMembersService } from 'services/team-members.service';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { UserService } from 'services/user.service';
import { routeToTeam } from 'shared/utils';

@Injectable()
export class TeamProjectsGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private teamsQuery: TeamsQuery,
    private teamService: TeamService,
    private teamMemberService: TeamMembersService,
    private projectQuery: ProjectQuery,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return combineLatest([
      this.userService.user$,
      this.teamsQuery.selectEntity(route.params.team),
      this.projectQuery.selectEntity(route.params.project),
      this.teamService.teamsReady$.pipe(filter(s => s)),
      this.teamMemberService.teamMembersReady$.pipe(filter(s => s)),
    ]).pipe(map(([user, team, project]) => {
      if (user && user.userType !== 'anonymous' && team) {
        if (this.teamMemberService.isPermissionFlagSet(Permission.CanViewEntities)) {
          return true;
        } else if (project && this.teamMemberService.isPermissionFlagSet(Permission.CanViewEntities, project)) {
          return true;
        } else {
          if (!project) { // project is empty for live-now and recent pages
            // allow if user has access to any project in this team
            for (const p of team.projects) {
              if (this.teamMemberService.isPermissionFlagSet(Permission.CanViewEntities, p)) {
                return true;
              }
            }
          }

          void this.router.navigate(routeToTeam(team.slug, 'no-access'));
          return false;
        }
      } else {
        void this.router.navigate(['/my']);
        return false;
      }
    }));
  }
}
