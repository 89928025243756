import { Component, EventEmitter, Input, Output } from '@angular/core';
import { farEllipsisH, farEllipsisV } from 'magma/common/icons';
import { ContextMenu } from 'magma/components/shared/directives/contextMenu';
import { EntityData, TeamRole, ProjectData } from 'shared/interfaces';
import { EntityToolbarButton } from 'util/entities-toolbar';

@Component({
  selector: 'entity-action-toolbar',
  templateUrl: './entity-action-toolbar.component.pug',
  styleUrls: ['./entity-action-toolbar.component.scss'],
})
export class EntityActionToolbarComponent {
  readonly farEllipsisH = farEllipsisH;
  readonly farEllipsisV = farEllipsisV;
  
  @Input() roles: TeamRole[] = [];
  @Input() entity!: EntityData;
  @Input() project!: ProjectData | undefined;
  @Input() compact!: boolean;
  @Input() isBinFolder = false;
  @Output() clicked = new EventEmitter<EntityToolbarButton>();

  async action(button: EntityToolbarButton, menu: ContextMenu) {
    this.clicked.emit(button);
    if (!button.clickedTitle) menu.close();
  }
}
