import { TeamData, TeamType } from './../../../shared/interfaces';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountData, CreateDrawingData, Feature } from 'magma/common/interfaces';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { createUser } from 'magma/common/user';
import type { Model } from 'magma/services/model';
import { uploadAvatar } from 'magma/services/uploadService';
import { EntityType } from 'shared/interfaces';
import { AuthService } from './auth.service';
import { EntitiesService } from './entities.service';
import { UserService } from './user.service';
import { getAnonymousNumber } from 'shared/utils';
import { Router } from '@angular/router';
import { ModalService } from './modal.service';
import { ToastService } from 'magma/services/toast.service';
import { TeamsQuery } from './team.query';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { defaultSettings, parseSettings } from 'magma/common/settings';
import { contactSupportIntercom } from 'magma/common/utils';
import { ManageTeamData } from 'magma/services/manageService';

@Injectable()
@UntilDestroy()
export class MagmaModel implements Partial<Model> {
  typeName = 'MagmaModel';
  user = createUser('', 0, '');
  settings = defaultSettings();
  onUserError = new Subject<any>();
  connectedStateChanged = new BehaviorSubject<boolean>(true);
  discount = 0;
  discountMonths = 0;
  isConnected = true;
  isSleeping = false;
  constructor(
    private entitiesService: EntitiesService,
    private authService: AuthService,
    private userService: UserService,
    private teamsQuery: TeamsQuery,
    private toastService: ToastService,
    private router: Router,
    public modals: ModalService,
  ) {
    combineLatest([
      this.userService.user$,
      this.teamsQuery.selectActive().pipe(map(team => team?.pro), distinctUntilChanged()),
    ]).pipe(untilDestroyed(this)).subscribe(([user, teamPro]) => {
      if (!user) user = { _id: '', userType: 'anonymous', name: '' };
      this.user.name = user.name;
      this.user.avatar = user.avatar;
      this.user.color = user.color ?? this.user.color;
      this.user.email = user.email;
      this.user.userJob = user.workRole;
      this.user.workTags = user.workTags;
      this.user.receiveEmails = user.newsletter;
      this.user.anonymous = user.userType === 'anonymous';
      this.user.anonymousNumber = getAnonymousNumber(user._id || '0'); // somehow got undefined _id here
      this.user.pro = !!user.pro;
      this.user.proSources = user.proSources;
      this.user.subscriptionStatus = user.subscriptionStatus;
      this.user.featureFlags = new Set(user.featureFlags ?? []);
      this.user.isSuperAdmin = !!user.isSuperAdmin;
      this.user.tags = user.tags;
      this.settings = user.settings ? parseSettings(user.settings) : defaultSettings();
    });
  }
  colorTaken() {
    return false;
  }
  async changeAvatar(file: File) {
    try {
      const avatar = await uploadAvatar(file);
      await this.userService.save({ avatar });
      await this.authService.refresh().toPromise(); // TODO: this is slow, updateProfile should return updated user
    } catch (e) {
      this.toastService.error({ message: 'Failed to change avatar', subtitle: e.message });
    }
  }
  async changeColor(color: string) {
    try {
      await this.userService.save({ color });
      await this.authService.refresh().toPromise(); // TODO: this is slow, updateProfile should return updated user
    } catch (e) {
      this.toastService.error({ message: 'Failed to change color', subtitle: e.message });
    }
  }
  async updateAccount(data: AccountData) {
    await this.userService.save({
      avatar: data.avatar,
      color: data.color,
      name: data.name,
      newsletter: data.receiveEmails,
      workRole: data.userJob,
    });
    await this.authService.refresh().toPromise(); // TODO: this is slow, updateProfile should return updated user
  }
  async signOut() {
    await this.authService.logout();
  }
  async createDrawing(createData: CreateDrawingData) {
    const entity = await this.entitiesService.createNewEntity(EntityType.Drawing, createData, 'new-drawing-modal');
    return entity.shortId;
  }
  reloadSession() {
  }
  sendMessage() {
  }
  upgrade(openedBy: string) {
    // is user has failed charge, redirect them to their billing page
    if (IS_PORTAL && this.user?.subscriptionStatus?.status === 'unpaid') {
      void this.router.navigate(['/my/account/billing']);
    } else {
      this.modals.upgradeModal(openedBy);
    }
  }
  allowedFolderProjectTeam({ team, project, folder }: { folder?: string; project?: string; team?: string; }) {
    // ignore team and project if user is not a member of this team
    if (team && !this.teamsQuery.getAll().some(t => t._id === team)) {
      team = undefined;
      project = undefined;
    }

    return { team, project, folder };
  }

  async getStorageUsage(_teamId?: string) {
    return {used: 0, limit: 1};
  }

  isStorageLimitActive(team?: ManageTeamData) {
    if (IS_HOSTED) return false;

    if (team) {
      return !team.featureFlags?.includes(Feature.StorageNoUsageLimits);
    }
    return !this.user.featureFlags.has(Feature.StorageNoUsageLimits);
  }
}
