import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { exclamationSquareIcon, infoIcon } from 'magma/common/icons';
import { Analytics } from 'magma/common/interfaces';
import { ITrackService } from 'magma/services/track.service.interface';
import { TeamData } from 'shared/interfaces';

export interface DeleteTeamConfirmationModalInput {
  team?: TeamData;
  openedBy: string;
  membersCount: number;
}

@Component({
  selector: 'delete-team-confirmation-modal',
  templateUrl: 'delete-team-confirmation-modal.component.pug',
  styleUrls: ['./delete-team-confirmation-modal.component.scss'],
})
export class DeleteTeamConfirmationModalComponent implements OnInit {
  @Output() close = new EventEmitter<boolean | undefined>();
  @Input() data: DeleteTeamConfirmationModalInput = { team: undefined, openedBy: '', membersCount: 0 };

  readonly infoIcon = infoIcon;

  constructor(private track: ITrackService) {
  }

  get name() {
    return `"${this.data.team?.name}"`;
  }

  get toMarkForDeletion() {
    return this.data.team?.isPublic && this.data.membersCount > 1;
  }

  ngOnInit() {
    this.track.event(Analytics.OpenDeleteTeamModal, { openedBy: this.data.openedBy });
  }

  onDelete() {
    this.close.emit(true);
  }

  onClose() {
    this.close.emit(undefined);
  }
}
