import { BillingInterval, BillingItem, CustomerBillingInfo, ProductPrice, ScheduledTeamBillingUpdate, StorageUsageData, TeamData, TeamType } from './../../../../../shared/interfaces';
import { MAX_FREE_PRIVATE_ARTSPACE_MEMBERS, CC_PRODUCT_EDITOR_PRICE_M, CC_PRODUCT_VIEWER_PRICE_M, TEAM_PRODUCTS, CC_PRODUCT_REVIEWER_PRICE_M } from 'shared/billing';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { faInfoCircle, farCheckCircle, farExclamationCircle, farExclamationTriangle } from 'magma/generated/fa-icons';
import { getBillingItems, getPlanForMember, getPlansRequiredForMembers, getPlansRequiredForMembersWithUser } from 'shared/utils';
import { BillingService } from 'services/billing.service';
import { Component } from '@angular/core';
import { ErrorReporter } from 'magma/services/errorReporter';
import { ModalService } from 'services/modal.service';
import { PaymentService } from 'services/payment.service';
import { TeamMembersQuery } from 'services/team-members.query';
import { TeamsQuery } from 'services/team.query';
import { ToastService } from 'magma/services/toast.service';
import { UserService } from 'services/user.service';
import { delay } from 'magma/common/promiseUtils';
import { map } from 'rxjs/operators';
import { TeamService } from 'services/team.service';
import { BaseBillingComponent } from 'components/base-billing.component';
import { AppService } from 'services/app.service';
import { firstValueFrom } from 'rxjs';
import { Feature } from 'magma/common/interfaces';

@UntilDestroy() @Component({
  selector: 'team-settings-billing',
  templateUrl: './team-settings-billing.component.pug',
  styleUrls: ['../../account-common.component.scss',
    './team-settings-billing.component.scss'],
  host: { class: 'use-magma-styles' },
})
export class TeamSettingsBillingComponent extends BaseBillingComponent {
  infoIcon = faInfoCircle;
  errorIcon = farExclamationCircle;

  limitOk = farCheckCircle;
  limitNok = farExclamationTriangle;

  MAX_FREE_PRIVATE_ARTSPACE_MEMBERS = MAX_FREE_PRIVATE_ARTSPACE_MEMBERS;

  products = new Map<string, BillingItem>();
  productsQuantity = new Map<string, number>();
  billingInterval: BillingInterval = 'month';

  team?: TeamData;

  membersCount$ = this.teamMembersQuery.selectCount();
  memberPlans$ = this.teamMembersQuery.selectAll().pipe(map(members => members.map(m => getPlanForMember(m))));

  productsNeeded = new Map<string, number>(); // stripeProductId: quantity
  isBillingValid = false;
  prices: Map<string, ProductPrice> = new Map();

  billingPendingChanges: ScheduledTeamBillingUpdate | undefined;
  storage: StorageUsageData | undefined;
  cancelAnswers = [
    'We didn\'t have time to set it up',
    'We didn\'t have time to use it',
    'It was difficult for us to learn',
    'It haven\'t improved our process',
    'We prefer other collaboration tools',
    'It was too expensive',
    'Some important features were missing',
    'Customer service was unsatisfactory',
    'Other',
  ];

  constructor(
    private billingService: BillingService,
    private paymentService: PaymentService,
    private modals: ModalService,
    userService: UserService,
    toastService: ToastService,
    errorReporter: ErrorReporter,
    private teamsQuery: TeamsQuery,
    private teamMembersQuery: TeamMembersQuery,
    private teamService: TeamService,
    private appService: AppService,
  ) {
    super(userService, toastService, errorReporter);

    this.memberPlans$.pipe(untilDestroyed(this)).subscribe(plans => {
      for (const [productId, productData] of TEAM_PRODUCTS) {
        const count = plans.reduce((count, plan) => count + (plan === productId ? 1 : 0), 0);
        this.productsNeeded.set(productId, count);
      }
      return this.productsNeeded;
    });
  }

  get noUserLimit() {
    return !!this.team?.featureFlags?.includes(Feature.NoUserLimit);
  }

  get isFreeMemberCountOk() {
    return this.teamMembersQuery.getCount() <= MAX_FREE_PRIVATE_ARTSPACE_MEMBERS || this.noUserLimit;
  }

  get isFreeUsageCountOk() {
    return this.storage && this.storage.used < this.storage.limit;
  }

  get hasSubscription() {
    return this.team?.pro || (!!this.billingData?.stripe?.subscriptionId && !this.isExpired);
  }

  get forcedPro() {
    if (!this.team) return false;
    return this.team.forcePro || (this.team.forceProUntil && new Date(this.team.forceProUntil).getTime() > Date.now());
  }

  get arePendingChanges() {
    return this.team?.pro && !!this.billingPendingChanges && !this.isCancelled;
  }

  get editorPrice() {
    return this.prices.get(CC_PRODUCT_EDITOR_PRICE_M)?.amount ?? 0;
  }

  get reviewerPrice() {
    return this.prices.get(CC_PRODUCT_REVIEWER_PRICE_M)?.amount ?? 0;
  }

  get viewerPrice() {
    return this.prices.get(CC_PRODUCT_VIEWER_PRICE_M)?.amount ?? 0;
  }

  getProduct(productId: string) {
    return TEAM_PRODUCTS.get(productId);
  }

  get intervalShort() {
    return this.billingData?.interval === 'year' ? 'yr' : 'mo';
  }

  get intervalLong() {
    return this.billingData?.interval === 'year' ? 'year' : 'month';
  }

  get canConvertToStudioPlan() {
    return this.teamType === TeamType.Private;
  }

  get teamType() {
    return this.team && this.billingService.getTeamType(this.team);
  }

  get planName() {
    switch (this.teamType) {
      case TeamType.Professional:
        return 'Professional';
      case TeamType.Private:
      case TeamType.Public:
        return 'Starter';
      case TeamType.Enterprise:
        return 'Enterprise';
    }
  }

  get isPublic() {
    return this.team?.isPublic;
  }

  async ngOnInit() {
    this.team = this.teamsQuery.getActive();
    this.loadBillingData().catch(e => this.showAndReportError(e));
    if (this.team) {
      this.storage = await this.teamService.getUsageData(this.team?._id).toPromise();
    }

    const allPrices = await this.billingService.getAllProductsPrices();
    this.prices = new Map(allPrices.map(p => [p.id, p]));
  }

  private async loadBillingData() {
    if (!this.team) return;
    const { billing } = await firstValueFrom(this.billingService.getTeamBillingInformation(this.team._id));
    if (billing) {
      this.billingData = billing;
      if (this.billingData.expiry?.isExpired === false) {
        this.billingInterval = this.billingData.interval;
        this.products = new Map(this.billingData?.stripe?.items?.map(i => [i.product, i]));
        this.productsQuantity = new Map(this.billingData?.stripe?.items?.map(i => [i.product, i.quantity]));
      }
    }
    const changes = await this.billingService.getTeamBillingPendingChanges(this.team._id).toPromise();
    if (changes?.items && changes.items.length > 0) {
      this.billingPendingChanges = changes;
    }
    this.isBillingValid = await this.billingService.isBillingValid(this.team, this.billingData);
    this.billingLoaded = true;
  }

  onChangeQuantity(product: string, event: Event) {
    const quantity = parseInt((event.target as HTMLInputElement).value);
    this.productsQuantity.set(product, quantity);
  }

  async getUpcomingInvoice() {
    if (!this.team) return;

    try {
      this.isChangingPlan = true;
      if (this.team && this.billingData?.stripe?.subscriptionId) {
        const requiredItems = getPlansRequiredForMembersWithUser(this.teamMembersQuery.getAll()); // bad
        await this.modals.invoicePreview({ teamId: this.team._id, teamSlug: this.team.slug, items: requiredItems, billingInterval: this.billingInterval });
      }
    } catch (e) {
      this.showAndReportError(e);
    } finally {
      this.isChangingPlan = false;
    }
  }

  async changeSubscriptionInterval() {
    if (this.team && this.billingData) {
      const requiredItems = getPlansRequiredForMembers(this.teamMembersQuery.getAll());
      await this.modals.teamBillingChangeInterval({
        teamId: this.team._id,
        teamSlug: this.team.slug,
        items: requiredItems,
        billingInterval: this.billingInterval,
        billingData: this.billingData,
      });
      await this.loadBillingData();
    } else {
      DEVELOPMENT && console.error('Missing active team');
    }
  }

  async upgradeSubscription() {
    if (this.team) {
      await this.modals.upgradeTeamSubscriptionModal({
        teamId: this.team._id, teamSlug: this.team.slug,
        alreadyOnProPlan: this.billingService.getTeamType(this.team) === TeamType.Professional
      });
    } else {
      DEVELOPMENT && console.error('Missing active team');
    }
  }

  async reviewBillingChanges() {
    if (this.team && this.billingData && this.billingPendingChanges) {
      const currentItems = new Map(this.billingData.stripe?.items.map(i => [i.price, i.quantity]));
      const afterItems = new Map(this.billingPendingChanges?.items?.map(i => [i.price, i.quantity]));

      await this.modals.teamBillingPendingChanges({
        teamId: this.team._id,
        teamSlug: this.team.slug,
        currentItems,
        afterItems,
        billingInterval: this.billingInterval,
        billingData: this.billingData,
        billingPendingChanges: this.billingPendingChanges,
      });
    }
  }

  async updateSubscription() {
    if (!this.team) return;
    if (!this.billingData) return;

    try {
      this.isChangingPlan = true;
      if (this.team) {
        const items = getBillingItems(this.billingData, this.productsNeeded, this.billingInterval);
        await this.billingService.updateTeamSubscription(this.team._id, items);
        await this.loadBillingData();
      }
    } catch (e) {
      this.showAndReportError(e);
    } finally {
      this.isChangingPlan = false;
    }
  }

  async cancelSubscription() {
    if (!this.team) {
      DEVELOPMENT && console.error('Missing team');
      return;
    }
    const takeEffectDate = this.billingData?.validUntil ?? new Date();
    const cancel = await this.modals.cancelSubscription({ takeEffectDate, trial: false, artspace: true, userCount: this.teamMembersQuery.getCount() });

    if (cancel) {
      try {
        this.isCancellingPlan = true;
        await this.billingService.cancelTeamSubscription(this.team._id);
        await this.loadBillingData();
        this.showCancelledSubscription = true;
        this.endOfCancelledSubscription = this.billingData?.validUntil ?? new Date();
      } catch (e) {
        this.showAndReportError(e);
      } finally {
        this.isCancellingPlan = false;
      }
    }
  }

  async restartSubscription() {
    if (!this.team) {
      DEVELOPMENT && console.error('Missing team');
      return;
    }
    try {
      this.isRestartingPlan = true;
      await this.billingService.restartTeamSubscription(this.team._id);
      await this.loadBillingData();
    } catch (e) {
      this.showAndReportError(e);
    } finally {
      this.isRestartingPlan = false;
    }
  }

  async updateCard() {
    if (!this.team) return;
    try {
      const checkout = await this.billingService.updateTeamPaymentInformation(this.team?._id);
      this.paymentService.redirectToStripeCheckout(checkout, '?upgraded=true');
    } catch (e) {
      this.showAndReportError(e);
    }
  }

  async retryPayment() {
    if (!this.team) return;
    const toast = this.toastService.loading({ message: 'Retrying payment' });
    try {
      this.isRetrying = true;
      await this.billingService.retryTeamPayment(this.team._id);
      await delay(2000);
      await this.loadBillingData();
      if (!this.isFailed) {
        this.toastService.updateToSuccess(toast, { message: 'Payment succeeded' });
      }
    } catch (error) {
      this.errorReporter.reportError(error.message, error);
      this.toastService.updateToError(toast, { message: error.message });
    } finally {
      this.isRetrying = false;
    }
  }

  async submitBillingInfo(billingInfo: CustomerBillingInfo) {
    await this.submitForm(async () => {
      if (!this.team) return;
      await this.billingService.updateTeamBillingInformation(this.team._id, billingInfo);
      if (this.billingData) this.billingData.billingInfo = { ...billingInfo };
      await this.loadBillingData();
      this.updatingBillingInfo = false;
    }, 'Billing info updated');
  }

  async submitCancelFeedback() {
    if (!this.team) return;

    const reasons = [...this.cancelChecked];
    const otherIndex = reasons.indexOf('Other');

    if (otherIndex !== -1) {
      reasons[otherIndex] += `: ${this.otherReason}`;
    }

    if (!reasons.length) return;

    await this.billingService.cancelationTeamReasons(this.team._id, reasons);
    this.showCancelledSubscription = false;
  }

  async contactUs() {
    await this.appService.onContactSupport();
  }
}
