import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectData } from 'shared/interfaces';

@Component({
  selector: 'open-project-description-modal',
  templateUrl: './open-project-description-modal.component.pug',
  styleUrls: ['./open-project-description-modal.component.scss'],
})
export class OpenProjectDescriptionModalComponent {
  @Input() data!: ProjectData;
  @Output() close = new EventEmitter<undefined>();
  constructor() {}

  onClose() {
    this.close.emit();
  }
}
