import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Analytics } from 'magma/common/interfaces';
import { ITrackService } from 'magma/services/track.service.interface';
import { TeamData } from 'shared/interfaces';

export interface TeamDeletionReasonModalInput {
  team?: TeamData;
  openedBy: string;
}

@Component({
  selector: 'team-deletion-reason-modal',
  templateUrl: 'team-deletion-reason-modal.component.pug',
  styleUrls: ['./team-deletion-reason-modal.component.scss'],
})
export class TeamDeletionReasonModalComponent implements OnInit {
  @Output() close = new EventEmitter<string | undefined>();
  @Input() data: TeamDeletionReasonModalInput = { team: undefined, openedBy: '' };
  reason = '';

  constructor(private track: ITrackService) {
  }

  ngOnInit() {
    this.track.event(Analytics.OpenDeleteTeamModal, { openedBy: this.data.openedBy });
  }

  async onSubmit() {
    this.close.emit(this.reason);
  }

  onClose() {
    this.close.emit(undefined);
  }
}
