import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currencyPipe' })
export class CurrencyPipe implements PipeTransform {
  constructor() {}

  transform(value: number, currency: string) {
    if (currency === 'usd') {
      const price = value / 100;
      return `${price.toFixed(2)}`;
    }
    return value;
  }
}
