
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ROLE_TYPES_READ_ONLY, TeamData } from './../../../../shared/interfaces';

import { BillingService } from 'services/billing.service';
import { TeamRole } from 'shared/interfaces';
import { TeamService } from 'services/team.service';
import { firstValueFrom } from 'rxjs';

export interface UserRolesModalInput {
  team: TeamData;
  userId: string;
  roles: string[];
}

@Component({
  selector: 'user-roles-modal',
  templateUrl: 'user-roles-modal.component.pug',
})
export class UserRolesModal {
  ROLE_TYPES_READ_ONLY = ROLE_TYPES_READ_ONLY;
  @Output() close = new EventEmitter<string[]>();
  @Input() data!: UserRolesModalInput;

  isSaving = false;
  rolesMap: Map<string, TeamRole> = new Map();
  roles: TeamRole[] = [];
  selectedRoles = new Map<string, boolean>();

  constructor(private teamService: TeamService, private billingService: BillingService) { }

  async ngOnInit() {
    this.roles = await firstValueFrom(this.teamService.getRoles(this.data.team._id));
    for (const role of this.roles) {
      this.selectedRoles.set(role._id, this.data.roles.includes(role._id));
    }
    this.rolesMap = new Map(this.roles.map(r => [r._id, r]));
  }

  selectRole(roleId: string, value: boolean) {
    this.selectedRoles.set(roleId, value);
  }

  closeModal() {
    this.close.emit();
  }

  getRoleName(roleId: string) {
    return this.rolesMap.get(roleId)?.name;
  }

  getRoleType(roleId: string) {
    return this.rolesMap.get(roleId)?.type;
  }

  async saveChanges() {
    this.isSaving = true;
    const selectedRoles = Array.from(this.selectedRoles.entries())
      .filter(([_, selected]) => selected).map(([roleId]) => roleId);

    this.close.emit(selectedRoles);
    this.isSaving = false;
  }
}
