import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { blazeIcon } from 'magma/common/icons';
import { Analytics } from 'magma/common/interfaces';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { ITrackService } from 'magma/services/track.service.interface';
import { ModalService } from 'services/modal.service';
import { UserService } from 'services/user.service';

// General announcement modal component, change this modal to new announcements when needed
// instead of creating new modal for each new announcement.

@Component({
  selector: 'announcement-modal',
  templateUrl: 'announcement-modal.component.pug',
  styleUrls: ['./announcement-modal.component.scss'],
})
export class AnnouncementModalComponent implements OnInit {
  readonly proIcon = blazeIcon;

  @Output() close = new EventEmitter<void>();

  constructor(
    private track: ITrackService,
    private loginSignup: LoginSignupService,
    private modals: ModalService,
    private userService: UserService,
  ) {
  }
  
  get hasPro() {
    return !!this.userService.user?.pro;
  }

  ngOnInit() {
    this.track.event(Analytics.OpenFeatureNotificationModal, { label: 'Blaze Brushes' });
    this.loginSignup.dismissNewFeature('brushes');
  }

  async join() {
    const url = 'https://magm.ai/magma-beta-artspace-invite';
    this.track.event(Analytics.OpenExternalLink, { url, shortUrlSlug: 'magma-beta-artspace-invite' });
    window.location.href = url;
  }

  closeModal() {
    this.track.event(Analytics.CloseFeatureNotificationModal, { label: 'Blaze Brushes' });
    this.close.emit();
  }

  newBrushes() {
    this.modals.newBrushes();
    this.close.emit();
  }
}
