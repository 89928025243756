import 'cookieconsent';
import { getValueOfVariable } from 'magma/common/utils';

if (!process.env.SELF_HOSTED_BUILD) {
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: '#000',
      },
      button: {
        background: '#f1d600',
      },
    },
    theme: 'edgeless',
    content: {
      message: 'We may store some information inside your browser to ensure your best experience with our website.',
    },
    law: {
      countryCode: getValueOfVariable('countryCode'),
      regionalLaw: false,
    },
  });
}
