import { ModalService } from 'services/modal.service';
import { BillingInterval, ProductPrice, TeamMemberUserData } from '../../../../../shared/interfaces';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingService } from 'services/billing.service';
import { TEAM_PRODUCTS } from 'shared/billing';
import { ToastService } from 'magma/services/toast.service';
import { Router } from '@angular/router';
import { routeToTeam } from 'shared/utils';

export interface CreateTeamSubscriptionModalInput {
  teamId: string;
  teamSlug: string;
  items: Map<string, { count: number, users: TeamMemberUserData[] }>; // stripeProductId: user
  billingInterval: BillingInterval;
}

@Component({
  selector: 'create-team-subscription-modal',
  templateUrl: 'create-team-subscription-modal.component.pug',
  styleUrls: ['create-team-subscription-modal.component.scss'],
})
export class CreateTeamSubscriptionModalComponent implements OnInit {
  TEAM_PRODUCTS = TEAM_PRODUCTS;
  items: {
    name: string;
    quantity: number;
    price: { [key: string]: ProductPrice },
    users: TeamMemberUserData[]
  }[] = []; 
  prices: Map<string,ProductPrice> =  new Map();
  openingStripe = false;

  isAnnual = false;
  get billingInterval(): BillingInterval {
    return this.isAnnual ? 'year' : 'month';
  }

  get intervalShort() {
    return this.isAnnual ? 'yr' : 'mo';
  }

  get intervalLong() {
    return this.isAnnual ? 'year' : 'month';
  }
  sumMonth = 0;
  sumAnnual = 0;
  get sum() {
    return this.isAnnual ? this.sumAnnual : this.sumMonth;
  }

  get subtotal() {
    return this.isAnnual ? this.sum / (12 * 100) : this.sum / 100;
  }

  @Output() close = new EventEmitter<BillingInterval>();
  @Input() data!: CreateTeamSubscriptionModalInput;

  constructor(private router: Router, private modalService: ModalService, protected billingService: BillingService, private toastService: ToastService) { }

  async ngOnInit() {
    this.isAnnual = this.data.billingInterval === 'year';
    const allPrices = await this.billingService.getAllProductsPrices();
    this.prices = new Map(allPrices.map(p => [p.id, p]));

    for (const [productId, data] of this.data.items) {
      const product = TEAM_PRODUCTS.get(productId);
      if (!product) {
        DEVELOPMENT && console.error('Invalid product id');
        continue;
      }

      const month =  this.prices.get(product.stripePriceId['month']);
      const year =  this.prices.get(product.stripePriceId['year']);

      if (!month || !year) {
        this.toastService.error({ message: 'Failed to get product prices. Refresh page'});
        continue;
      }

      this.items.push({
        name: product?.name,
        quantity: data.count,
        price: { month, year },
        users: data.users
      });

      this.sumMonth += (this.prices.get(product.stripePriceId['month'])?.amount ?? 0) * data.count;
      this.sumAnnual += (this.prices.get(product.stripePriceId['year'])?.amount ?? 0) * data.count;
    }
  }

  adjustRoles() {
    void this.router.navigate(routeToTeam(this.data.teamSlug, ['settings', 'members']));
    this.modalService.closeAll();
  }

  cancel() {
    this.close.emit();
  }

  async accept() {
    this.openingStripe = true;
    try {
      const items = this.items.map(i => ({ plan: i.price[this.billingInterval].id, quantity: i.quantity }));
      await this.billingService.upgradeCurrentTeam(this.data.teamId, items);
    } catch (e) {
      this.toastService.error({ message: 'Failed to open stripe', subtitle: e.message });
    }
    this.openingStripe = false;
  }
}
