import { ApplicationRef, EmbeddedViewRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TopLevelComponentLoaderService {
  constructor(private applicationRef: ApplicationRef, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private _container: HTMLElement | null = null;
  private renderer: Renderer2 | null = null;

  get container(): HTMLElement {
    if (!this.renderer) {
      throw new Error('unable to acquire renderer');
    }

    if (!this._container) {
      const componentRootViewContainer = this.applicationRef.components[0];
      const appRootElement = (componentRootViewContainer.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

      let topLevelComponentContainer = appRootElement.querySelector('#top-level-components-container') as HTMLElement;

      if (!topLevelComponentContainer) {
        topLevelComponentContainer = this.renderer.createElement('div');
        this.renderer.setProperty(topLevelComponentContainer, 'id', 'top-level-component-container');
        this.renderer.setProperty(topLevelComponentContainer, 'style', 'position: absolute; top: 0; left: 0;');
        this.renderer.appendChild(appRootElement, topLevelComponentContainer);
      }

      this._container = topLevelComponentContainer;
    }

    return this._container;
  }
}
