<div class="card-wrapper" [ngSwitch]="cardType">
  <img *ngSwitchCase="CARD_TYPES.VISA" class="card-img" src="/assets/visa.a513b547.png" [alt]="cardType">
  <img *ngSwitchCase="CARD_TYPES.MASTER_CARD" class="card-img" src="/assets/mastercard.e1114d2a.png" [alt]="cardType">
  <img *ngSwitchCase="CARD_TYPES.AMERICAN_EXPRESS" class="card-img" src="/assets/amex.d53d69d5.png" [alt]="cardType">
  <img *ngSwitchCase="CARD_TYPES.DISCOVER" class="card-img" src="/assets/discover.de2ccf96.png" [alt]="cardType">
  <img *ngSwitchCase="CARD_TYPES.DINER_CLUB" class="card-img" src="/assets/diners.7a4b5534.png" [alt]="cardType">
  <img *ngSwitchCase="CARD_TYPES.JCB" class="card-img" src="/assets/jcb.0dfba7eb.png" [alt]="cardType">
  <ng-container *ngSwitchDefault>
    <img *ngIf="cardType" class="card-img" src="/assets/general.b8535d3a.png" [alt]="cardType">
  </ng-container>
  
</div>
