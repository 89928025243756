import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[outsideClick]' })
export class OutsideClickDirective {
  @Output() outsideClick = new EventEmitter();
  constructor(private elementRef: ElementRef<HTMLElement>) { }
  @HostListener('document:click', ['$event.target'])
  onClick(target: EventTarget) {
    const clickedInside = this.elementRef.nativeElement.contains(target as any);
    if (!clickedInside) {
      this.outsideClick.emit();
    }
  }
}
