import { BillingInterval, ProductPrice } from '../../../../../shared/interfaces';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingService } from 'services/billing.service';
import { CC_PRODUCT_EDITOR_PRICE_M, CC_PRODUCT_REVIEWER_PRICE_M, CC_PRODUCT_VIEWER_PRICE_M, TEAM_PRODUCTS } from 'shared/billing';
import { getPlansRequiredForMembersWithUser } from 'shared/utils';
import { TeamMembersQuery } from 'services/team-members.query';
import { ModalService } from 'services/modal.service';
import { blazeIcon } from 'magma/common/icons';

const { enterpriseSalesLink } = PRODUCT_INFO;

export interface UpgradeTeamSubscriptionModalInput {
  teamId: string;
  teamSlug: string;
  alreadyOnProPlan?: boolean;
}

@Component({
  selector: 'upgrade-team-subscription-modal',
  templateUrl: 'upgrade-team-subscription-modal.component.pug',
  styleUrls: ['upgrade-team-subscription-modal.component.scss'],
})
export class UpgradeTeamSubscriptionModalComponent implements OnInit {
  enterpriseSalesLink = enterpriseSalesLink;
  proIcon = blazeIcon;
  TEAM_PRODUCTS = TEAM_PRODUCTS;
  prices: Map<string, ProductPrice> = new Map();

  @Output() close = new EventEmitter<BillingInterval>();
  @Input() data!: UpgradeTeamSubscriptionModalInput;

  constructor(
    private teamMembersQuery: TeamMembersQuery,
    private billingService: BillingService,
    private modalsService: ModalService,
  ) { }

  async ngOnInit() {
    const allPrices = await this.billingService.getAllProductsPrices();
    this.prices = new Map(allPrices.map(p => [p.id, p]));
  }

  get editorPrice() {
    return this.prices.get(CC_PRODUCT_EDITOR_PRICE_M)?.amount ?? 0;
  }

  get reviewerPrice() {
    return this.prices.get(CC_PRODUCT_REVIEWER_PRICE_M)?.amount ?? 0;
  }

  get viewerPrice() {
    return this.prices.get(CC_PRODUCT_VIEWER_PRICE_M)?.amount ?? 0;
  }

  cancel() {
    this.close.emit();
  }

  async upgrade() {
    const requiredItems = getPlansRequiredForMembersWithUser(this.teamMembersQuery.getAll());
    await this.modalsService.createTeamSubscriptionModal({ teamId: this.data.teamId, teamSlug: this.data.teamSlug, items: requiredItems, billingInterval: 'month' });
  }

  async infoEducationalPlan() {
    await this.billingService.infoEducationalPlan();
  }

  async infoCommunityPlan() {
    await this.billingService.infoCommunityPlan();
  }
}
