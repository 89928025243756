import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'magma/common/interfaces';
import { faCheck } from 'magma/generated/fa-icons';
import { Model } from 'magma/services/model';

@Component({
  selector: 'manage-presentation-viewers-modal',
  templateUrl: './manage-presentation-viewers-modal.component.pug',
  styleUrls: ['./manage-presentation-viewers-modal.component.scss'],
})
export class ManagePresentationViewersModal {
  readonly checkIcon = faCheck;
  selectedUsers = new Set<string>();
  selectedViewers = new Set<string>();
  invitedUsers = new Set<string>();
  selectAllViewers = false;
  selectAllCanvasUsers = false;

  @Output() close = new EventEmitter<boolean>();
  @Input() data: { model?: Model } = {};

  get presentationViewers(): User[] {
    return this.data.model!.getPresentationViewers.filter(v => this.data.model!.getUserByUniqId(v.uniqId, true));
  }

  get usersOnCanvas(): User[] {
    const usersOnCanvas = this.data.model!.users.map(v => this.data.model!.getUserByUniqId(v.uniqId, true)!).filter(u => !!u);
    this.invitedUsers.forEach(uniqId => !usersOnCanvas.some(u => u.uniqId === uniqId) && this.invitedUsers.delete(uniqId));
    return usersOnCanvas.filter(u => !this.presentationViewers.includes(u));
  }

  get selectedUsersCount() {
    return this.selectedUsers.size;
  }

  get selectedViewersCount() {
    return this.selectedViewers.size;
  }

  toggleUsersSelection(key: string) {
    if (key === 'viewers') {
      if (this.selectAllViewers) this.presentationViewers.forEach(v => this.toggleSetSelection(v.uniqId, this.selectedViewers, true));
      else this.selectedViewers.clear();
    } else if (key === 'canvasUsers') {
      if (this.selectAllCanvasUsers) this.usersOnCanvas.forEach(u => this.toggleSetSelection(u.uniqId, this.selectedUsers, true));
      else this.selectedUsers.clear();
    }
  }

  toggleSetSelection(uniqId: string, set: Set<string>, selected: boolean) {
    if (!selected) {
      set.delete(uniqId);
    } else {
      set.add(uniqId);
    }
  }

  inviteUserToPresentation(uniqId: string) {
    const { model } = this.data;

    if (model) {
      model.inviteToPresentation(uniqId);
      this.invitedUsers.add(uniqId);
    }
  }

  kickUserFromPresentation(uniqId: string) {
    const { model } = this.data;

    if (model) {
      model.kickFromPresentation([uniqId]);
      this.invitedUsers.delete(uniqId);
    }
  }

  bulkPromoteUsers() {
    this.selectedViewers.forEach(uniqId => this.toggleUserPromotion(uniqId));
  }

  toggleUserPromotion(uniqId: string) {
    const isCoPresenter = this.data.model?.isPresentationCoPresenter(uniqId);
    this.data.model?.updatePresentationCoPresenters(uniqId, !!isCoPresenter);
  }

  isCoPresenter(user: User) {
    return this.data.model?.isPresentationCoPresenter(user.uniqId);
  }

  bulkInviteUsers() {
    this.selectedUsers.forEach(userId => this.inviteUserToPresentation(userId));
  }

  bulkKickUsersFromPresentation() {
    this.data.model?.kickFromPresentation([...this.selectedViewers.values()]);
  }

  onClose(confirmed = false) {
    if (confirmed) {
      this.close.emit(true);
    } else {
      this.close.emit(undefined);
    }
  }
}
