import { Component } from '@angular/core';
import { BillingInterval, TeamMemberUserData } from 'shared/interfaces';
import { CreateTeamSubscriptionModalComponent } from '../create-team-subscription-modal/create-team-subscription-modal.component';

export interface InvoicePreviewModalInput {
  teamId: string;
  teamSlug: string;
  items: Map<string, { count: number, users: TeamMemberUserData[] }>; // stripeProductId: user
  billingInterval: BillingInterval;
}

@Component({
  selector: 'invoice-preview-modal',
  templateUrl: 'invoice-preview-modal.component.pug',
  styleUrls: ['../create-team-subscription-modal/create-team-subscription-modal.component.scss'],
})
export class InvoicePreviewModalComponent extends CreateTeamSubscriptionModalComponent {
}
