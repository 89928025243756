import { ToastService } from 'magma/services/toast.service';
import { TeamsQuery } from 'services/team.query';
import { Component } from '@angular/core';
import { faGlobe, faKey } from 'magma/common/icons';
import { TeamService } from 'services/team.service';
import { TeamData } from 'shared/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TeamMembersService } from 'services/team-members.service';
import { Feature, Permission } from 'magma/common/interfaces';
import { TeamMembersQuery } from 'services/team-members.query';
import { ModalService } from 'services/modal.service';
import { MAX_FREE_PRIVATE_ARTSPACE_MEMBERS } from 'shared/billing';
import { Router } from '@angular/router';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'team-settings-details',
  templateUrl: './team-settings-details.component.pug',
  styleUrls: [
    '../../account-common.component.scss',
    './team-settings-details.component.scss',
  ],
  host: { class: 'use-magma-styles' },
})
export class TeamSettingsDetailsComponent {
  readonly faGlobe = faGlobe;
  readonly faKey = faKey;
  url = `${location.protocol}//${location.host}`;
  shortUrl = location.host;
  activeTeam$ = this.teamsQuery.selectActive();
  team: TeamData | undefined = undefined;
  error: string | undefined = undefined;
  constructor(
    private teamsQuery: TeamsQuery,
    private teamsService: TeamService,
    private teamMemberService: TeamMembersService,
    private teamMembersQuery: TeamMembersQuery,
    private modals: ModalService,
    private toastService: ToastService,
    private router: Router,
  ) {
    this.activeTeam$.pipe(untilDestroyed(this)).subscribe(team => {
      this.team = team;
      this.error = this.team ? undefined : 'Failed to get team data';
    });
  }

  get canUpdateTeam() {
    return this.teamMemberService.isPermissionFlagSet([Permission.CanManageTeam]);
  }

  get teamMemberCount() {
    return this.teamMembersQuery.getCount();
  }

  async switchToPublic() {
    if (!this.team) return;
    const confirmed = await this.modals.switchingToPublic(this.team);
    if (confirmed) await this.switchTeamMode(true);
  }

  async switchToPrivate() {
    if (!this.team) return;
    if (this.team.pro || this.teamMemberCount <= MAX_FREE_PRIVATE_ARTSPACE_MEMBERS || this.team.featureFlags?.includes(Feature.NoUserLimit)) {
      await this.switchTeamMode(false);
    } else {
      await this.modals.switchingToPrivate(this.team);
    }
  }

  switchingModes = false;

  private async switchTeamMode(isPublic: boolean) {
    if (!this.team) return;

    this.switchingModes = true;

    try {
      await this.teamsService.updateTeam(this.team, { isPublic });
      this.toastService.success({ message: `Team mode changed to ${isPublic ? 'Public' : 'Private'}` });
    } catch (e) {
      this.toastService.error({ message: e.message });
    } finally {
      this.switchingModes = false;
    }
  }
  get canDeleteTeam() {
    return this.teamMemberService.isPermissionFlagSet([Permission.isTeamOwner]);
  }
  get teamMarkedForDeletion() {
    return !!this.team?.deletionDate;
  }
  async deleteTeam() {
    const membersCount = this.teamMembersQuery.getCount();
    const confirmed = await this.modals.deleteTeam({ team: this.team,  openedBy: 'team-settings-page', membersCount});
    if (confirmed && this.team) {
      const { _id, isPublic } = this.team;
      const currentUrl = this.router.url;
      try {
        await this.teamsService.deleteTeam(_id);
        this.toastService.success({ message: `Artspace "${this.team.name}" has been successfully deleted.` });

        if (membersCount > 1) {
          // asking for reason only when there's members of in the team
          const reason = await this.modals.teamDeletionReason({ team: this.team,  openedBy: 'team-settings-page', membersCount});
          if (reason && reason.length) await this.teamsService.teamDeletionReason(_id, reason);
        }
        window.location.href = isPublic && membersCount > 1 ? currentUrl : '/my/artworks';
      } catch (error) {
        this.toastService.error({ message: `Failed to delete Artspace ${this.team.name}`, subtitle: error.message });
      }
    }
  }
  async restoreTeam() {
    try {
      if (this.team) {
        await this.teamsService.restoreTeam(this.team._id);
        this.toastService.success({ message: `Artspace "${this.team.name}" has been unmarked for deletion successfully.` });
        window.location.href = this.router.url;
      }
    } catch (error) {
      this.toastService.error({ message: `Failed to unmark Artspace ${this.team!.name} for deletion`, subtitle: error.message });
    }
  }

  timeLeft(date: Date | string) {
    moment.updateLocale('en', {
      relativeTime: {
        future: '%s',
        past: '%s',
        d: '%d day',
        dd: '%d days',
      }
    });
    return moment(date).fromNow(true);
  }
}
