import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TextLayer } from '../../../../../../magma/src/ts/common/interfaces';

@Component({
  selector: 'rasterize-flow-modal',
  templateUrl: './rasterize-flow-modal.component.pug',
  styleUrls: ['./rasterize-flow-modal.component.scss'],
})
export class RasterizeFlowModal {

  @Output() close = new EventEmitter<boolean>();
  @Input() data: TextLayer | undefined = undefined;

  onClose(confirmedRasterization: boolean) {
    this.close.emit(!!(confirmedRasterization && !!this.data));
  }

}
