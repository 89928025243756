import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MAX_ENTITY_NAME_LENGTH } from 'magma/common/constants';

export interface FolderNamingModalInput {
  name?: string;
  forRename?: boolean;
  header: string;
  label: string;
}

@Component({
  selector: 'folder-naming-modal',
  templateUrl: 'folder-naming-modal.component.pug',
})
export class FolderNamingModal {
  readonly maxLength = MAX_ENTITY_NAME_LENGTH;
  @Output() close = new EventEmitter<string | undefined>();
  @Input() data: FolderNamingModalInput = { name: '', label: '', header: '' };
  submit() {
    this.close.emit(this.data.name);
  }
  onClose() {
    this.close.emit(undefined);
  }
}
