import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DAY, HOUR, MONTH, WEEK, YEAR } from 'magma/common/constants';
import { leftArrowLightIcon } from 'magma/common/icons';
import { faCheck, faGlobe } from 'magma/generated/fa-icons';
import { Model } from 'magma/services/model';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, switchMap, tap, map } from 'rxjs/operators';
import { AppService } from 'services/app.service';
import { ContainerScrollEventService } from 'services/container-scroll-event.service';
import { SearchBarService } from 'services/search-bar.service';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { QUERY_FOR_TYPE } from 'shared/constants';
import { EntityData, ProjectData, UserData } from 'shared/interfaces';

interface CombinedSearchResult {
  documents?: { records: EntityData[]; total: number; isLoading?: boolean; };
  users?: { records: UserData[]; total: number; isLoading?: boolean; };
  projects?: { records: ProjectData[]; total: number; isLoading?: boolean; };
}

@UntilDestroy()
@Component({
  templateUrl: './search-results.component.pug',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnDestroy {
  readonly checkIcon = faCheck;
  readonly everywhereIcon = faGlobe;
  readonly leftIcon = leftArrowLightIcon;
  queryForType = QUERY_FOR_TYPE;
  queryFor = QUERY_FOR_TYPE.DOCUMENTS;
  commentsCollection = ['', 'with', 'with-unresolved'];
  sortColumn = 'name';
  showOnlyDocuments = true; // TODO: PRODUCT_INFO.showOnlyDocuments;
  filterResults = [
    { queryFor: QUERY_FOR_TYPE.DOCUMENTS },
    ... !this.showOnlyDocuments ? [{ queryFor: QUERY_FOR_TYPE.PROJECTS }] : [],
    ... !this.showOnlyDocuments ? [{ queryFor: QUERY_FOR_TYPE.USERS }] : [],
  ];
  lastActive = [0, DAY, 3 * DAY, WEEK, MONTH, YEAR];
  fetchingData = false;
  canFetchMore = false;
  private pageSize$ = new BehaviorSubject(15);
  reload$ = new BehaviorSubject(null);
  results$ = combineLatest([
    this.activatedRoute.queryParams,
    this.pageSize$,
    this.reload$,
  ]).pipe(
    tap(() => this.fetchingData = true),
    switchMap(([{ q, place, lastActive, comments }, pageSize]) => {
      this.search.q = q;
      this.search.place = place;
      this.search.lastActive = lastActive;
      this.search.comments = comments;

      return this.appService.search({
        q, place, pageSize,
        lastActive: +lastActive,
        comments,
        sortColumn: this.sortColumn,
        queryFor: QUERY_FOR_TYPE.DOCUMENTS,
      });
    }),
    map(documents => {
      this.fetchingData = false;
      this.canFetchMore = !!documents.records && documents.records.length < documents.total;
      return { documents } as CombinedSearchResult;
    }),
  );

  constructor(
    private teamsQuery: TeamsQuery,
    private model: Model,
    private activatedRoute: ActivatedRoute,
    private containerScrollEventService: ContainerScrollEventService,
    private appService: AppService,
    private teamService: TeamService,
    public search: SearchBarService,
  ) {
    this.containerScrollEventService.approachingBottom$.pipe(
      debounceTime(700),
      untilDestroyed(this),
    ).subscribe(() => {
      if (this.canFetchMore) this.fetchMore();
    });
  }

  ngOnDestroy() {
    this.search.reset();
  }

  get teams() {
    return this.teamsQuery.getAll();
  }

  get user() {
    return this.model.user;
  }

  private fetchMore() {
    const pageSize = Math.floor(window.innerHeight / 64);
    this.pageSize$.next(this.pageSize$.value + pageSize);
  }

  getPlaceName(place: string) {
    if (!place) {
      return 'Everywhere';
    } else if (place == 'artdesk') {
      return 'My Artdesk';
    } else {
      return this.teamService.getTeamBySlug(place)?.name;
    }
  }

  getTeamAvatar(slug: string) {
    return this.teamService.getTeamBySlug(slug)?.avatar;
  }

  getLastActiveLabel(lastActive: number) {
    if (!lastActive) {
      return 'Anytime';
    } else if (lastActive <= DAY) {
      return `Last ${Math.round(lastActive / HOUR)} hours`;
    } else {
      return `Last ${Math.round(lastActive / DAY)} days`;
    }
  }

  getCommentsLabel(comments: string) {
    if (comments === 'with') {
      return 'With comments';
    } else if (comments === 'with-unresolved') {
      return 'With unresolved comments';
    } else {
      return 'With or without comments';
    }
  }
}
