import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UserService } from 'services/user.service';
import { routeToTeam } from 'shared/utils';

@Injectable()
export class DefaultPathGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  async canActivate() {
    const user = await this.userService.user$.pipe(take(1)).toPromise();

    if (user?.lastTeam) {
      void this.router.navigate(routeToTeam(user.lastTeam));
    } else {
      void this.router.navigate(['/my']);
    }

    return false;
  }
}
