import { Route } from '@angular/router';
import { AppGuard } from 'guards/app.guard';

export default [
  {
    path: 'd/:id',
    loadChildren: () => import(/* webpackPrefetch: true */ 'modules/drawings/app/drawings.module').then(m => m.DrawingsModule),
    canActivate: [AppGuard],
    data: { noReload: true },
  },
] as Route[];
