import { Component } from '@angular/core';
import { faLock } from 'magma/generated/fa-icons';
import { ServerConstant } from './utils';
import { ServerConstantVars } from 'shared/interfaces';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  faLock = faLock;

  @ServerConstant('vars') vars!: ServerConstantVars;
}
