import { AuthService } from 'services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { PaymentService, redirectAfterCheckout } from 'services/payment.service';
import { of } from 'rxjs';

@Injectable()
export class PaymentWaitGuard implements CanActivate {
  constructor(private router: Router, private paymentService: PaymentService, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const sessionId: string | undefined = route.queryParams.sessionId;

    return this.paymentService.checkPaymentAwaiting(sessionId).pipe(switchMap(exists => {
      if (!exists) return of(true);
      return this.authService.refresh().pipe(map(() => this.router.parseUrl(redirectAfterCheckout() ?? '/my')));
    }));
  }
}
