import { removeItemFast } from 'magma/common/baseUtils';
import { UserService } from 'services/user.service';
import { COUNTRIES_LIST } from 'shared/constants';
import { BillingData, CustomerBillingInfo } from 'shared/interfaces';
import { ToastService } from 'magma/services/toast.service';
import { ErrorReporter } from 'magma/services/errorReporter';
import { isSubscriptionCancelled, isSubscriptionExpired, isSubscriptionPaymentFailed } from 'shared/utils';

export class BaseBillingComponent {
  billingData: BillingData | undefined = undefined;
  billingLoaded = false;
  isSubmitting = false;
  isChangingPlan = false;
  isCancellingPlan = false;
  isRestartingPlan = false;
  isRetrying = false;
  countries = COUNTRIES_LIST;
  updatingBillingInfo = false;
  billingInfo: CustomerBillingInfo = {
    name: '',
    email: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  };

  otherReason = '';
  cancelChecked: string[] = [];
  showCancelledSubscription = false;
  endOfCancelledSubscription = new Date();

  get user() {
    return this.userService.user;
  }

  get isExpired() {
    return isSubscriptionExpired(this.billingData);
  }

  get isCancelled() {
    return isSubscriptionCancelled(this.billingData);
  }

  get isFailed() {
    return isSubscriptionPaymentFailed(this.billingData);
  }

  private get price() {
    return this.billingData?.nextCharge ? (this.billingData.nextCharge / 100) : this.billingData?.pricePaid ?? 0;
  }

  get planDollars() {
    return Math.floor(this.price);
  }

  get planCents() {
    const text = this.price.toFixed(2);
    return text.substring(text.length - 2);
  }

  protected get validUntil() {
    return this.billingData?.validUntil ? new Date(this.billingData?.validUntil) : new Date();
  }

  get nextChargeDate() {
    return this.billingData?.nextChargeDate ?? this.validUntil;
  }

  get card() {
    const card = this.billingData?.paymentMethod?.card;

    // card can be empty object if it's not initialized
    if (card?.brand && card.expiryMonth && card.expiryYear && card.last4) {
      return card;
    } else {
      return undefined;
    }
  }

  constructor(protected userService: UserService, protected toastService: ToastService, protected errorReporter: ErrorReporter) { }

  protected hideForms() {
    this.cancelUpdateBillingInfo();
  }

  updateBillingInfo() {
    this.hideForms();
    this.updatingBillingInfo = true;

    if (this.billingData?.billingInfo) {
      Object.assign(this.billingInfo, this.billingData.billingInfo);
    } else {
      this.billingInfo.email = this.user!.email || ''; // HACK: billingInfo.email should be optional
      this.billingInfo.name = this.user!.name;
    }
  }

  cancelUpdateBillingInfo() {
    this.updatingBillingInfo = false;
  }

  isCancelChecked(value: string) {
    return this.cancelChecked.indexOf(value) !== -1;
  }

  cancelCheck(value: string, checked: boolean) {
    if (this.isCancelChecked(value) === checked) return;

    if (checked) {
      this.cancelChecked.push(value);
    } else {
      removeItemFast(this.cancelChecked, value);
    }
  }

  protected async submitForm(action: () => Promise<void>, successMessage: string) {
    try {
      this.isSubmitting = true;
      await action();
      this.toastService.success({ message: successMessage });
    } catch (e) {
      this.showAndReportError(e);
    } finally {
      this.isSubmitting = false;
    }
  }

  protected showAndReportError(error: Error) {
    this.errorReporter.reportError(error.message, error);
    this.toastService.error({ message: error.message });
  }
}
