import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardData } from 'shared/interfaces';

@Component({
  selector: 'billing-card',
  templateUrl: 'billing-card.component.pug',
  styleUrls: [],
})
export class BillingCardComponent {
  @Output() updateCard = new EventEmitter<void>();
  @Input() card?: CardData;
}
