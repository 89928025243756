<div class="dropdown-container" [ngStyle]="{ 'top': (top || 0) + 'px', left: (left || 0) + 'px' }">
  <ul class="status-items-container">
    <li
      *ngFor="let documentStatus of documentStatuses"
      class="dropdown-item"
      [ngClass]="{ 'selected': documentStatus === selectedStatus }"
      (click)="statusChanged(documentStatus)"
    >
      <div class="status" [ngClass]="documentStatus">{{ documentStatus | titlecase }}</div>
      <span><svg-icon *ngIf="documentStatus === selectedStatus" [icon]="farCheck"></svg-icon></span>
    </li>
  </ul>
  <div class="dropdown-last-change" *ngIf="lastChange">
    <span class="title">Last status change:</span>
    <div class="user">
      <avatar class="right-10" [size]="20" [user]="lastChange.by"></avatar>
      <span>{{ lastChange.by.name }} <span [timeAgo]="lastChange.at"></span></span>
    </div>
  </div>
</div>
