export function initCanny() {
  ((w: Window, d: Document, i: string, s: string) => {
    function l() {
      if (!d.getElementById(i)) {
        const f = d.getElementsByTagName(s)[0],
          e = d.createElement(s) as any;
        (e.type = 'text/javascript'),
          (e.async = !0),
          (e.src = 'https://canny.io/sdk.js'),
          f.parentNode?.insertBefore(e, f);
      }
    }
    if ('function' !== typeof (window as any).Canny) {
      const c = (...args: any[]) => {
        (c as any).q.push(args);
      };
      ((c as any).q = []),
        ((window as any).Canny = c),
        'complete' === d.readyState
          ? l()
          : (w as any).attachEvent
            ? (w as any).attachEvent('onload', l)
            : w.addEventListener('load', l, !1);
    }
  })(window, document, 'canny-jssdk', 'script');
}

export function renderCanny(token: string, path: string, userToken: string) {
  window.Canny?.('render', {
    boardToken: token,
    basePath: path,
    ssoToken: userToken,
  });
}

export function destroyCanny() {
  const node = document.getElementById('canny-body');
  node?.parentNode?.removeChild(node);
}
