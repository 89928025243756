import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserService } from 'services/user.service';

@Injectable()
export class AccountPageGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.userService.user$.pipe(map(user => {
      if (user && user.userType !== 'anonymous') return true;
      void this.router.navigate(['my']);
      return false;
    }));
  }
}
