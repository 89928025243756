import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'resources-links-modal',
  templateUrl: 'resources-links-modal.component.pug',
})
export class ResourcesLinksModal {
  @Output() close = new EventEmitter<void>();

  closeModal() {
    this.close.emit();
  }
}
