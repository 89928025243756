import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Analytics } from 'magma/common/interfaces';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { storageSetBoolean } from 'magma/services/storage';
import { ITrackService } from 'magma/services/track.service.interface';
import { ModalService } from 'services/modal.service';
import { UserService } from 'services/user.service';
import { blazeIcon } from 'magma/common/icons';

@Component({
  selector: 'stephen-silver-shapes-modal',
  templateUrl: './stephen-silver-shapes-modal.component.pug',
  styleUrls: ['./stephen-silver-shapes-modal.component.scss'],
})
export class StephenSilverShapesModal implements OnInit {
  readonly blazeIcon = blazeIcon;
  @Output() close = new EventEmitter<void>();

  constructor(
    private userService: UserService,
    private track: ITrackService,
    private modalService: ModalService,
    private loginSignup: LoginSignupService,
  ) {
  }

  ngOnInit() {
    this.track.event(Analytics.SilverShapesModal);
  }

  get pro() {
    return !!this.userService.user?.pro;
  }

  closeModal() {
    this.loginSignup.dismissNewFeature('silver-academy');
    storageSetBoolean('silver-drawing-modal-closed', true);
    this.close.emit();
  }

  tryShapes() {
    this.closeModal();
    const shapes = document.querySelector<HTMLElement>('#shapes-silvertoons-header .mr-2');
    shapes?.click();
  }

  upgrade() {
    this.closeModal();
    this.modalService.upgradeModal(`pro-creator-shapes-silvertoons`);
  }
}
