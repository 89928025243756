import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentStatuses } from 'shared/constants';
import { EntityStatus, UserData } from 'shared/interfaces';
import { startCase, toLower } from 'lodash';
import { NotifyMembersModal } from 'components/notify-member-modal.component';
import { faCircle } from 'magma/common/icons';
import { DocumentStatusStateCommunicatorService } from './document-status-state-notification.service';

export interface NotifyData {
  canInviteOthers: boolean;
  adminEmail?: string;
  teamMembers: UserData[];
}

@UntilDestroy()
@Component({
  selector: 'document-status',
  templateUrl: './document-status.component.html',
  styleUrls: ['./document-status.component.scss'],
})
export class DocumentStatusComponent implements AfterViewInit, OnDestroy {
  faCircle = faCircle;

  constructor(private documentStatusStateCommunicatorService: DocumentStatusStateCommunicatorService) {
    this.documentStatusStateCommunicatorService.onDropdownOpened()
      .pipe(untilDestroyed(this))
      .subscribe((opened) => {
        if (opened) {
          this.isDropdownVisible = false;
        }
      });
  }

  private defaultLabel = 'draft';

  @Input()
  set label(label: string | null) {
    if (label?.includes('$STATUS')) {
      this._label = label.replace('$STATUS', this.status?.state || this.defaultLabel);
      return;
    }
  }
  get label() {
    if (this.dotOnly) {
      return '';
    }

    return startCase(toLower(this._label || this.defaultLabel));
  }
  private _label = this.defaultLabel;

  @Input() dotOnly = false;
  @Input() readOnly = false;

  @Input()
  set status(statusObject: EntityStatus | null) {
    if (statusObject) {
      this._status = statusObject;
      this._label = statusObject.state;
    }
  }
  get status() {
    return this._status;
  }
  private _status: EntityStatus | null = null;

  @Output() statusChange = new EventEmitter<DocumentStatuses>();

  showNotifyMemberModal = false;
  showInviteTeamMemberModal = false;
  isDropdownVisible = false;

  @ViewChild('notifyModal')
  notifyModal: NotifyMembersModal | null = null;

  ngOnDestroy() {
    this.isDropdownVisible = false;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {}

  onStatusChange(newStatus: DocumentStatuses) {
    if (this.status?.state !== newStatus) {
      this.status = {
        ... this.status,
        state: newStatus,
      } as EntityStatus;

      this.statusChange.emit(newStatus);
    }
  }

  onHover(event: any) {
    if (!this.readOnly) {
      if (this.isDropdownVisible) {
        return;
      }

      this.documentStatusStateCommunicatorService.hasOpened();
      setTimeout(() => { this.isDropdownVisible = true; }, 0);
    }
  }
}
