import { Component } from '@angular/core';
import { TeamMembersQuery } from 'services/team-members.query';
import { RoleType } from 'shared/interfaces';
@Component({
  selector: 'no-access',
  templateUrl: './no-access.component.pug',
  styleUrls: ['./no-access.component.scss'],
  host: {
    class: 'use-magma-styles',
  },
})
export class NoAccessComponent {

  constructor(private teamMemberQuery: TeamMembersQuery) { }

  get administrator() {
    return this.teamMemberQuery.getAll().find(m => m.roles.find(r => r.type === RoleType.Owner))?.user;
  }
}
