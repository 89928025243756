<div class="box-container"><div class="header-box box"><div class="mb-3"><h2>What’s New?</h2><div class="text-muted">Jul 1, 2023</div></div><h1>Blaze updates &amp; other news</h1><div>Read full announcement in our <a href="https://magm.ai/whats-new-06-2023">What's New blog post</a></div></div><div class="header-box box"><iframe width="460" height="287" src="https://www.youtube.com/embed/_rojmMjhil0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="border-radius: 5px"></iframe></div><div class="box"><div class="article"><div class="article-title"><h3><svg-icon class="text-danger mr-2" [icon]="blazeIcon" size="lg" [autoWidth]="true"></svg-icon>New Blaze Brushes</h3><button class="mt-2 d-block" *ngIf="showBanner" (click)="newBrushes()"><img src="/assets/banner.8bedd889.png"></button></div><img src="/assets/new-blaze-brushes.01120be1.jpg"><div class="article-text"><p>We’ve added 28 new stunning brushes to the Blaze Brush collection.
Use the new brush collection to expand your artistic toolkit and unlock endless creative possibilities!
</p></div></div><div class="article"><h3 class="article-title">Deleting Artspaces</h3><img src="/assets/deleting-artspaces.fa3b1c01.jpg"><div class="article-text"><p>We’ve recently introduced a new feature which allows Artspace owners and administrators to remove their
Artspaces once they are no longer needed.
</p></div></div><div class="article"><h3 class="article-title">Artspace Description Box</h3><img src="/assets/artspace-description.b06f0014.jpg"><div class="article-text"><p>Artspace owners &amp; admins can now add custom information to project channels using our new
Description Box feature, which helps to sharing important information and communicate with Artspace members.
</p></div></div><div class="article"><h3 class="article-title">Weekly Clubhouse Stream</h3><img src="/assets/weekly-clubhouse-stream.697f139d.jpg"><div class="article-text"><p>Want to draw online with new friends? Then join our weekly community hangout: the Magma Clubhouse.
Each week we pick a new theme the canvas, invite our users to help us fill it, and live stream the whole thing.</p><a href="https://magm.ai/clubhouse">Read more</a></div></div></div><div class="box"><div class="article"><h3 class="article-title">Pyro’s Lab: Beta Artspace</h3><img src="/assets/pyros-lab.363e45f1.jpg"><div class="article-text"><p>We’ve launched Pyro’s Lab: join our Beta Artspace and help us test new exciting features like AI,
Text Tool and more.</p><a href="https://magm.ai/magma-beta-artspace-invite">Join Pyro’s Lab now</a></div></div><div class="article"><h3 class="article-title">No more Canvas Archiving</h3><img src="/assets/no-archiving.ea651597.png"><div class="article-text"><p>Now you have free, unlimited access to all of your drawings, including ones accessed over 30 days ago.
Moving forward, the only limitation is your Cloud Storage space.</p><a href="https://magm.ai/cloud-storage">Read more</a></div></div><div class="article"><h3 class="article-title">Filters: Blur &amp; Color Adjustments</h3><img src="/assets/filters.95440c48.jpg"><div class="article-text"><p>We’ve introduced a “Filter” menu which gives you new ways to edit and enhance your art.
Latest additions are Gaussian Blur and Color Adjustments, and there are more filters underway.
</p></div></div><div class="article"><h3 class="article-title">Magma Classroom</h3><img src="/assets/magma-classroom.436ad565.jpg"><div class="article-text"><p>Get your start in digital painting with the Magma Classroom live stream series.
Join lessons hosted by Magma artist, Ryan “Eyekoo” Allan directly on Magma canvas.</p><a href="https://magm.ai/classroom">Read more</a></div></div></div></div>