import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MAX_PROJECT_NAME_LENGTH } from 'magma/common/constants';
import { Permission, ProjectType } from 'magma/common/interfaces';
import { faCheck } from 'magma/generated/fa-icons';
import { ProjectService } from 'services/projects.service';
import { TeamMembersService } from 'services/team-members.service';
import { TeamService } from 'services/team.service';
import { TEAM_PROJECT_PRIVILIEGES, TEAM_PROJECT_PRIVILIEGES_NUMBERS } from 'shared/constants';
import { ProjectData, ProjectShareTypes } from 'shared/interfaces';
import { faBookAlt, faHashtag } from 'magma/common/icons';

@Component({
  selector: 'create-project-modal',
  templateUrl: './create-project-modal.component.pug',
  styleUrls: ['./create-project-modal.component.scss'],
})
export class CreateProjectModalComponent implements OnInit {
  readonly maxLength = MAX_PROJECT_NAME_LENGTH;
  readonly privilieges = TEAM_PROJECT_PRIVILIEGES;
  readonly priviliegeNumbers = TEAM_PROJECT_PRIVILIEGES_NUMBERS;
  readonly checkIcon = faCheck;
  readonly projectTypes =[
    {
      type: ProjectType.Project as any,
      title: 'Project',
      subtitle: 'Create and store folders and drawings',
      icon: faHashtag
    },
    {
      type: ProjectType.ContentPage as any,
      title: 'Content page',
      subtitle: 'Embed articles, videos and links',
      icon: faBookAlt
    },
  ];

  @Input() data!: ProjectData;
  @Output() close = new EventEmitter<ProjectData | undefined>();
  error = '';
  title = '';
  constructor(
    private projectService: ProjectService,
    private teamService: TeamService,
    private teamMemberService: TeamMembersService,
  ) { }
  ngOnInit() {
    this.title = this.data._id ? this.data.name : 'New project';
  }
  get team() {
    return this.teamService.getTeam(this.data.team);
  }
  async submit() {
    try {
      const { name, team, shareType, type } = this.data;

      if (!this.isNewProject) {
        await this.projectService.updateProject(this.data._id, { name, shareType }).toPromise();
        this.close.emit();
      } else {
        const newProject = await this.projectService.createNewProject({ name, team, shareType, type });
        this.close.emit(newProject);
      }
    } catch (e) {
      this.error = e.message;
    }
  }
  onClose() {
    this.close.emit();
  }
  getLabel(shareType: ProjectShareTypes) {
    return (this.privilieges.find(p => p.value === shareType) ?? this.privilieges[0]).label;
  }
  get isNewProject() {
    return !this.data._id;
  }
  get hasAccess() {
    return this.isNewProject ? this.canCreateProject : this.canUpdateProject;
  }
  get canCreateProject() {
    return this.teamMemberService.isPermissionFlagSet([Permission.CanCreateProjects]);
  }
  get canUpdateProject() {
    return this.teamMemberService.isPermissionFlagSet([Permission.CanUpdateProjects]);
  }

  get iconForCurrentProjectType() {
    return this.projectTypes.find(projectType => {
      return projectType.type === this.data.type;
    })?.icon;
  }

  setProjectType(type: string) {
    this.data.type = type as ProjectType;
  }
}
