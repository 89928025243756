import 'polyfills.ts';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';

import { hmrBootstrap } from './hmr';

import '../stylesheets/style-ng.scss';

import 'components/cookie-consent';

import localForage from 'localforage';
import { APP_PARAMS } from 'shared/constants';
import { enableAkitaProdMode } from '@datorama/akita';

if (process.env.IS_PRODUCTION) {
  enableProdMode();
  enableAkitaProdMode();
}

export async function bootstrap() {
  await prepareAppParams();
  return platformBrowserDynamic().bootstrapModule(AppModule);
}

if (process.env.HMR) {
  if ((module as any).hot) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap().catch(err => console.log(err));
}

async function prepareAppParams() {
  if (!(window && window.localStorage)) {
    return;
  }

  const urlParams = window.location.search;
  let params: {[key: string]: string} = {};

  try {
    const localForageParam = await localForage.getItem<typeof params>(APP_PARAMS);
    params = localForageParam || {};
  } catch (e) {
    console.warn('could not parse existing params', e);
  }

  if (urlParams) {
    urlParams.replace('?', '').split('&').map(val => {
      const splitValue = val.split('=');
      params[splitValue[0]] = splitValue[1];
    });

    try {
      await localForage.setItem<typeof params>(APP_PARAMS, params);
    } catch (e) {
      console.warn('error setting app params', e);
    }
  }
}
