import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'services/auth.service';
import { resetStores } from '@datorama/akita';

@Injectable()
export class ManageEntitiesGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.hasAuthToken) {
      resetStores();
      void this.router.navigate(['login'], { queryParams: { url: state.url } });
      return false;
    }
    return true;
  }
}
