import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DocumentStatusStateCommunicatorService {
  constructor() {}

  private dropdownOpenedSubject = new Subject<boolean>();
  private dropdownOpened$ = this.dropdownOpenedSubject.asObservable();

  /**
   * notify other document status dropdowns
   * that a new dropdown has opened
   */
  hasOpened() {
    this.dropdownOpenedSubject.next(true);
  }

  /**
   * @returns Observable<boolean>
   *
   * Returns an observable to allow clients
   * to react to the event when a dropdown is opened
   * on the page.
   */
  onDropdownOpened() {
    return this.dropdownOpened$;
  }
}
