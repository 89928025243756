import { HttpErrorResponse, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { logAction } from 'magma/common/actionLog';
import { getCookie } from 'magma/common/cookies';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TOKEN_NAME } from 'shared/constants';
import { isTokenExpired } from 'shared/jwt';

let requestNumber = 1;

@Injectable()
export class LogInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = getCookie(TOKEN_NAME);
    const auth = token ? (isTokenExpired(token) ? 'expired' : 'auth') : 'noauth';

    const message = `${req.method} ${req.url} #${requestNumber++} ${auth}`;
    logAction(message);

    return next.handle(req).pipe(
      tap(event => {
        if (event.type === HttpEventType.Response) {
          const response = event as HttpResponse<any>;
          logAction(`${message} -> ${response.status} (${typeof response.body})`);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        logAction(`${message} -> ${error.status}`);
        return throwError(error);
      }),
    );
  }
}
