import { CreateBillingItem } from './../../../shared/interfaces';
import { UserService } from 'services/user.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { deleteCookie, getCookie } from 'magma/common/cookies';
import { SubscriptionPeriod } from 'magma/common/interfaces';
import { CouponService } from 'magma/services/couponService';
import { SubscriptionService } from 'magma/services/subscription';
import { BillingInterval } from 'shared/interfaces';
import { BillingService } from './billing.service';
import { PaymentService } from './payment.service';
import { ModalService } from './modal.service';
import { take } from 'rxjs/operators';
import { CC_PRODUCT_MAGMA_PRO_PRICE_M, CC_PRODUCT_MAGMA_PRO_PRICE_Y } from 'shared/billing';

@Injectable({ providedIn: 'any' })
export class PortalSubscriptionService extends SubscriptionService {
  constructor(
    private billing: BillingService,
    private payment: PaymentService,
    private router: Router,
    private couponService: CouponService,
    private userService: UserService,
    private modals: ModalService,
  ) {
    super();
  }

  async handleUpgrade() {
    const period = getCookie('magma-plan') as SubscriptionPeriod;

    if (period) {
      deleteCookie('magma-plan', '/');

      const promo = await this.couponService.getPromo();
      const user = await this.userService.user$.pipe(take(1)).toPromise();

      if (user?.userType === 'anonymous') {
        this.preselectedPeriod = period;
        this.modals.upgradeModal('magma-plan-cookie');
      } else if (!user?.pro) {
        await this.upgradeToPro(period, promo?.promoCode);
      }
    }
  }

  async manageSubscription() {
    void this.router.navigate(['/my/account/billing']);
  }

  async upgradeToPro(period: SubscriptionPeriod, promoCode: string | undefined) {
    const plan = period === 'monthly' ? CC_PRODUCT_MAGMA_PRO_PRICE_M : CC_PRODUCT_MAGMA_PRO_PRICE_Y;
    // checkout is `false` if the user already has pro or subscription got restarted without need for checkout
    const items: CreateBillingItem[] = [{ plan, quantity: 1 }];
    const checkout = await this.billing.createUserSubscription(items, promoCode);
    if (checkout) this.payment.redirectToStripeCheckout(checkout);
  }
}
