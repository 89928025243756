import { NgModule } from '@angular/core';
import { ContactSalesModalComponent } from './contact-sales-modal.component';
import { ContactSalesButtonComponent } from './contact-sales-button.component';
import { ClarityModule } from '@clr/angular';
import { RequestDemoFormComponent } from './request-demo-form.component';
import { SalesRequestFormComponent } from './sales-request-form.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    ContactSalesModalComponent,
    ContactSalesButtonComponent,
    RequestDemoFormComponent,
    SalesRequestFormComponent,
  ],
  exports: [
    ContactSalesModalComponent,
    ContactSalesButtonComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ClarityModule,
  ],
  providers: [
  ],
})
export class SalesModalsModule {

}
