import { Component } from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Post, PostType } from '../../../../shared/posts';
import { faPlus } from 'magma/common/icons';
import { ModalService } from '../../services/modal.service';
import { invalidEnum } from 'magma/common/baseUtils';
import { PostModalMode } from '../modals/post-modal/post-modal.component';

@UntilDestroy()
@Component({
  selector: 'content-page',
  templateUrl: 'content-page.component.pug',
  styleUrls: ['content-page.component.scss'],
})
export class ContentPageComponent {
  readonly newPostIcon = faPlus;

  constructor(
    private blogService: BlogService,
    private activatedRoute: ActivatedRoute,
    private modals: ModalService,
  ) { }

  ngOnInit(){
    const projectId = this.activatedRoute.snapshot.paramMap.get('project');
    this.blogService.setupBlog(projectId);
    this.activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.blogService.setupBlog(params['project']!);
      });
  }

  ngOnDestroy() {
    this.blogService.setupBlog(null);
  }

  get loading(){
    return this.blogService.loadingPosts;
  }

  get project() {
    return this.blogService.project;
  }

  get posts(){
    return this.blogService.posts;
  }

  get canManage(){
    return this.blogService.canManage;
  }

  get disableNewPostButton(){
    return this.blogService.disableCreating;
  }

  moveProjectToLeft(projectId: string, post: Post){
    this.blogService.moveProjectToLeft(projectId, post);
  }

  moveProjectToRight(projectId: string, post: Post){
    this.blogService.moveProjectToRight(projectId, post);
  }

  create (projectId: string) {
    this.modals.openPostModal({ mode: PostModalMode.Creating, projectId })
      .catch((e) => { DEVELOPMENT && console.error(e); });
  }

  open(projectId: string, post: Post) {
    switch (post.type) {
      case PostType.Link:
        window.open(post.url, '_blank');
        break;
      case PostType.Article:
      case PostType.Video:
        this.modals.openPostModal({ mode: PostModalMode.Reading, post: post })
          .catch((e) => { DEVELOPMENT && console.error(e); });
        break;
      default: invalidEnum(post.type);
    }
  }

  edit(projectId: string, post: Post) {
    this.modals.openPostModal({ mode: PostModalMode.Editing, post, projectId })
    .catch((e) => { DEVELOPMENT && console.error(e); });
  }

  remove(projectId: string, post: Post) {
    this.modals.openPostModal({ mode: PostModalMode.Removing, post, projectId })
      .catch((e) => { DEVELOPMENT && console.error(e); });
  }
}
