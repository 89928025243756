import { Directive, ElementRef, Input } from '@angular/core';

const backgroundPropValue = (src: string) => `url(${src})`;

@Directive({
  selector: '[onLoadSrc]',
})
export class OnLoadSrcDirective {
  constructor(private el: ElementRef) { }

  internalImg = new Image();
  internalImageLoaded = false;

  @Input()
  set onLoadSrc(_src: string) {
    if (!_src) { return; }

    if (!this.internalImageLoaded) {
      this.image.src = _src;
      this.image.onload = () => this.internalImageLoaded = true;
      return;
    }

    if (_src !== this.image.src) {
      this.internalImg.src = _src;
      this.internalImg.onload = () => {
        this.image.src = _src;
      };
    }
  }

  private setBackgroundImage(src: string) {
    this.divElement.style.setProperty('background-image', backgroundPropValue(src));
  }

  @Input()
  set onLoadBackgroundImage(_src: string) {
    const currentValue = this.image.style.getPropertyValue('background-image');
    if (!currentValue) {
      this.setBackgroundImage(_src);
      return;
    }

    if (currentValue !== backgroundPropValue(_src)) {
      this.internalImg.src = _src;
      this.internalImg.onload = () => {
        this.setBackgroundImage(_src);
      };
    }
  }

  get divElement() {
    return (this.el.nativeElement as HTMLDivElement);
  }

  get image() {
    return (this.el.nativeElement as HTMLImageElement);
  }
}
