import { Observable, combineLatest, of, ReplaySubject } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { filter, switchMap } from 'rxjs/operators';
// import { ɵmarkDirty as markDirty } from '@angular/core';

// TODO: remove this
export class LoadingPage<T> {
  data$$ = new ReplaySubject<Observable<T>>(1);
  data$ = this.data$$.pipe(switchMap(x$ => x$));
  data!: T;

  get isLoading(): boolean {
    return !this.data;
  }

  load(observable: Observable<T>, isLoading$: Observable<boolean> = of(false)) {
    this.data$$.next(observable);

    combineLatest([this.data$, isLoading$])
      .pipe(untilDestroyed(this), filter(([data]) => !!data))
      .subscribe(([data, isLoading]) => {
        if (!isLoading) {
          if (data instanceof Array) {
            this.data = [...data] as any as T;
          } else {
            this.data = { ...data };
          }
        }
        // try {
        //   markDirty(this);
        // } catch { }
      });
  }
}
