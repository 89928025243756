import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isProCustomShape } from 'magma/common/constants';
import { Analytics, ProCreatorInfo, ToolId, ToolSource } from 'magma/common/interfaces';
import { faFacebook, faInstagram, faLinkedin, faPlay, faYoutube } from 'magma/generated/fa-icons';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { Model } from 'magma/services/model';
import { selectTool } from 'magma/services/otherActions';
import { storageGetItem } from 'magma/services/storage';
import { ModalService } from 'services/modal.service';

@Component({
  selector: 'creator-modal',
  templateUrl: 'creator-modal.component.pug',
  styleUrls: ['creator-modal.component.scss'],
})
export class CreatorModalComponent implements OnInit {
  readonly facebookIcon = faFacebook;
  readonly instagramIcon = faInstagram;
  readonly youtubeIcon = faYoutube;
  readonly linkedinIcon = faLinkedin;
  readonly playIcon = faPlay;
  sanitizedVideoLink: SafeResourceUrl | undefined = undefined;
  @Output() close = new EventEmitter<void>();
  @Input() data!: { creator: ProCreatorInfo; model: Model; };
  constructor(
    private sanitizer: DomSanitizer,
    private modals: ModalService,
    private loginSignup: LoginSignupService,
  ) {
  }
  get creator() {
    return this.data.creator;
  }
  get model() {
    return this.data.model;
  }
  get hasPro() {
    return !!this.loginSignup.user.pro;
  }
  ngOnInit() {
    this.loginSignup.trackEvent(Analytics.OpenProCreatorModal, { creator: this.creator.name });
    this.sanitizedVideoLink = this.creator.video ? this.sanitizer.bypassSecurityTrustResourceUrl(this.creator.video) : undefined;
    this.openShapes();
  }
  closeModal() {
    this.close.emit();
  }
  isShapePro(id: string) {
    return !this.hasPro && isProCustomShape(id);
  }
  upgrade() {
    this.modals.upgradeModal(`pro-creator-${this.creator.id}`);
  }
  openShapes() {
    // TODO: fix ???
    const hiddenPresetGroups = storageGetItem('hidden-preset-groups');
    const closed = hiddenPresetGroups?.includes('shape-shapes-silvertoons');
    if (closed) {
      const shapesHeader = document.getElementById('shapes-silvertoons-header') as HTMLElement | null;
      shapesHeader?.getElementsByTagName('button')[0]?.click();
    }
  }
  clickShape(selectedShape: { name: string, shape: string }) {
    if (!this.hasPro && isProCustomShape(selectedShape.shape)) return;
    this.closeModal();

    if (!this.model.editor) return;
    selectTool(this.model.editor, this.model.editor.tools.find(t => t.id === ToolId.Shape), ToolSource.Auto);

    setTimeout(() => {
      const editorShapes = document.getElementById('shapes-silvertoons-content') as HTMLElement | null;
      const previewShapes = editorShapes?.getElementsByTagName('shape-preview-img') as HTMLCollectionOf<HTMLElement>;
      const shape = [...previewShapes].find(shape => shape.getAttribute('title') == selectedShape.name);
      if (shape) {
        shape.click();
        shape.scrollIntoView();
      }
    }, 100);
  }
}
