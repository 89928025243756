import { Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';

@Directive({ selector: '[scrollTracker]' })
export class ScrollTrackerDirective {
  @Output() approachingBottom = new EventEmitter<void>();
  @Output() approachingTop = new EventEmitter<void>();
  @Input() bottomTriggerDistance = 500;
  @Input() topTriggerDistance = 500;
  constructor(element: ElementRef<HTMLElement>, zone: NgZone) {
    const elem = element.nativeElement;
    let lastScrollTop = elem.scrollTop;
    let notifiedBottom = false;
    let notifiedTop = false;

    zone.runOutsideAngular(() => elem.addEventListener('scroll', () => {
      const rect = elem.getBoundingClientRect();

      if (lastScrollTop < elem.scrollTop) { // scrolling down
        notifiedTop = false;
        const toBottom = elem.scrollHeight - (rect.height + elem.scrollTop);
        if (toBottom < this.bottomTriggerDistance) {
          notifiedBottom = true;
          zone.run(() => this.approachingBottom.emit());
        }
      } else if (lastScrollTop > elem.scrollTop) { // scrolling up
        notifiedBottom = false;
        if (elem.scrollTop < this.bottomTriggerDistance) {
          notifiedTop = true;
          zone.run(() => this.approachingTop.emit());
        }
      }

      lastScrollTop = elem.scrollTop;
    }));
  }
}
