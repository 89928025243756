import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from 'services/auth.service';

@Injectable()
export class AppGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot) {
    if (!this.auth.hasAuthToken) {
      // case when user logs out from the app and goes back
      // html5 history will then try to re-activate the app view without a token
      await this.auth.initAuth();
    }

    if (this.auth.loggedIn && route.url.length === 0) {
      void this.router.navigate(['my']);
    }

    return true;
  }
}
