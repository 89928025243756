import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Component } from '@angular/core';
import { TeamMembersService } from 'services/team-members.service';
import { TeamsQuery } from 'services/team.query';
import { ToastService } from 'magma/services/toast.service';
import { ModalService } from 'services/modal.service';
import { faTimes } from 'magma/common/icons';
import { TeamBannedMember, TeamData } from '../../../../../shared/interfaces';
import { Permission } from 'magma/common/interfaces';


@UntilDestroy()
@Component({
  selector: 'team-settings-bans',
  templateUrl: './team-settings-bans.component.pug',
  styleUrls: ['../../account-common.component.scss',
    './team-settings-bans.component.scss'],
  host: { class: 'use-magma-styles' },
})
export class TeamSettingsBansComponent {
  faTimes = faTimes;
  activeTeam$ = this.teamsQuery.selectActive();
  activeTeam: TeamData | undefined;
  bannedTeamMembers: TeamBannedMember[] = []; 

  constructor(
    private teamsQuery: TeamsQuery,
    private teamMembersService: TeamMembersService,
    private toastService: ToastService,
    private modalService: ModalService,
  ) {
    this.activeTeam$.pipe(untilDestroyed(this)).subscribe(async (team) => {
      this.activeTeam = team;
      if (team && this.teamMembersService.isPermissionFlagSet([Permission.CanManageTeamMembers])) {
        this.bannedTeamMembers = await this.teamMembersService.getTeamBannedMembers(team._id);
      }
    });
  }

  async unbanUser(member: TeamBannedMember) {
    try {
      if (await this.modalService.unbanUserFromTeam({ teamName: this.activeTeam!.name, userName: member.user.name })) {
        await this.teamMembersService.unbanUserTeam(this.activeTeam!._id, member.user._id);
        this.bannedTeamMembers = this.bannedTeamMembers.filter((user) => user != member);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to Unban member', subtitle: e.message });
    }
  }
}
