import { Component, Input } from '@angular/core';
import { faFileImport, faLink, farExclamationCircle, farExclamationTriangle, faTrash } from 'magma/common/icons';
import { TeamService } from 'services/team.service';
import { uploadAvatar } from 'magma/services/uploadService';
import { TeamData } from 'shared/interfaces';
import { TEAM_DESCRIPTION_MAX_LENGTH, TEAM_NAME_MAX_LENGTH, TEAM_SLUG_MAX_LENGTH } from 'shared/constants';
import { TeamMembersService } from 'services/team-members.service';
import { Permission } from 'magma/common/interfaces';
import { TeamMembersQuery } from 'services/team-members.query';
import { isRandomTeamAvatar, randomTeamAvatar } from 'magma/common/utils';

@Component({
  selector: 'team-details-form',
  templateUrl: 'team-details-form.component.pug',
  styleUrls: [
    '../../account-common.component.scss',
    'team-details-form.component.scss',
  ],
  host: { class: 'use-magma-styles' },
})
export class TeamDetailsFormComponent {
  readonly TEAM_NAME_MAX_LENGTH = TEAM_NAME_MAX_LENGTH;
  readonly TEAM_SLUG_MAX_LENGTH = TEAM_SLUG_MAX_LENGTH;
  readonly TEAM_DESCRIPTION_MAX_LENGTH = TEAM_DESCRIPTION_MAX_LENGTH;
  readonly removeIcon = faTrash;
  readonly openIcon = faFileImport;
  readonly errorIcon = farExclamationCircle;
  readonly warningIcon = farExclamationTriangle;
  readonly faLink = faLink;
  url = `${location.protocol}//${location.host}`;
  shortUrl = location.host;
  isUpdating = false;
  isEditing = false;
  name = '';
  avatar = '';
  slug = '';
  description = '';
  error: string | undefined = undefined;
  constructor(
    private teamsService: TeamService,
    private teamMemberService: TeamMembersService,
    private teamMembersQuery: TeamMembersQuery,
  ) {
  }

  private _team: TeamData | undefined = undefined;
  @Input() get team() {
    return this._team;
  }
  set team(value) {
    if (this._team !== value) {
      this._team = value;
      this.copyTeamFields();
    }
  }

  async selectAvatar(file: File) {
    try {
      this.error = '';
      this.avatar = await uploadAvatar(file);
    } catch (e) {
      this.error = e.message;
    }
  }

  nameChanged() {
    if (isRandomTeamAvatar(this.avatar) && parseInt(this.name.substring(6), 16) !== this.name.codePointAt(0)) {
      this.avatar = randomTeamAvatar(this.name);
    }
  }

  removeAvatar() {
    this.avatar = randomTeamAvatar(this.name);
  }

  edit() {
    if (this.team) {
      this.isEditing = true;
      this.copyTeamFields();
    }
  }

  cancel() {
    this.isEditing = false;
    this.error = '';
    this.copyTeamFields();
  }

  async updateTeam() {
    if (!this.team) return;

    try {
      this.isUpdating = true;
      await this.teamsService.updateTeam(this.team, { // TODO try to not pass whole team object
        name: this.name,
        slug: this.slug,
        avatar: this.avatar,
        description: this.description,
      });
      this.isEditing = false;
    } catch (e) {
      this.error = e.message;
    }

    this.isUpdating = false;
  }

  get showSlugWarning() {
    return this.team?.slug !== this.slug;
  }

  get canUpdateTeam() {
    return this.teamMemberService.isPermissionFlagSet([Permission.CanManageTeam]);
  }

  get teamMemberCount() {
    return this.teamMembersQuery.getCount();
  }

  private copyTeamFields() {
    if (this.team) {
      this.name = this.team.name;
      this.slug = this.team.slug;
      this.avatar = this.team.avatar;
      this.description = this.team.description ?? '';
    }
  }
}
