import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { blazeIcon } from 'magma/common/icons';
import { faCheck, faCopy, faRedo, faTrash } from 'magma/generated/fa-icons';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ModalService } from 'services/modal.service';
import { UserService } from 'services/user.service';
import { ApiKeyData } from 'shared/interfaces';

@UntilDestroy()
@Component({
  selector: 'account-api',
  templateUrl: './account-api.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './account-api.component.scss',
  ],
})
export class AccountApiComponent {
  readonly faTrash = faTrash;
  readonly faRedo = faRedo;
  readonly faCheck = faCheck;
  readonly faCopy = faCopy;
  readonly proIcon = blazeIcon;

  showCopied = false;
  apiKeys: ApiKeyData[] = [];
  generateApiTokenModalOpen = false;

  private reloadApiKeys$ = new BehaviorSubject(null);

  constructor(private userService: UserService, private modals: ModalService) {
    this.reloadApiKeys$.pipe(
      switchMap(() => this.userService.getApiKeys()),
      untilDestroyed(this),
    ).subscribe(value => this.apiKeys = value);
  }

  get apiEnabled() {
    return !!this.userService.user?.pro;
  }

  copyTrigger() {
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 4000);
  }

  toggleGenerateApiTokenModal() {
    this.generateApiTokenModalOpen = !this.generateApiTokenModalOpen;
  }

  async generateApiKey() {
    await this.userService.generateApiKey().toPromise();
    this.reloadApiKeys();
  }

  async regenerateApiKey(key: ApiKeyData) {
    await this.userService.regenerateApiKey(key._id).toPromise();
    this.reloadApiKeys();
  }

  async revokeApiKey(key: ApiKeyData) {
    await this.userService.revokeApiKey(key._id).toPromise();
    this.reloadApiKeys();
  }

  upgrade() {
    this.modals.upgradeModal('api');
  }

  reloadApiKeys() {
    this.reloadApiKeys$.next(null);
  }
}
