import { Component } from '@angular/core';
import { TeamsStore } from '../../services/team.store';
import { BlogService } from 'services/blog.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'community-hub',
  templateUrl: 'community-hub.component.pug',
  styleUrls: ['community-hub.component.scss'],
})
export class CommunityHubComponent {
  constructor(
    private teamsStore: TeamsStore,
    private blogService: BlogService,
    private userService: UserService,
    private router: Router,
  ) { }

  async ngOnInit(){
    this.teamsStore.setActive(null);

    if (!this.blogService.communityHubProjects.length) {
      await this.blogService.getCommunityHubProjects();
    }

    const navigateTo = this.userService.user?.lastCommunityHubProject ?? this.blogService.communityHubProjects[0]._id;

    if (navigateTo && this.blogService.communityHubProjects.some(p => p._id === navigateTo)) {
      this.router.navigate(['community-hub', navigateTo])
        .catch((e) => { DEVELOPMENT && console.error(e); });
    }
  }
}
