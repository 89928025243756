<ng-template #overflow>
  <div class="dropdown-menu context-menu mx-0">
    <div class="overflow-participants-list">
      <participant [participant]="p" *ngFor="let p of participants | slice:overflowThreshold:overflowLimit; trackBy:byId"></participant>
    </div>
    <div *ngIf="participants.length > overflowLimit" class="text-muted text-right mt-1">
      +{{participants.length - overflowLimit}} more
    </div>
  </div>
</ng-template>

<div class="participant-list-container">
  <participant *ngFor="let p of participants | slice:0:overflowThreshold; trackBy:byId" [participant]="p"></participant>
  <button *ngIf="participants.length > overflowThreshold" class="overflow-count btn btn-sm btn-light dropdown-toggle"
    [contextMenu]="overflow" contextMenuLocation="bottom right" contextMenuEvent="click" tabindex="-1">
    {{participants.length - overflowThreshold}}
  </button>
</div>
