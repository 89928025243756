import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Permission } from 'magma/common/interfaces';
import { faInfoCircle } from 'magma/generated/fa-icons';
import { ToastService } from 'magma/services/toast.service';
import { ProjectService } from 'services/projects.service';
import { TeamMembersService } from 'services/team-members.service';
import { ProjectData } from 'shared/interfaces';

@Component({
  selector: 'update-project-description-modal',
  templateUrl: './update-project-description-modal.component.pug',
  styleUrls: ['./update-project-description-modal.component.scss'],
})
export class UpdateProjectDescriptionModalComponent {
  readonly infoIcon = faInfoCircle;
  @Input() data!: ProjectData;
  @Output() close = new EventEmitter<ProjectData | undefined>();
  constructor(
    private projectService: ProjectService,
    private teamMemberService: TeamMembersService,
    private toastService: ToastService,
  ) { }
  async submit() {
    try {
      const { description, title } = this.data;
      await this.projectService.updateProject(this.data._id, { description, title }).toPromise();
      this.close.emit();
    } catch (e) {
      this.toastService.error({ message: e.message });
    }
  }
  onClose() {
    this.close.emit();
  }
  get hasAccess() {
    return this.canUpdateProject;
  }
  get canUpdateProject() {
    return this.teamMemberService.isPermissionFlagSet([Permission.CanUpdateProjects]);
  }
  get validInputs() {
    const { title, description } = this.data;
    return title && description && title.trim() && description.trim();
  }
}
