import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { Modals } from 'magma/services/modals';
import { removeItem } from 'magma/common/baseUtils';

@Component({
  selector: 'portal-modals-box',
  templateUrl: './portal-modals-box.component.pug',
})
export class PortalModalsBoxComponent implements AfterViewInit, OnDestroy {
  @ViewChild('createProject', { static: true }) createProject!: TemplateRef<any>;
  @ViewChild('deleteProject', { static: true }) deleteProject!: TemplateRef<any>;
  @ViewChild('cancelSubscription', { static: true }) cancelSubscription!: TemplateRef<any>;
  @ViewChild('changePlan', { static: true }) changePlan!: TemplateRef<any>;
  @ViewChild('renameEntity', { static: true }) renameEntity!: TemplateRef<any>;
  @ViewChild('deleteEntity', { static: true }) deleteEntity!: TemplateRef<any>;
  @ViewChild('shareEntityAsImage', { static: true }) shareEntityAsImage!: TemplateRef<any>;
  @ViewChild('shareEntity', { static: true }) shareEntity!: TemplateRef<any>;
  @ViewChild('exportEntity', { static: true }) exportEntity!: TemplateRef<any>;
  @ViewChild('resourcesLinks', { static: true }) resourcesLinks!: TemplateRef<any>;
  @ViewChild('removeTeamMember', { static: true }) removeTeamMember!: TemplateRef<any>;
  @ViewChild('banTeamMember', { static: true }) banTeamMember!: TemplateRef<any>;
  @ViewChild('unbanTeamMember', { static: true }) unbanTeamMember!: TemplateRef<any>;
  @ViewChild('transferTeamOwnership', { static: true }) transferTeamOwnership!: TemplateRef<any>;
  @ViewChild('leaveTeam', { static: true }) leaveTeam!: TemplateRef<any>;
  @ViewChild('inviteTeamMembers', { static: true }) inviteTeamMembers!: TemplateRef<any>;
  @ViewChild('joinTeam', { static: true }) joinTeam!: TemplateRef<any>;
  @ViewChild('selectFolder', { static: true }) selectFolder!: TemplateRef<any>;
  @ViewChild('permissionFlags', { static: true }) permissionFlags!: TemplateRef<any>;
  @ViewChild('announcement', { static: true }) announcement!: TemplateRef<any>;
  @ViewChild('userRoles', { static: true }) userRoles!: TemplateRef<any>;
  @ViewChild('upgrade', { static: true }) upgrade!: TemplateRef<any>;
  @ViewChild('creator', { static: true }) creator!: TemplateRef<any>;
  @ViewChild('stephenSilverShapes', { static: true }) stephenSilverShapes!: TemplateRef<any>;
  @ViewChild('newBrushes', { static: true }) newBrushes!: TemplateRef<any>;
  @ViewChild('switchingToPrivate', { static: true }) switchingToPrivate!: TemplateRef<any>;
  @ViewChild('switchingToPublic', { static: true }) switchingToPublic!: TemplateRef<any>;
  @ViewChild('startPresentation', { static: true }) startPresentation!: TemplateRef<any>;
  @ViewChild('rasterizeFlow', { static: true }) rasterizeFlow!: TemplateRef<any>;
  @ViewChild('createTeam', { static: true }) createTeam!: TemplateRef<any>;
  @ViewChild('createTeamSubscriptionModal', { static: true }) createTeamSubscriptionModal!: TemplateRef<any>;
  @ViewChild('upgradeTeamSubscriptionModal', { static: true }) upgradeTeamSubscriptionModal!: TemplateRef<any>;
  @ViewChild('invoicePreviewModal', { static: true }) invoicePreviewModal!: TemplateRef<any>;
  @ViewChild('teamBillingChangeInterval', { static: true }) teamBillingChangeInterval!: TemplateRef<any>;
  @ViewChild('teamBillingPendingChangesInterval', { static: true }) teamBillingPendingChangesInterval!: TemplateRef<any>;
  @ViewChild('moveToBinEntity', { static: true }) moveToBinEntity!: TemplateRef<any>;
  @ViewChild('openDeletedFolder', { static: true }) openDeletedFolder!: TemplateRef<any>;
  @ViewChild('updateProjectDescription', { static: true }) updateProjectDescription!: TemplateRef<any>;
  @ViewChild('openProjectDescription', { static: true }) openProjectDescription!: TemplateRef<any>;
  @ViewChild('deleteTeam', { static: true }) deleteTeam!: TemplateRef<any>;
  @ViewChild('teamDeletionReason', { static: true }) teamDeletionReason!: TemplateRef<any>;
  @ViewChild('queueAsyncJob', { static: true }) queueAsyncJob!: TemplateRef<any>;
  @ViewChild('editAiModel', { static: true }) editAiModel!: TemplateRef<any>;
  @ViewChild('presentationActionModal', { static: true }) presentationActionModal!: TemplateRef<any>;
  @ViewChild('managePresentationViewersModal', { static: true }) managePresentationViewersModal!: TemplateRef<any>;
  @ViewChild('storageLimitExceeded', { static: true }) storageLimitExceeded!: TemplateRef<any>;
  @ViewChild('postModal', { static: true }) postModal!: TemplateRef<any>;

  ngAfterViewInit() {
    Modals.templates.push(this);
  }

  ngOnDestroy() {
    removeItem(Modals.templates, this);
  }
}
