import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface TransferTeamOwnershipModalInput {
  teamName: string;
  teamId: string;
  userName: string;
  userId: string;
}

@Component({
  selector: 'transfer-team-ownership-modal',
  templateUrl: 'transfer-team-ownership-modal.component.pug',
})
export class TransferTeamOwnershipModalComponent {
  @Output() close = new EventEmitter<boolean | undefined>();
  @Input() data!: TransferTeamOwnershipModalInput;

  onDelete() {
    this.close.emit(true);
  }

  onClose() {
    this.close.emit(undefined);
  }
}
