import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  EDUCATION_LINK, MAX_SESSIONS_FREE, MAX_IMAGE_LAYERS_FREE, MAX_IMAGE_LAYERS_PRO, MAX_SESSIONS_PRO,
  MAX_USERS_PER_DRAWING_FREE, MAX_USERS_PER_DRAWING_PRO
} from 'magma/common/constants';
import { voiceChatUrl } from 'magma/common/data';
import { blazeIcon, faSchool, sparkIcon } from 'magma/common/icons';
import { Analytics, SubscriptionPeriod } from 'magma/common/interfaces';
import { sendGAEvent } from 'magma/common/utilsAnalytics';
import { CouponService } from 'magma/services/couponService';
import { ErrorReporter } from 'magma/services/errorReporter';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { SubscriptionService } from 'magma/services/subscription';
import { ToastService } from 'magma/services/toast.service';
import { UserService } from 'services/user.service';

@Component({
  selector: 'upgrade-modal',
  templateUrl: 'upgrade-modal.component.pug',
  styleUrls: ['upgrade-modal.component.scss'],
})
export class UpgradeModalComponent implements OnInit, OnDestroy {
  readonly schoolIcon = faSchool;
  readonly educationLink = EDUCATION_LINK;
  readonly proIcon = blazeIcon;
  readonly freeIcon = sparkIcon;
  @Output() close = new EventEmitter<void>();
  @Output() modalClass = new EventEmitter<string>();
  @Output() modalIgnoreKeys = new EventEmitter<boolean>();
  @Input() data = { openedBy: '' };
  period: SubscriptionPeriod = 'monthly';
  coupon: string | undefined = undefined;
  partner: string | undefined = undefined;
  monthly = 999;
  yearly = 9999;
  percentOff = 10;
  durationInMonths = 0;
  monthsForFree = 0;
  signingIn = false;
  plan = 1;
  MAX_SESSIONS_FREE = MAX_SESSIONS_FREE;
  MAX_SESSIONS_PRO = MAX_SESSIONS_PRO;
  MAX_IMAGE_LAYERS_FREE = MAX_IMAGE_LAYERS_FREE;
  MAX_IMAGE_LAYERS_PRO = MAX_IMAGE_LAYERS_PRO;
  MAX_USERS_PER_DRAWING_FREE = MAX_USERS_PER_DRAWING_FREE;
  MAX_USERS_PER_DRAWING_PRO = MAX_USERS_PER_DRAWING_PRO;
  isYearly = false;
  get noYearlyPlan() {
    // Stripe does not handle durations in months for yearly plans
    return this.durationInMonths && this.durationInMonths !== 12;
  }
  get hasYearlyPlan() {
    return !this.noYearlyPlan;
  }
  get hasVoiceChat() {
    return !!voiceChatUrl;
  }
  get monthlyOff() {
    return this.coupon ? Math.round(this.monthly * (100 - this.percentOff) / 100) : this.monthly;
  }
  get yearlyOff() {
    return this.coupon ? Math.round(this.yearly * (100 - this.percentOff) / 100) : this.yearly;
  }
  get canClose() {
    return !this.partner;
  }
  get hasTrial() {
    return !this.userService.user?.subscriptionStatus?.status ||
      this.userService.user.subscriptionStatus.status === 'incomplete';
  }
  private interval: any;
  constructor(
    private errorReporter: ErrorReporter,
    private subscriptionService: SubscriptionService,
    private couponService: CouponService,
    private loginSignupService: LoginSignupService,
    private userService: UserService,
    private toastService: ToastService,
  ) {
  }
  ngOnInit() {
    if (this.subscriptionService.preselectedPeriod && this.userService.user?.userType === 'anonymous') {
      this.period = this.subscriptionService.preselectedPeriod;
      this.signingIn = true;
      this.modalClass.emit('modal-lg');
      this.subscriptionService.preselectedPeriod = undefined;
    }

    const coupon = this.couponService.getCoupon();

    if (coupon) {
      this.couponService.getPromo()
        .then(info => {
          if (info) {
            this.percentOff = info.percentOff;
            this.durationInMonths = info.durationInMonths;
            if (info.percentOff === 100) {
              this.monthsForFree = info.durationInMonths;
            }
            this.partner = info.partner;
            this.coupon = coupon;
            this.modalIgnoreKeys.emit(!this.canClose);
          } else {
            this.coupon = undefined;
          }

          this.loginSignupService.trackEvent(Analytics.OpenUpgradeModal, {
            label: this.coupon ? 'pricing-with-promo' : 'pricing',
            openedBy: this.data.openedBy,
            campaign: this.partner,
          });
        })
        .catch(e => DEVELOPMENT && console.error(e));
    } else {
      this.loginSignupService.trackEvent(Analytics.OpenUpgradeModal, {
        label: 'pricing',
        openedBy: this.data.openedBy,
        campaign: this.partner,
      });
    }

    sendGAEvent('Stripe checkout', 'Started Pro checkout', this.data.openedBy);
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
  closeModal() {
    this.close.emit();
  }
  upgrade(period: SubscriptionPeriod) {
    if (this.userService.user?.userType === 'anonymous') {
      this.period = period;
      this.signingIn = true;
      this.modalClass.emit('modal-lg');
      clearInterval(this.interval);
    } else {
      void this.upgradeToPro(period);
    }
  }
  signUpFinished() {
    this.signingIn = false;
    this.modalClass.emit('modal-md');
    this.interval = setInterval(() => {
      if (this.userService.user?.userType === 'anonymous') return;

      clearInterval(this.interval);

      if (this.userService.user?.pro) {
        this.closeModal();
      } else {
        void this.upgradeToPro(this.period);
      }
    }, 100);
  }
  change() {
    this.signingIn = false;
    this.modalClass.emit('modal-md');
  }
  private async upgradeToPro(period: SubscriptionPeriod) {
    try {
      sendGAEvent('Stripe checkout', 'Started Pro trial');
      this.closeModal();
      await this.subscriptionService.upgradeToPro(period, this.coupon);
    } catch (e) {
      if (e.message === 'Cancelled') return;
      this.errorReporter.reportError('Upgrade to pro failed', e);
      this.toastService.error({ message: e.message });
    }
  }
}
