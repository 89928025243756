import { Component, Input } from '@angular/core';
import { getRoleIcon, getRoleTitle } from 'magma/common/userRole';
import { Participant } from 'shared/interfaces';

@Component({
  selector: 'participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss'],
  host: {
    '[class.offline]': '!participant.isOnline',
  },
})
export class ParticipantComponent {
  @Input() participant!: Participant;
  @Input() size = 22;

  get roleIcon() {
    return getRoleIcon(this.participant);
  }

  get roleTitle() {
    return getRoleTitle(this.participant);
  }
}
