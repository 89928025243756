import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { switchMap, tap, map, distinctUntilChanged, catchError } from 'rxjs/operators';
import { EntitiesService } from 'services/entities.service';
import { TeamsQuery } from 'services/team.query';

@UntilDestroy()
@Component({
  selector: 'bin-folder',
  templateUrl: 'bin-folder.component.pug',
  styleUrls: ['bin-folder.component.scss'],
})
export class BinFolderComponent {
  title = 'Bin Folder';
  constructor(
    private entitiesService: EntitiesService,
    private activatedRoute: ActivatedRoute,
    private teamsQuery: TeamsQuery,
  ) {
  }
  reload$ = new BehaviorSubject(null);
  canFetchMore = false;
  fetchingData = false;
  results$ = combineLatest([
    this.activatedRoute.queryParams.pipe(map(params => params.folder as string | undefined), distinctUntilChanged()),
    this.teamsQuery.selectActive().pipe(distinctUntilChanged()),
    this.reload$,
  ]).pipe(
    tap(() => this.fetchingData = true),
    switchMap(([folderId, activeTeam]) => this.entitiesService.getRemoved(activeTeam?._id, folderId)),
    catchError(() => of([])),
    tap(() => this.fetchingData = false),
  );
}
