import { Directive, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { getAllParentsOfElement, isElementInViewport } from 'util/util';

@UntilDestroy()
@Directive({
  selector: '[scrolledOutOfViewport]',
})
export class ScrolledOutOfViewportDirective implements AfterViewInit {

  @Output() scrolledOutOfViewport = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    setTimeout(() => {
      const parents = getAllParentsOfElement(this.elementRef.nativeElement);

      merge(...parents.map(element => fromEvent(element, 'scroll'))).pipe(
        untilDestroyed(this),
        debounceTime(50),
        map(() => !isElementInViewport(this.elementRef.nativeElement)),
      ).subscribe((isOutOfViewport) => {
        if (isOutOfViewport) {
          this.scrolledOutOfViewport.emit(true);
        }
      });
    }, 0);
  }
}
