import { AfterContentInit, Component, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, Observable, of } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { ContainerScrollEventService } from 'services/container-scroll-event.service';
import { ProjectService } from 'services/projects.service';
import { RecentEntitiesQuery } from 'services/recent-entities/recent-entities.query';
import { RecentParticipationService } from 'services/recent-participation.service';
import { RouteData } from 'services/router.service';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { RecentEntity } from 'shared/interfaces';
import { getLastProject, saveLastProject } from 'shared/utils';
import { recentEntityTileDimension } from 'util/recent-entities';

const PAGE_SIZE = 15;

@UntilDestroy()
@Component({
  selector: 'recent-entities-page',
  templateUrl: 'recent-entities-page.component.pug',
  styleUrls: ['recent-entities-page.component.scss'],
})
export class RecentEntitiesPageComponent implements AfterContentInit {
  title = 'Participated in';
  onlyLive = false;
  entities$: Observable<RecentEntity[] | null> = of(null);
  teamId = this.teamsQuery.getActive()?._id;

  get project() {
    return this.projectService.getProject(this.projectId);
  }

  get projectId() {
    const team = this.teamsQuery.getActive();
    if (!team) return undefined;
    const id = getLastProject(team.slug);
    if (!id) {
      if (team.projects?.length > 0) { // projects was undefined here for some reason
        return team.projects[0]._id;
      } else {
        return undefined;
      }
    }
    return id;
  }

  private pageSize = PAGE_SIZE;

  constructor(
    private recentParticipationService: RecentParticipationService,
    private containerScrollEventService: ContainerScrollEventService,
    private recentEntitiesQuery: RecentEntitiesQuery,
    private elementRef: ElementRef<HTMLElement>,
    private activatedRoute: ActivatedRoute,
    private teamService: TeamService,
    private teamsQuery: TeamsQuery,
    private projectService: ProjectService,
  ) {
    this.activatedRoute.parent?.params.subscribe(({ team }) => {
      if (team) {
        this.teamService.setActiveTeamSlug(team);
        this.projectService.setActiveProject(null);
      }
    });

    this.containerScrollEventService.approachingBottom$.pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.loadMore();
    });

    fromEvent(window, 'resize').pipe(
      debounceTime(300),
      untilDestroyed(this),
    ).subscribe(() => {
      const currentPageSize = this.getPageSize();
      if (currentPageSize > this.pageSize) {
        this.loadMore();
      }
    });
  }

  ngAfterContentInit() {
    this.activatedRoute.data.subscribe(({ title, onlyLive, path }: RouteData) => {
      if (title) this.title = title;
      this.onlyLive = !!onlyLive;
      this.pageSize = this.getPageSize();
      this.entities$ = this.recentParticipationService.recentEntities(this.pageSize, onlyLive);

      const teamSlug = this.teamsQuery.getActiveId();
      if (teamSlug && path && !['live', 'recent'].includes(path)) saveLastProject(teamSlug, path);
    });
  }

  private loadMore() {
    this.pageSize = this.getPageSize();
    this.recentEntitiesQuery.selectCount().pipe(
      take(1),
      untilDestroyed(this),
    ).subscribe(storeSize => {
      this.recentParticipationService.requestSize(this.pageSize + storeSize);
    });
  }

  private getPageSize() {
    const containerNativeElement = this.elementRef.nativeElement;
    const { height: tileHeight, width: tileWidth } = recentEntityTileDimension;
    const displayWidth = containerNativeElement.getBoundingClientRect().width;
    const approxDisplayHeight = window.innerHeight;
    const tilesInOneRow = Math.floor(displayWidth / tileWidth);
    const tilesInOneColumn = Math.floor(approxDisplayHeight / tileHeight);
    const tilesInOnePage = tilesInOneRow * tilesInOneColumn;
    return tilesInOnePage + (tilesInOneRow * 2);
  }

  entityTrackBy(index: number, entityWithParticipant: RecentEntity) {
    return entityWithParticipant.entity._id;
  }
}
