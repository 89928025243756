import { TeamMembersService } from 'services/team-members.service';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ClrDatagrid } from '@clr/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { capitalize } from 'lodash';
import natsort from 'natsort';
import { pushOpenedBy } from 'magma/common/clientUtils';
import { DEFAULT_DRAWING_NAME, IMPORT_FILE_TYPES } from 'magma/common/constants';
import { boldPlusIcon, faClone, faCogs, faFileImport, faFolderPlus, faFolderTree, faInfoCircle, faPencil, farEllipsisH, farEllipsisV, farPlus, faTimes, faTrash, trashArrowUpIcon, xmarkIcon } from 'magma/common/icons';
import { Analytics, Feature, Permission, ProjectType } from 'magma/common/interfaces';
import { copyText } from 'magma/common/utils';
import { ToastService } from 'magma/services/toast.service';
import { ITrackService } from 'magma/services/track.service.interface';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, publishReplay, refCount, startWith, switchMap, tap } from 'rxjs/operators';
import { AppService } from 'services/app.service';
import { BillingService } from 'services/billing.service';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { EntitiesQuery } from 'services/entities.query';
import { EntitiesService } from 'services/entities.service';
import { EntitiesStore } from 'services/entities.store';
import { EntityDragDropNotificationService } from 'services/entity-drag-drop-notification.service';
import { InvitationQuery } from 'services/invitation.query';
import { InvitationService } from 'services/invitation.service';
import { ModalService } from 'services/modal.service';
import { PresenceService } from 'services/presence.service';
import { ProjectQuery } from 'services/projects.query';
import { ProjectService } from 'services/projects.service';
import { RecentEntitiesQuery } from 'services/recent-entities/recent-entities.query';
import { RecentEntitiesStore } from 'services/recent-entities/recent-entities.store';
import { RecentParticipationService } from 'services/recent-participation.service';
import { RouterService } from 'services/router.service';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { UserService } from 'services/user.service';
import { SortBy } from 'shared/constants';
import { getEntityAbsoluteUrlWithPassword } from 'shared/product-info';
import { refCountDelay } from 'shared/rxjs';
import { getTypeName, removeLastProject, routeToProject, routeToTeam, saveLastProject, userStorageLimit } from 'shared/utils';
import { recentEntityTileDimension } from 'util/recent-entities';
import { downloadUrl, generateEntityDownloadUrl, setOgEntityName } from 'util/util';
import { belowBreakpointSM, ServerConstant } from '../utils';
import { LoadingPage } from '../loading-page.class';
import { EntityToolbarButton } from '../../util/entities-toolbar';
import { EntityData, EntityType, FlowchartData, FlowchartListData, HiddenProjectsDescriptions, RecentEntity, StorageUsageData } from 'shared/interfaces';
import { storageGetJson, storageSetJson } from 'magma/services/storage';
import { invalidEnum, removeFileExtension } from 'magma/common/baseUtils';
import { EntityGridToolbarEvent } from '../entity-grid/entity-grid.component';
import { disableMyArtdesk } from 'magma/common/data';
import { delay } from 'magma/common/promiseUtils';
import { EntityActions } from '@datorama/akita';
import { ManageService } from 'magma/services/manageService';

const PROJECT_DESCRIPTION_MAX = 440;

@UntilDestroy()
@Component({
  selector: 'app-entities-page',
  templateUrl: './entities-page.component.pug',
  styleUrls: ['./entities-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  host: { class: 'use-magma-styles' },
})
export class EntitiesPageComponent extends LoadingPage<FlowchartListData[]> implements AfterViewInit, OnInit {
  readonly faTimes = faTimes;
  readonly faInfo = faInfoCircle;
  readonly trashArrowUpIcon = trashArrowUpIcon;
  readonly faFolderTree = faFolderTree;
  readonly boldPlusIcon = boldPlusIcon;
  readonly closeIcon = xmarkIcon;
  @ServerConstant('defaultCode') defaultCode!: string;
  @ViewChild(ClrDatagrid) clrDataGrid: ClrDatagrid | null = null;
  @Input() set entities(value: EntityData[]) {
    this.loading = true;
    this.isBinFolder = this.isBinFolder || this.activatedRoute.snapshot.routeConfig?.path === 'bin';
    if (!this.isBinFolder) {
      this.entities$ = of(value).pipe(
        this.presenceService.mapLivePresence(),
        publishReplay(1),
        refCountDelay(1000),
      );
    } else {
      this.entities$ = of(value).pipe(
        publishReplay(1),
        refCountDelay(1000),
      );

      this.loading = false;
    }
  }
  @Input() showHierarchy = false;
  @Input() dragDrop = true;
  @Input() isSearchPage = false;
  @Input() fetchingData = false;
  @Input() canFetchMore = false;
  @Input() isBinFolder = false;
  @Output() reloadSearch = new EventEmitter<Event>();
  @Output() entityAction = new EventEmitter<EntityGridToolbarEvent>();

  private $resizeSubject = new Subject<void>();
  private sorter = natsort({ insensitive: true });

  disableMyArtdesk = disableMyArtdesk;
  importFileTypes = IMPORT_FILE_TYPES;
  importIcon = faFileImport;
  newFolderIcon = faFolderPlus;
  farEllipsisV = farEllipsisV;
  farEllipsisH = farEllipsisH;
  renameIcon = faPencil;
  duplicateIcon = faClone;
  deleteIcon = faTrash;
  settingsIcon = faCogs;
  faInfoCircle = faInfoCircle;
  faPlusIcon = farPlus;
  isMoveToProjectModal = false;
  showCreateFlowchartReadOnlyTooltip = false;
  loading = false;
  flowchartInDeleteProcess = '';
  flowchart: EntityData | null = null;
  flowchartOwner: string | undefined = '';
  invitationState$ = this.invitationQuery.invitationData$;
  activeStatusFlowchart = '';
  subscriptionModal$ = this.teamService.getSuccessTeamUpgrade();
  teams$ = this.teamsQuery.selectAll();
  currentTeam$ = this.teamsQuery.selectActive();
  projects$ = this.projectQuery.selectAll();
  availableProjects$ = this.teamsQuery.availableProjects$;
  onDragOverId = '';
  belowBreakpointSM$ = new BehaviorSubject(belowBreakpointSM());
  entitiesCount$ = this.flowchartsQuery.selectCount();
  isTwoTeamInvitationModalOpen = false;
  projectId: null | string = null;
  selectedEntities: EntityData[] = [];
  highlightItem = '';
  folder: EntityData | undefined = undefined;
  folderId: string | undefined = undefined;
  activeTeam$ = this.teamsQuery.selectActive();
  teamId$ = this.activeTeam$.pipe(map(team => team?._id));
  projectId$ = this.projectService.projectId$;
  showViewAllButton = false;
  reachedStorageLimit = false;
  recentEntities$ = combineLatest([
    this.$resizeSubject.pipe(debounceTime(300), startWith(true)),
    this.recentParticipationService.recentEntities(),
  ]).pipe(
    map(([_, recentEntities]) => {
      // TODO - should the live-entity-preview component be responsible for it?
      const count = this.getRecentEntitiesToDisplayCount();
      // we should fetch +1 to trigger the View all button
      if ((count + 1) > this.recentParticipationService.getRequestSize()) {
        this.recentParticipationService.requestSize(count + 1);
      }

      this.showViewAllButton = recentEntities.length > count;
      return recentEntities.slice(0, count);
    }),
  );
  showRecentEntities$ = this.activeTeam$
    .pipe(
      map((team) => !this.isSearchPage && !team && !belowBreakpointSM()),
      distinctUntilChanged(),
    );
  loadingIndicatorForEntity$ = this.recentParticipationService.loadingIndicatorForEntity$;
  loadingIndicatorForEntity: string | undefined = undefined;
  reload$ = new BehaviorSubject(null);
  sortBy$ = new BehaviorSubject<SortBy>(SortBy.SizeOnDisk);
  entities$ = combineLatest([
    this.projectService.projectId$,
    this.activatedRoute.queryParams.pipe(map(params => params.folder as string | undefined), distinctUntilChanged()),
    this.reload$,
  ]).pipe(
    tap(() => this.loading = true),
    switchMap(([projectId, folderId]) => {
      const team = this.teamsQuery.getActive();

      if (projectId) {
        this.projectId = projectId;
        return this.projectService.getProjectWithEntities(projectId, folderId).pipe(
          map(data => data.entities),
          tap(() => {
            if (team) {
              saveLastProject(team.slug, projectId);
            }
          }),
          catchError(e => {
            this.handleError(e);
            return of([]);
          }),
        );
      } else if (disableMyArtdesk) {
        return of(undefined);
      } else if (this.isBinFolder) {
        return this.entitiesService.getRemoved(team?._id, folderId).pipe(() => of([]));
      } else {
        return this.entitiesService.getAll(folderId, this.isBinFolder);
      }
    }),
    tap(() => this.loading = false),
    switchMap(entities =>
      combineLatest([
        this.sortBy$,
        this.belowBreakpointSM$,
      ]).pipe(
        distinctUntilChanged(),
        map(([sortBy, belowBreakpointSM]) => entities?.slice().sort((a, b) => {
          if (belowBreakpointSM) return new Date(b.updatedAt!).getTime() - new Date(a.updatedAt!).getTime();
          switch (sortBy) {
            case SortBy.Name:
              return this.sorter(a.name, b.name);
            case SortBy.SizeOnDisk:
              return (a as any).sizeOnDisk! - (b as any).sizeOnDisk!;
            case SortBy.CreatedAt:
              return new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime();
            case SortBy.ModifiedAt:
              return new Date(a.modifiedAt!).getTime() - new Date(b.modifiedAt!).getTime();
            case SortBy.OpenedAt:
            default:
              return new Date(a.openedAt!).getTime() - new Date(b.openedAt!).getTime();
          }
        })),
      )
    ),
    publishReplay(1),
    refCount(),
  );

  actions$ = this.entitiesQuery.selectEntityAction([EntityActions.Add, EntityActions.Remove, EntityActions.Update]);
  storage$ = combineLatest([
    this.userService.user$.pipe(map(user => user?.pro), distinctUntilChanged()),
    this.teamsQuery.selectActive().pipe(map(team => team?._id), distinctUntilChanged()),
    this.reload$,
    this.projectService.projectId$,
  ]).pipe(
    switchMap(([pro, teamId]) =>
      (teamId ? this.teamService.getUsageData(teamId) : this.entitiesService.getUsageData())
        .pipe(map(storage => {
          this.storage = storage;
          const team = teamId ? this.manage.team(teamId) : undefined;
          this.reachedStorageLimit = false;
          if (this.manage.isStorageLimitActive(team) && storage && storage.limit) {
            this.reachedStorageLimit = storage.used >= storage.limit;
          }
          return { ...storage, pro };
        }))),
  );
  storage: StorageUsageData | undefined = undefined;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private flowchartStore: EntitiesStore,
    private entitiesService: EntitiesService,
    private recentParticipationService: RecentParticipationService,
    private flowchartsQuery: EntitiesQuery,
    private projectService: ProjectService,
    private projectQuery: ProjectQuery,
    private teamService: TeamService, // do not remove
    private teamsQuery: TeamsQuery,
    private invitationQuery: InvitationQuery,
    private invitationService: InvitationService,
    private appService: AppService,
    private billingService: BillingService,
    private userService: UserService,
    private dragDropService: EntityDragDropNotificationService,
    private toastService: ToastService,
    private modals: ModalService,
    private presenceService: PresenceService,
    private recentEntitiesQuery: RecentEntitiesQuery,
    private recentEntitiesStore: RecentEntitiesStore,
    private elementRef: ElementRef<HTMLElement>,
    private track: ITrackService,
    private entitiesQuery: EntitiesQuery,
    private breadcrumbService: BreadcrumbService,
    private routerService: RouterService,
    private teamMemberService: TeamMembersService,
    private manage: ManageService,
  ) {
    super();

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      tap(() => {
        this.elementRef.nativeElement.scrollIntoView();
        this.flowchartChangesListener();
      }),
      untilDestroyed(this),
    ).subscribe();

    this.activatedRoute.params.subscribe(({ team, project }) => {
      this.teamService.setActiveTeamSlug(team);
      this.projectService.setActiveProject(project);
    });

    this.teamService.correctActiveTeam$.pipe(untilDestroyed(this)).subscribe(slug => {
      const projectId = this.projectService.activeProjectId;
      if (projectId) void this.router.navigate(routeToProject(slug, projectId));
    });

    this.dragDropService.dropListener$.pipe(
      switchMap(dropData => this.projectService.moveEntities({
        project: dropData.projectId,
        folder: dropData.folderId,
        entities: this.selectedEntities,
      })),
      tap(() => {
        this.selectedEntities = [];
        this.reload();
      }),
      untilDestroyed(this),
    ).subscribe();

    // refresh recent entities when returning from the editor
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        if (event.url === '/') { // editor redirects to '/'
          this.recentParticipationService.refreshEntities(this.getRecentEntitiesToDisplayCount() + 1);
        }
      }),
      untilDestroyed(this),
    ).subscribe();

    combineLatest([
      this.activatedRoute.queryParams.pipe(map(params => params.folder), distinctUntilChanged()),
      this.reload$
    ]).pipe(
      tap(([folderId]) => this.folderId = folderId),
      switchMap(([folderId]) => folderId ? this.entitiesService.get(folderId, this.isBinFolder) : of(undefined)),
      catchError(e => {
        this.handleError(e);
        return of(undefined);
      }),
      untilDestroyed(this),
    ).subscribe(folder => this.folder = folder);

    combineLatest([this.userService.user$, this.activeTeam$]).pipe(
      map(([user, team]) => {
        if (team) {
          return {
            storage: team.storageUsage ?? { used: 0, limit: 1 },
            noLimit: !!team.featureFlags?.includes(Feature.StorageNoUsageLimits)
          };
        } else {
          return {
            storage: { used: user?.storageUsage ?? 0, limit: userStorageLimit(user ?? {}) },
            noLimit: !!user?.featureFlags?.includes(Feature.StorageNoUsageLimits)
          };
        }
      }),
      untilDestroyed(this),
    ).subscribe(({ storage, noLimit }) => {
      this.storage = storage;
      this.reachedStorageLimit = !noLimit && storage.used > storage.limit;
    });

    dragDropService.dragStartSubject.subscribe((selectedEntities: EntityData[]) => {
      this.selectedEntities = selectedEntities;
      const team = this.teamsQuery.getActive();
      this.dragDropService.dragStart(team || null);
    });

    dragDropService.isDragging$.subscribe((isDragging) => {
      if (!isDragging) {
        this.dragDropService.dragStop();
      }
    });

    this.loadingIndicatorForEntity$.pipe(untilDestroyed(this)).subscribe(value => this.loadingIndicatorForEntity = value);

    this.shouldBeContentPageInstead$.subscribe();
  }

  get project() {
    return this.projectQuery.getActive();
  }

  get canCreateEntities() {
    return this.teamMemberService.isPermissionFlagSet(Permission.CanCreateEntities, this.project, this.folder);
  }

  get canDuplicateFolder() {
    return this.teamMemberService.isPermissionFlagSet(Permission.CanDuplicateEntities, this.project, this.folder);
  }

  get canDeleteFolder() {
    return this.teamMemberService.isPermissionFlagSet(Permission.CanDuplicateEntities, this.project, this.folder);
  }

  get canRenameFolder() {
    return this.teamMemberService.isPermissionFlagSet(Permission.CanUpdateEntities, this.project, this.folder);
  }

  get canDeleteEntities() {
    return this.teamMemberService.isPermissionFlagSet(Permission.CanDeleteEntities, this.project, this.folder);
  }

  get canDeleteEntitiesPermanently() {
    return this.teamMemberService.isPermissionFlagSet(Permission.CanDeleteEntitiesPermanently, this.project, this.folder);
  }

  get canUpdateEntities() {
    return this.teamMemberService.isPermissionFlagSet(Permission.CanUpdateEntities, this.project, this.folder);
  }

  get canUpdateProject() {
    return this.teamMemberService.isPermissionFlagSet([Permission.CanUpdateProjects]);
  }

  private handleError(e: Error & { status?: number; }) {
    DEVELOPMENT && console.error(e);

    if (e.status === 403 || e.status === 404) {
      const teamSlug = this.teamsQuery.getActiveId();
      if (teamSlug) {
        removeLastProject(teamSlug); // prevent redirecting to deleted project
        void this.router.navigate(routeToTeam(teamSlug));
      } else {
        void this.router.navigate(['/my']);
      }
    } else {
      this.toastService.error({ message: e.message });
    }
  }

  projectDescription(truncate = true) {
    const { description } = this.projectQuery.getActive() || {};

    if (!description) return undefined;
    return truncate && description.length > PROJECT_DESCRIPTION_MAX ? description.slice(0, PROJECT_DESCRIPTION_MAX) + '...' : description;
  }

  get projectDescriptionIsTruncated() {
    const { description } = this.projectQuery.getActive() || {};
    return description && description.length > PROJECT_DESCRIPTION_MAX;
  }

  getProjectTitle() {
    return this.projectQuery.getActive()?.title;
  }

  get projectInfoBoxIsSet() {
    return !this.isBinFolder && this.projectQuery.getActive()?.title || this.projectQuery.getActive()?.description;
  }

  async openProjectDescription() {
    const project = this.projectQuery.getActive();
    if (project) await this.modals.openProjectDescription(project);
  }

  onDrop(target: EntityData) {
    const targetProject = typeof target.project === 'string' ? target.project : target.project?._id ?? undefined;
    this.dragDropService.dropped(targetProject, target._id);
  }

  get projectName() {
    return this.projectQuery.getActive()?.name;
  }

  get isProjectPage() {
    return this.activatedRoute.snapshot.data.page === 'project';
  }

  @ViewChild('entitiesPage')
  set entitiesPage(entitiesPageElementRef: ElementRef | null) {
    if (entitiesPageElementRef) {
      this._flowchartPage = entitiesPageElementRef;
    }
  }
  get entitiesPage() {
    return this._flowchartPage;
  }

  private _flowchartPage: ElementRef | null = null;

  ngAfterViewInit() {
    this.$resizeSubject.pipe(
      debounceTime(300),
      untilDestroyed(this),
    ).subscribe(() => {
      this.clrDataGrid?.resize();
    });

    // we should fetch +1 to trigger the View all button
    this.recentParticipationService.requestSize(this.getRecentEntitiesToDisplayCount() + 1);
  }

  async ngOnInit() {
    this.highlightItem = this.activatedRoute.snapshot.queryParams.highlight;
  }

  @HostListener('window:resize')
  onResize() {
    const newBelowBreakpointSM = belowBreakpointSM();
    if (this.belowBreakpointSM$.value !== newBelowBreakpointSM) this.belowBreakpointSM$.next(belowBreakpointSM());
    this.$resizeSubject.next();
  }

  private flowchartChangesListener() {
    const { paramMap, data } = this.activatedRoute.snapshot;

    switch (data.page) {
      case 'drafts': {
        this.projectService.setActiveProject(null);
        this.teamService.setActiveTeamSlug(null);
        this.load(this.flowchartsQuery.selectAll(), this.flowchartsQuery.selectLoading());
        break;
      }
      case 'project': {
        this.projectId = paramMap.get('project') as string;
        this.projectService.setActiveProject(this.projectId);
        const project = this.projectService.getProject(this.projectId);
        if (project) {
          this.load(of(project.entities!), this.projectQuery.selectLoading());
        }
        break;
      }
      default: {
        if (!this.isSearchPage) {
          void this.router.navigate(['my']);
        }
        break;
      }
    }
  }

  private closeTwoTeamInvitationModal() {
    this.invitationService.closeTwoTeamsInvitationModal();
    void this.router.navigate([], { queryParams: { joinTeam: null } });
  }

  async import(files: File[]) {
    const hasPdf = files.some(file => file.type === 'application/pdf');
    const mode = files.length === 1 ? 'separate' : await this.modals.multiFileImportManage(hasPdf);
    const user = this.userService.user!;
    const folderId = this.folder?._id;

    switch (mode) {
      case 'separate':
      case 'sequence': {
        let sequence = mode === 'sequence' && files.length > 1 ? 'true' : undefined;

        for (const file of files) {
          const navigate = (mode === 'sequence' || files.length === 1) && file === files[0];
          const name = removeFileExtension(file.name);
          const entity = await this.entitiesService.importEntityWithToast(name, [file], folderId, user, navigate, sequence);
          if (entity) sequence = entity.sequence;
        }

        if (mode === 'separate' && files.length > 1) {
          this.reload();
        }

        break;
      }
      case 'layers': {
        const name = removeFileExtension(files[0].name) || DEFAULT_DRAWING_NAME;
        await this.entitiesService.importEntityWithToast(name, files, folderId, user);
        break;
      }
      case undefined: break;
      default: invalidEnum(mode);
    }
  }

  async newFolder() {
    if (this.reachedStorageLimit) {
      this.storageLimitExceeded();
      return;
    }
    await this.modals.createNewFolder(this.folder?._id);
    this.reload();
  }

  async renameFolder() {
    if (!this.folder) return;
    await this.renameEntity(this.folder);
  }

  async duplicateFolder() {
    if (this.reachedStorageLimit) {
      this.storageLimitExceeded();
      return;
    }
    if (!this.folder) return;
    const cloned = await this.entitiesService.cloneEntityWithToast(this.folder);
    if (cloned) this.reload();
  }

  async deleteFolder() {
    const team = this.teamsQuery.getActive();
    const folder = this.folder;
    if (!folder) return;

    const deleted = await this.deleteEntity(folder);
    if (deleted) {
      // go to parent folder or team/project/my root
      const project = typeof folder.project === 'string' ? folder.project : folder.project?._id;
      this.routerService.navigateToFolder(team?.slug, project, folder.folder);
    }
  }

  storageLimitExceeded() {
    this.manage.reachedStorageLimit();
  }

  async projectSettings() {
    const project = this.projectQuery.getActive();
    if (project) await this.modals.editProject(project);
  }

  async deleteProject() {
    const project = this.projectQuery.getActive();
    if (project) await this.modals.deleteProject(project);
  }

  async deleteAll(entities: EntityData[]) {
    await this.onEntityAction({ button: { id: 'delete' }, entity: entities });
  }

  async restoreAll(entities: EntityData[]) {
    if (this.reachedStorageLimit) {
      this.storageLimitExceeded();
      return;
    }
    await this.onEntityAction({ button: { id: 'restore' }, entity: entities });
  }

  async moveAll(entities: EntityData[]) {
    await this.onEntityAction({ button: { id: 'move' }, entity: entities });
  }

  failed(error: Error) {
    this.toastService.error({ message: error.message });
  }

  private async renameEntity(entity: EntityData) {
    const newName = await this.modals.renameEntity({
      forRename: true,
      name: entity.name,
      header: `Rename ${getTypeName(entity.type)}`,
      label: `${getTypeName(entity.type)} name`,
    });

    if (newName) {
      try {
        await this.entitiesService.renameEntity(entity, newName);
        // TODO: this should be generalized and moved to entity service ?
        const recentEntity = this.getRecentEntityFromEntityId(entity._id);
        if (recentEntity) this.recentEntitiesStore.update(recentEntity._id, { ...recentEntity, entity: { ...recentEntity.entity, name: newName } });
        this.breadcrumbService.reload$.next(null);
      } catch (e) {
        this.toastService.error({ message: `Failed to rename ${getTypeName(entity.type)}`, subtitle: e.message });
        DEVELOPMENT && console.error(e);
      }

      this.reload();
    }
  }

  onEntityOpen(entity: EntityData) {
    this.openEntity(null, entity);
  }

  updateFlowchart(flowchart: FlowchartData) {
    const { _id } = flowchart;
    this.flowchartStore.upsert((_id || ''), flowchart);
  }

  // TODO: move to service
  openEntity(event: Event | null, entity: EntityData) {
    event?.stopImmediatePropagation();

    if (entity.type !== EntityType.Folder) {
      setOgEntityName(entity.name!);
      pushOpenedBy(entity.shortId, 'my-artworks');
    }

    this.routerService.navigateToEntity(entity, 'entities-page');
  }

  showPlans() {
    this.billingService.setBillingPlanModal(true);
  }

  async openSupport() {
    await this.appService.onContactSupport();
    this.closeTwoTeamInvitationModal();
  }

  shouldShowDocumentStatusColumn$ = of(false);

  private async moveEntity(entity: EntityData | EntityData[]) {
    if (Array.isArray(entity)) {
      if (entity.length === 1) {
        await this.moveEntity(entity[0]);
        return;
      }

      let entityToMove = entity[0];
      const projectId = typeof entityToMove.project === 'string' ? entityToMove.project : entityToMove.project?._id;
      const team = this.teamService.getTeam(entityToMove.team);

      const isAllowedToMoveOutside = this.teamMemberService.isPermissionFlagSet([Permission.CanMoveEntitiesOutsideTeam]);

      const selectedFolder = await this.modals.selectFolder({
        pickedFolderIds: entityToMove.type === EntityType.Folder ? [entityToMove._id] : [],
        teamId: isAllowedToMoveOutside ? undefined : team?._id,
        showArtdesk: (isAllowedToMoveOutside || !team) && !disableMyArtdesk,
        preselectedFolder: { teamId: entityToMove.team, projectId, folderId: entityToMove.folder },
      });

      if (selectedFolder) {
        const teamId = selectedFolder.teamId;
        // checking if the storage limit is exceeded
        const storage = await this.manage.getStorageUsage(teamId, true);
        const team = teamId ? this.manage.team(teamId) : undefined;
        if (this.manage.isStorageLimitActive(team) && storage && storage.used >= storage.limit) {
          this.manage.reachedStorageLimit();
          return;
        }
        // it has its own error handler
        entity.map(async (e) => {
          await this.projectService.moveEntities({
            entities: [e],
            project: selectedFolder.projectId,
            folder: selectedFolder.folderId,
          }).toPromise();
          this.reload();
        });
      }
    } else {
      const projectId = typeof entity.project === 'string' ? entity.project : entity.project?._id;
      const team = this.teamService.getTeam(entity.team);

      const isAllowedToMoveOutside = this.teamMemberService.isPermissionFlagSet([Permission.CanMoveEntitiesOutsideTeam]);

      const selectedFolder = await this.modals.selectFolder({
        pickedFolderIds: entity.type === EntityType.Folder ? [entity._id] : [],
        teamId: isAllowedToMoveOutside ? undefined : team?._id,
        showArtdesk: (isAllowedToMoveOutside || !team) && !disableMyArtdesk,
        preselectedFolder: { teamId: entity.team, projectId, folderId: entity.folder },
      });

      if (selectedFolder) {
        const teamId = selectedFolder.teamId;
        // checking if the storage limit is exceeded
        const storage = await this.manage.getStorageUsage(teamId, true);
        const team = teamId ? this.manage.team(teamId) : undefined;
        if (this.manage.isStorageLimitActive(team) && storage && storage.used >= storage.limit) {
          this.manage.reachedStorageLimit(team, true);
          return;
        }
        // it has its own error handler
        await this.projectService.moveEntities({
          entities: [entity],
          project: selectedFolder.projectId,
          folder: selectedFolder.folderId,
        }).toPromise();
        this.reload();
      }
    }
  }

  async onEntityAction({ button, entity }: { button: EntityToolbarButton; entity: EntityData | EntityData[]; }) {
    if (Array.isArray(entity)) {
      if (entity.length === 1) { // run regular handler for single item
        await this.onEntityAction({ button, entity: entity[0] });
        return;
      }

      switch (button.id) {
        case 'download-png':
          for (const e of entity) {
            downloadUrl(generateEntityDownloadUrl(e.shortId, 'png'));
            await delay(500);
          }
          this.track.event(Analytics.ExportImage, { format: 'png', eventSource: 'entities-page' });
          break;
        case 'clone': {
          await Promise.all(entity.map(e => this.entitiesService.cloneEntityWithToast(e)));
          this.reload();
          break;
        }
        case 'delete': {
          const confirmed = await this.modals.deleteEntity({ entity, openedBy: 'entities-page' });
          if (confirmed) await Promise.all(entity.map(e => this.deleteEntityWithoutConfirmation(e, true)));
          break;
        }
        case 'bin': {
          const confirmed = await this.modals.moveToBinEntity({ entity, openedBy: 'entities-page' });
          if (confirmed) await Promise.all(entity.map(e => this.deleteEntityWithoutConfirmation(e)));
          break;
        }
        case 'restore': {
          await Promise.all(entity.map(e => this.entitiesService.restoreEntity(e)));
          this.reload();
          break;
        }
        case 'move': {
          await this.moveEntity(entity);
          this.reload();
          break;
        }
        case 'copy-link': {
          await copyText(entity.map(getEntityAbsoluteUrlWithPassword).join('\n'));
          this.track.event(Analytics.CopyDrawingLink, { eventSource: 'entities-page' });
          break;
        }
        default:
          DEVELOPMENT && console.error(`Invalid toolbar event "${button.id}"`);
      }
    } else {
      switch (button.id) {
        case 'share': {
          // TODO if user doesn't have access to change password but it has it stored in localstorage - show it
          const hadPassword = !!entity.hasPassword;
          await this.modals.shareEntity({ entity, openedBy: 'entities-page', team: this.teamsQuery.getActive() });
          const hasPassword = !!this.entitiesQuery.getEntity(entity._id)?.hasPassword;

          if (hadPassword !== hasPassword) {
            // TODO: this should be generalized and moved to entity service ?
            const recentEntity = this.getRecentEntityFromEntityId(entity._id);
            if (recentEntity) {
              this.recentEntitiesStore.update(recentEntity._id, { ...recentEntity, entity: { ...recentEntity.entity, hasPassword } });
            }
            this.reload();
          }
          break;
        }
        case 'share-as-image':
          await this.modals.shareEntityAsImage({ entityId: entity._id, openedBy: 'entities-page' });
          break;
        case 'download-png':
          downloadUrl(generateEntityDownloadUrl(entity.shortId, 'png'));
          this.track.event(Analytics.ExportImage, { format: 'png', eventSource: 'entities-page' });
          break;
        case 'move':
          await this.moveEntity(entity);
          break;
        case 'clone':
          await this.entitiesService.cloneEntityWithToast(entity);
          this.reload();
          break;
        case 'delete':
          await this.deleteEntity(entity, true);
          break;
        case 'bin':
          await this.deleteEntity(entity);
          break;
        case 'rename':
          await this.renameEntity(entity);
          break;
        case 'export': {
          const isPro = this.teamService.isUserOrTeamPro(entity.team);
          const hasAccess = this.teamService.canUserExportEntity(entity);
          const error = hasAccess ? undefined : (
            entity.hasPassword ? `This drawing is password protected` : `You don't have access to export this drawing`);
          await this.modals.exportEntity({ isPro, entityShortId: entity.shortId, cacheId: entity.cacheId, openedBy: 'entities-page', error });
          break;
        }
        case 'copy-link':
          await copyText(getEntityAbsoluteUrlWithPassword(entity));
          this.track.event(Analytics.CopyDrawingLink, { eventSource: 'entities-page' });
          break;
        case 'restore':
          await this.entitiesService.restoreEntity(entity);
          this.reload();
          break;
        case 'restore-folder': {
          const confirmation = await this.modals.openDeletedFolder({ entity, openedBy: 'entities-page' });
          if (confirmation) {
            await this.entitiesService.restoreEntity(entity);
            this.reload();
          }
          break;
        }
        default:
          DEVELOPMENT && console.error(`Invalid toolbar event "${button.id}"`);
      }
    }
  }

  private async deleteEntity(entity: EntityData, permanentDelete = false) {
    if (!permanentDelete || this.checkDeletionPermessions()) {
      const shouldDelete = permanentDelete ? await this.modals.deleteEntity({ entity, openedBy: 'entities-page' }) : await this.modals.moveToBinEntity({ entity, openedBy: 'entities-page' });
      if (!shouldDelete) return false;
      return await this.deleteEntityWithoutConfirmation(entity, permanentDelete);
    }
  }

  private async deleteEntityWithoutConfirmation(entity: EntityData, permanentDelete = false) {
    try {
      const deleteMessage = permanentDelete ? 'Deleted permanently' : 'moved to bin';
      await (this.entitiesService.remove(entity._id, this.projectId ?? undefined, permanentDelete).toPromise());
      this.toastService.notification({
        message: `${capitalize(getTypeName(entity.type))} "${entity.name}" ${deleteMessage}.`,
        actionText: permanentDelete ? undefined : 'Undo',
        action: permanentDelete ? undefined : () => this.entitiesService.restoreEntity(entity).then(() => this.reload()),
        closable: false,
        timeout: 5000
      });
      const recentEntity = this.getRecentEntityFromEntityId(entity._id);
      if (recentEntity) this.recentEntitiesStore.remove(recentEntity._id);
      this.recentParticipationService.requestSize(this.getRecentEntitiesToDisplayCount());
      this.reload();
      return true;
    } catch (e) {
      DEVELOPMENT && console.error(e);
      this.toastService.error({ message: `Failed to delete ${getTypeName(entity.type)}`, subtitle: e.message });
      return false;
    }
  }

  private getRecentEntityFromEntityId(id: string) {
    return this.recentEntitiesQuery.getAll().find(({ entity }) => entity._id === id);
  }

  private getRecentEntitiesToDisplayCount() {
    const { width } = recentEntityTileDimension;
    const containerNativeElement = this.elementRef.nativeElement;
    const displayWidth = containerNativeElement.getBoundingClientRect().width;
    return Math.floor(displayWidth / width);
  }

  private reload() {
    this.reloadSearch.emit();
    this.reload$.next(null);
  }

  recentEntityTrackBy(_: number, item: RecentEntity) {
    return item.entity?._id; // HACK: entity was undefined for some reason
  }

  entityTrackBy(_: number, entityData: EntityData) {
    return entityData._id;
  }

  onSortBy(event: SortBy) {
    this.sortBy$.next(event);
  }


  async updateProjectDescription() {
    const project = this.projectQuery.getActive();
    if (project) await this.modals.editProjectDescription(project);
  }

  async deleteProjectDescription() {
    const project = this.projectQuery.getActive();
    if (project) await this.projectService.updateProject(project._id, { description: '', title: '' }).toPromise();
  }

  isDescriptionHidden() {
    let hiddenProjects = storageGetJson<HiddenProjectsDescriptions>('hidden-project-descriptions') ?? { ids: {} };
    return this.projectId && hiddenProjects.ids[this.projectId];
  }

  hideDescription() {
    if (!this.projectId) return;
    let hiddenProjects = storageGetJson<HiddenProjectsDescriptions>('hidden-project-descriptions') ?? { ids: {} };
    hiddenProjects.ids[this.projectId] = true;
    storageSetJson('hidden-project-descriptions', hiddenProjects);
  }

  checkDeletionPermessions(multiple = false) {
    if (!this.canDeleteEntities || !this.canDeleteEntitiesPermanently) {
      const message = `You are not able to delete ${multiple ? 'the drawings' : 'this drawing'}. You need permission from the owner to manage the ${multiple ? 'drawings' : 'drawing'}.`;
      this.toastService.notification({
        title: 'Lack of permission',
        message,
        closable: true,
        timeout: 5000
      });
      return false;
    }
    return true;
  }

  @HostBinding('class.entities-content-page') shouldBeContentPageInstead = false;
  shouldBeContentPageInstead$ = this.projectId$.pipe(
    untilDestroyed(this),
    map((id) => {
      return this.projectService.getProject(id ?? '')?.type === ProjectType.ContentPage;
    }),
    tap((shouldBe) => { this.shouldBeContentPageInstead = shouldBe; }),
  );
}
