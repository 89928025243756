import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { EntityData, TeamData } from 'shared/interfaces';

export interface DropData {
  projectId?: string;
  folderId?: string;
}

@Injectable()
export class EntityDragDropNotificationService {
  private dragListenerSubject = new Subject<TeamData | null | undefined>();
  private dropListenerSubject = new Subject<DropData>();

  dragListener$ = this.dragListenerSubject.asObservable();
  dropListener$ = this.dropListenerSubject.asObservable();

  dragStart(team: TeamData | null) {
    this.dragListenerSubject.next(team);
  }

  dragStop() {
    this.dragListenerSubject.next(undefined);
  }

  dropped(projectId?: string, folderId?: string) {
    this.dropListenerSubject.next({ projectId, folderId });
  }

  isDragging$ = new BehaviorSubject<boolean>(false);
  dragStartSubject = new Subject<EntityData[]>();
}
