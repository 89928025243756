import { MAX_FREE_PRIVATE_ARTSPACE_MEMBERS } from 'shared/billing';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faInfoCircle } from 'magma/generated/fa-icons';
import { BillingService } from 'services/billing.service';
import { ModalService } from 'services/modal.service';
import { TeamData, TeamType } from 'shared/interfaces';
import { openTawk } from 'magma/common/utils';

@Component({
  selector: 'switching-to-private-modal',
  templateUrl: 'switching-to-private-modal.component.pug',
  styleUrls: ['switching-to-private-modal.component.scss'],
})
export class SwitchingToPrivateModalComponent {
  readonly infoIcon = faInfoCircle;
  MAX_FREE_PRIVATE_ARTSPACE_MEMBERS = MAX_FREE_PRIVATE_ARTSPACE_MEMBERS;

  @Input() data!: { team: TeamData };
  @Output() close = new EventEmitter<boolean | undefined>();

  constructor(private modalService: ModalService, private billingService: BillingService) { }

  ok() {
    this.close.emit(true);
  }

  onClose() {
    this.close.emit(undefined);
  }

  async upgrade() {
    await this.modalService.upgradeTeamSubscriptionModal({
      teamId: this.data.team._id,
      teamSlug: this.data.team.slug,
      alreadyOnProPlan: this.billingService.getTeamType(this.data.team) === TeamType.Professional
    });
  }

  async infoEducationalPlan() {
    await this.billingService.infoEducationalPlan();
    this.openSupport();
  }

  async infoCommunityPlan() {
    await this.billingService.infoCommunityPlan();
    this.openSupport();
  }

  openSupport() {
    openTawk();
    window.Intercom?.('showNewMessage');
  }
}
