import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { filter, map, share, switchMap } from 'rxjs/operators';
import { ModalService } from 'services/modal.service';
import { ProjectQuery } from 'services/projects.query';
import { ProjectService } from 'services/projects.service';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { getLastProject, routeToProject, routeToTeam } from 'shared/utils';

@UntilDestroy()
@Component({
  selector: 'team-page',
  templateUrl: './team-page.component.pug',
  styleUrls: ['./team-page.component.scss'],
  host: { class: 'use-magma-styles' },
})
export class TeamPageComponent {
  teamProjects$ = this.teamsQuery.selectActive().pipe(
    switchMap(team => this.projectQuery.select(state => {
      const projects = Object.values(state.entities ?? {});
      return projects.filter(project => project.team === team?._id);
    })),
    share(),
  );

  hasProjects: boolean | null = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private teamService: TeamService,
    private teamsQuery: TeamsQuery,
    private projectService: ProjectService,
    private projectQuery: ProjectQuery,
    private modals: ModalService,
  ) {

    this.teamService.correctActiveTeam$.pipe(untilDestroyed(this)).subscribe(slug => {
      void router.navigate(routeToTeam(slug));
    });

    combineLatest([
      this.teamsQuery.selectActive(),
      this.teamProjects$,
      // ensure that projects are loaded
      this.projectQuery.selectLoading().pipe(filter(loading => !loading)),
    ]).pipe(
      map(([team, projects]) => {
        this.hasProjects = !!projects.length;
        // redirect to first project
        if (team && projects.length) {
          const projectId = getLastProject(team.slug) ?? projects[0]._id;
          void router.navigate(routeToProject(team.slug, projectId));
        }
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  async createProject() {
    const team = this.teamsQuery.getActive();
    if (team) {
      const project = await this.modals.createProject(team._id);
      if (project) {
        void this.router.navigate(routeToProject(team.slug, project._id));
      }
    }
  }
}
