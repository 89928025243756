import { Component } from '@angular/core';
import { ModalService } from 'services/modal.service';

const discordInviteLink = PRODUCT_INFO.discordInviteLinks?.drawingBuddiesPage;

@Component({
  selector: 'drawing-buddies-page',
  templateUrl: 'drawing-buddies-page.component.pug',
  styleUrls: ['drawing-buddies-page.component.scss'],
})
export class DrawingBuddiesPageComponent {
  readonly discordInviteLink = discordInviteLink;

  constructor(private modalService: ModalService) { }

  createCanvas() {
    this.modalService.createDrawing();
  }
}
