import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResourcesLinksResponse } from 'shared/interfaces';
import { handleHttpError } from 'shared/utils';

export interface ResourcesLinksOperationsConfig {
  page?: number;
  pageSize?: number;
}

@Injectable({ providedIn: 'root' })
export class ResourcesLinksService {
  constructor(private http: HttpClient) {
  }

  getResourcesLinks({ page, pageSize }: ResourcesLinksOperationsConfig) {
    let params = new HttpParams();
    if (page) params = params.append('page', page.toString());
    if (pageSize) params = params.append('pageSize', pageSize.toString());

    return this.http.get<ResourcesLinksResponse>('/api/profile/resources-links', { params }).pipe(handleHttpError());
  }
}
