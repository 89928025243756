<div class="document-status">
  <div class="visible-control" (mouseenter)="onHover($event)" (outsideClick)="isDropdownVisible = false" (scrolledOutOfViewport)="isDropdownVisible = false" #parentStatusElement>
    <svg-icon [icon]="faCircle" class="is-solid" [ngClass]="status?.state || 'draft'"></svg-icon>
    <div class="label" *ngIf="label">{{ label }}</div>
  </div>

  <ng-container *ngIf="isDropdownVisible && status">
    <document-status-dropdown
      #statusDropdown
      [selectedStatus]="status.state"
      [lastChange]="status.lastChange || null"
      (selectionChanged)="onStatusChange($event)"
      topLevel
      [topLevelParentForPosition]="parentStatusElement"
      topLevelPosition="bottom center"
    ></document-status-dropdown>
  </ng-container>
</div>
