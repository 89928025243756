import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Analytics } from 'magma/common/interfaces';
import { ITrackService } from 'magma/services/track.service.interface';
import { EntityData, EntityType } from 'shared/interfaces';

export interface DeleteConfirmationModalInput {
  entity?: EntityData | EntityData[];
  openedBy: string;
}

@Component({
  selector: 'move-to-bin-confirmation-modal',
  templateUrl: 'move-to-bin-confirmation-modal.component.pug',
})
export class MoveToBinConfirmationModalComponent implements OnInit {
  @Output() close = new EventEmitter<boolean | undefined>();
  @Input() data: DeleteConfirmationModalInput = { entity: undefined, openedBy: '' };

  constructor(private track: ITrackService) {
  }

  get name() {
    if (this.data.entity && Array.isArray(this.data.entity)) {
      if (this.data.entity.length <= 1) {
        return `"${this.data.entity[0]?.name}"`;
      } else {
        return `${this.data.entity.length} items`;
      }
    } else {
      return `"${this.data.entity?.name}"`;
    }
  }

  get isFolder() {
    if (this.data.entity && Array.isArray(this.data.entity)) {
      return this.data.entity.some(e => e.type === EntityType.Folder);
    } else {
      return this.data.entity?.type === EntityType.Folder;
    }
  }

  get isMultiple() {
    return this.data.entity && Array.isArray(this.data.entity) && this.data.entity.length > 1;
  }

  ngOnInit() {
    this.track.event(Analytics.OpenMoveToBinModal, { openedBy: this.data.openedBy });
  }

  onMoveToBin() {
    this.close.emit(true);
  }

  onClose() {
    this.close.emit(undefined);
  }
}
