import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { RouterService } from './router.service';
import { TeamsQuery } from './team.query';

/*
## Testing scenarios
- You are on `/my`. Type `d` in search input. Navigated to `/search?q=d`. Results appear. No results appear before for `defaultSearchParams`.
- You are on `/my`. Type `x` in search input. Navigated to `/search?q=x`. Placeholder appear. No results appear before placeholder for `defaultSearchParams`.
- You are on `/my`. Press `Enter` in empty search input. Navigated to `/search`. Results appear for `defaultSearchParams`.
- You are on `/my`. Change place in search input dropdown to `My Artdesk`. No navigation to search page. Type `d` in search input. Navigated to `/search?q=d&personal=true`. Results appear.
- You are on `/my`. Change place in search input dropdown to `My Artdesk`. No navigation to search page.
  Type `x` in search input. Navigated to `/search?q=x&personal=true`. Placeholder appear. No results appear before placeholder for `defaultSearchParams`.
- You are on `/my`. Change place in search input dropdown to `My Artdesk`. Press `Enter` in empty search input.
  Navigated to `/search?personal=true`. Placeholder appear. No results appear before placeholder for `defaultSearchParams`.
- You are on `/my`. Change place in search input dropdown to `t1` team. Type `d` in search input. Navigated to `/search?q=d&team=t1`. Team 't1` icon is not highlighted.
- Repeat steps similar for `My Artdesk`.
- Go to search page. Set some params. Clicking browser back, go back to previously selected params.
  So with navigation history like: `/search`, `/search?q=d`, `/search?q=d&team=t1`,
  clicking browser back will go first to `/search?q=d`, then clicking back again will go to `/search`.
- You are on `/my`. Go into search page. Set some params. With navigation history like: `/search`, `/search?q=d`, `/search?q=d&team=t1`,
  clicking internal back, will go to `/my`.
- You are on `/s/t1/<projectId>?folder=<folderId>`. You go into search page.
  With navigation history: `/search`, `/search?q=d`, `/search?q=d&team=t1`,
  clicking internal back, will go to `/s/t1/<projectId>?folder=<folderId>`.
- You refresh browser page `/search`. `My Artdesk` icon is highlighted in team selector. You arrive on search page.
- You refresh browser page `/search?q=d&team=t1`.`t1` team icon is highlighted in team selector. Clicking internal back will navigate to `/s/t1`.
- You refresh browser page `/search?q=d&personal=true`. `My Artdesk` icon is highlighted in team selector. Clicking internal back will navigate to `/my`.
- You refresh browser page `/search`. Clicking internal back will navigate to `/my`.
- Typing in search input: there is debounceTime 700ms for setting q from queryParams in address bar.
- Typing in search input: there is debounceTime 700ms for sending search request to server with new q.
- You are on `/search?q=d&team=t1`. You go inside some folder in search results. Browser back will get you to `/search?q=d&team=t1`.
- You are on `/search?q=d&team=t1`. You click on feedback link. Browser back will get you to `/search?q=d&team=t1` and left side bar will be visible.
- You are on `/search?q=d&team=t1`. Switching to team `t1` or `My Artdesk` works.
- You are on `/search?q=d&team=t1`. You click internal back or switch to some artspace. When going again to search page, you will not see previously set `params$`.
- You are on search page. Clicking browser back button goes to previous page and not search page again.
*/

@UntilDestroy()
@Injectable()
export class SearchBarService {
  place = '';
  q = '';
  lastActive = 0;
  comments = '';

  private prevUrl = '';

  constructor(
    private teamsQuery: TeamsQuery,
    private router: Router,
    private routerService: RouterService,
  ) {
    this.teamsQuery.selectActiveId().pipe(
      distinctUntilChanged(),
      untilDestroyed(this),
    ).subscribe(teamSlug => {
      if (!this.onSearchPage()) {
        this.place = teamSlug ?? '';
      }
    });
  }

  private onSearchPage() {
    return this.routerService.data$.value.page === 'search';
  }
  
  reset() {
    this.q = '';
    this.place = '';
    this.comments = '';
    this.lastActive = 0;
  }

  setQ(q: string) {
    this.q = q || '';

    if (this.q) {
      this.navigateDebounced();
    } else {
      this.navigateFromSearch();
    }
  }

  commit() {
    this.navigateToSearch();
  }

  clearQ() {
    this.q = '';
    this.navigateFromSearch();
  }

  // `place` - '' or 'artdesk' or team slug
  setPlace(place: string) {
    this.place = place || '';

    if (this.onSearchPage()) {
      this.navigateToSearch();
    }
  }

  private navigateTimeout: any;

  private navigateDebounced() {
    clearTimeout(this.navigateTimeout);
    this.navigateTimeout = setTimeout(() => this.navigateToSearch(), 500);
  }

  private navigateToSearch() {
    clearTimeout(this.navigateTimeout);

    if (!this.onSearchPage()) {
      this.prevUrl = this.router.url;
    }

    const queryParams: any = { q: this.q };
    if (this.place) queryParams.place = this.place;
    if (this.lastActive) queryParams.lastActive = this.lastActive;
    if (this.comments) queryParams.comments = this.comments;
    void this.router.navigate(['search'], { queryParams });
  }

  private navigateFromSearch() {
    clearTimeout(this.navigateTimeout);

    if (this.prevUrl) {
      void this.router.navigateByUrl(this.prevUrl);
      this.prevUrl = '';
    } else {
      void this.router.navigate(['my', 'artworks']);
    }
  }

  setLastActive(lastActive: number) {
    this.lastActive = lastActive || 0;
    this.navigateToSearch();
  }

  setComments(comments: string) {
    this.comments = comments || '';
    this.navigateToSearch();
  }
}
