import { belowBreakpointSM } from 'components/utils';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EntityData } from 'shared/interfaces';
import { cloneDeep } from 'lodash';
import { faChevronRight, faFolder, faPencil, faPlus, farEllipsisH, farEllipsisV } from 'magma/common/icons';

export interface Crumb extends Pick<EntityData, 'name' | 'depth'> {
  _id?: string;
  type: string;
  link: {
    url: string;
    query?: { [key: string]: string; };
  } | null;
}

@UntilDestroy()
@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  dragOverCrumb: Crumb | null = null;
  showDropdown = false;
  faChevronRight = faChevronRight;
  farEllipsisH = farEllipsisH;
  farEllipsisV = farEllipsisV;
  faPencil = faPencil;
  faFolder = faFolder;
  faPlus = faPlus;
  belowBreakpointSM = belowBreakpointSM;

  @Input() threshold = 4;
  @Input() trailers = 2;

  _crumbs: Crumb[] = [];
  visibleCrumbs: Crumb[] = [];
  firstCrumb: Crumb | null = null;
  crumbsForDropdown: Crumb[] = [];

  @Input() set crumbs(crumbs: Crumb[]) {
    this._crumbs = crumbs;
    this.visibleCrumbs = this.getVisibleCrumbs(crumbs);
    this.firstCrumb = this.getFirstCrumb(crumbs);
    this.crumbsForDropdown = this.getCrumbsForDropdown(crumbs);
  }
  get crumbs() {
    return this._crumbs;
  }

  @Input() highlightDropZones = false;
  @Input() showIcons = false;
  @Input() anchorLeaf = false;
  @Input() showFirstCrumb = false;
  @Output() onDrop = new EventEmitter<Crumb>();

  getFirstCrumb(crumbsData: Crumb[]): Crumb | null {
    const crumbs = cloneDeep(crumbsData);
    if (crumbs && crumbs.length > 0) {
      return crumbs[0];
    }
    return null;
  }

  getCrumbsForDropdown(crumbsData: Crumb[]) {
    const crumbs = cloneDeep(crumbsData);
    if (crumbs && crumbs.length > this.threshold) {
      if (this.showFirstCrumb) {
        crumbs.splice(0, 1);
      }
      crumbs.splice(crumbs.length - this.trailers, this.trailers);
      return crumbs;
    }
    return [];
  }

  getVisibleCrumbs(crumbsData: Crumb[]) {
    const crumbs = cloneDeep(crumbsData);
    if (crumbs) {
      if (crumbs.length <= this.threshold) {
        crumbs.splice(0, 1);
      } else if (crumbs.length > this.threshold) {
        crumbs.splice(0, crumbs.length - this.trailers);
      }

      return crumbs;
    }
    return [];
  }

  dropped(crumb: Crumb) {
    if (this.highlightDropZones) {
      this.onDrop.emit(crumb);
    }
  }
}
