import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContainerScrollEventService {
  private approachingBottomSubject = new Subject<void>();
  approachingBottom$ = this.approachingBottomSubject.asObservable();

  approachingBottom() {
    this.approachingBottomSubject.next();
  }
}
