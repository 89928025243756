import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaymentService, redirectAfterCheckout } from 'services/payment.service';

@UntilDestroy()
@Component({
  selector: 'payment-validator',
  templateUrl: './payment-validator.component.pug',
  styleUrls: ['./payment-validator.component.scss'],
})
export class PaymentValidatorComponent implements OnInit {
  loadComplete = false;
  confirmationFailure = false;
  pollCount = 0;
  timeToRedirect = 10;
  productName = APP_NAME;
  private sessionId: string | undefined;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService
  ) {
    this.sessionId = route.snapshot.queryParams.sessionId;
  }

  ngOnInit() {
    this.startPoll();
  }

  private startPoll() {
    this.paymentService.checkPaymentAwaiting(this.sessionId)
      .pipe(untilDestroyed(this))
      .subscribe(exists => {
        if (!exists) {
          this.pollCount++;
          if (this.pollCount > 3) {
            this.confirmationFailure = true;
            this.redirectOnFailure();
            return;
          }
          setTimeout(() => this.startPoll(), 2000);
        } else {
          this.showPaymentComplete();
          setTimeout(() => this.redirectOnSuccess(), 2000);
        }
      });
  }

  private redirectOnFailure() {
    if (this.timeToRedirect === 1) {
      location.replace(redirectAfterCheckout() ?? '/my');
      return;
    }
    setTimeout(() => {
      this.timeToRedirect--;
      this.redirectOnFailure();
    }, 1000);
  }

  showPaymentComplete() {
    this.loadComplete = true;
  }

  private redirectOnSuccess() {
    void this.router.navigateByUrl(redirectAfterCheckout() ?? '/my');
  }
}
