import { Component, Input } from '@angular/core';
import { farEllipsisH, farEllipsisV } from 'magma/common/icons';
import { ContextMenu } from 'magma/components/shared/directives/contextMenu';
import { RecentParticipationService } from 'services/recent-participation.service';

@Component({
  selector: 'recent-entity-action-toolbar',
  templateUrl: './recent-entity-action-toolbar.component.pug',
  styleUrls: ['./recent-entity-action-toolbar.component.scss'],
})
export class RecentEntityActionToolbarComponent {
  readonly farEllipsisH = farEllipsisH;
  readonly farEllipsisV = farEllipsisV;

  @Input() participationId!: string;
  expanded = false;
  clicked = false;

  constructor(private recentParticipationService: RecentParticipationService) {
  }

  async action(menu: ContextMenu) {
    menu.close();
    await this.recentParticipationService.leave(this.participationId);
  }
}
