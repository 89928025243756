import { ProjectQuery } from 'services/projects.query';
import { TeamMembersService } from 'services/team-members.service';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ClrDatagrid, ClrDatagridColumnToggle, ClrDatagridSortOrder, ClrDatagridComparatorInterface } from '@clr/angular';
import { ColumnsService } from '@clr/angular/data/datagrid/providers/columns.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { saveInLocalStorage } from 'shared/decorators';
import { remove } from 'lodash';
import natsort from 'natsort';
import { faCommentAlt, faFilm, faKey, faPlus, farShareAltSquare } from 'magma/common/icons';
import { fromEvent, merge, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap, auditTime, skip } from 'rxjs/operators';
import { PresenceStateService } from 'services/presence-state.service';
import { TeamsQuery } from 'services/team.query';
import { DocumentStatuses, SortBy } from 'shared/constants';
import { EntityData, EntityType } from 'shared/interfaces';
import { getDefaultName, getEntityPath, getQueryParamsForFolder } from 'shared/product-info';
import { UserPresence } from 'shared/rpc-interface';
import { createThumbPath, pathToProject } from 'shared/utils';
import { belowBreakpointLG, belowBreakpointMD } from 'components/utils';
import { ProjectService } from 'services/projects.service';
import { setOgEntityName } from 'util/util';
import { pushOpenedBy } from 'magma/common/clientUtils';
import { EntityDragDropNotificationService } from 'services/entity-drag-drop-notification.service';
import { EntityToolbarButton } from '../../util/entities-toolbar';
import { TeamService } from 'services/team.service';
import { Permission } from 'magma/common/interfaces';
import { RouterService } from 'services/router.service';
import { ContextMenu } from 'magma/components/shared/directives/contextMenu';
import { EntitiesService } from 'services/entities.service';
import moment from 'moment';
import { ManageService } from 'magma/services/manageService';

export interface EntityGridToolbarEvent {
  button: EntityToolbarButton;
  entity: EntityData | EntityData[];
}

interface MouseEventWithEntity {
  event: MouseEvent;
  entity: EntityData;
}

class EntityNameComparator implements ClrDatagridComparatorInterface<EntityData> {
  sorter = natsort({ insensitive: true });
  compare(a: EntityData, b: EntityData) {
    return this.sorter(a.name, b.name);
  }
}

@UntilDestroy()
@Component({
  selector: 'entity-grid',
  templateUrl: './entity-grid.component.pug',
  styleUrls: ['./entity-grid.component.scss'],
  host: { class: 'use-clarity-styles' },
})
export class EntityGridComponent implements AfterViewInit {
  private resizeSubject$ = new Subject<void>();

  readonly farShareAltSquare = farShareAltSquare;
  readonly passwordIcon = faKey;
  readonly faFilm = faFilm;
  readonly entityType = EntityType;
  readonly faCommentAlt = faCommentAlt;
  readonly faPlus = faPlus;

  SortBy = SortBy;

  mouseDownSubject$ = new Subject<MouseEventWithEntity>();
  mouseUpSubject$ = new Subject<MouseEventWithEntity>();

  actionToolbarVisibleFor: string | null = null;
  selectedEntities: EntityData[] = [];
  onDragOverId = '';
  dragTop = 0;
  dragLeft = 0;
  markedForSelection: EntityData | null = null;
  clickPosition: { x: number, y: number } | null = null;
  moveForDragThreshold = 10;
  supportedEntities = PRODUCT_INFO.entities; // TODO: make it an input
  dragEntityCount = 0;
  dragTitle = '';
  clickWasInside = false;
  nameComparator = new EntityNameComparator();

  roles$ = this.teamMemberService.roles$;

  @saveInLocalStorage(0) sortedByName!: number;
  @saveInLocalStorage(0) sortedByModifiedAt!: number;
  @saveInLocalStorage(-1) sortedByOpenedAt!: number;
  @saveInLocalStorage(0) sortedByCreatedAt!: number;
  @saveInLocalStorage(0) sortedByRemovedAt!: number;
  @saveInLocalStorage(0) sortedBySize!: number;
  @saveInLocalStorage(50) drawingsPerPage!: number | string;
  @saveInLocalStorage(true) showSizeColumn!: boolean;
  @saveInLocalStorage(false) showCreatedColumn!: boolean;
  @saveInLocalStorage(false) showUpdatedColumn!: boolean;
  @saveInLocalStorage(true) showOpenedColumn!: boolean;

  @Input() presence: Map<string, Observable<UserPresence[]>> | null = null;
  @Input() enableDragDrop = false;
  @Input() showBreadcrumb = false;
  @Input() showDocumentStatus = false;
  @Input() allowCreatingEntityWhenEmpty = true;
  @Input() isGridReadOnly = false;
  @Input() highlightItem = '';
  @Input() isSearchPage = false;
  @Input() fetchingData = false;
  @Input() canFetchMore = false;
  @Input() isBinFolder = false;
  @Input() reachedStorageLimit = false;

  @Output() sortBy = new EventEmitter<SortBy>();
  @Output() dropped = new EventEmitter<EntityData>();
  @Output() entityAction = new EventEmitter<EntityGridToolbarEvent>();
  @Output() documentStatusChanged = new EventEmitter<{ entity: EntityData, status: DocumentStatuses }>();

  @ViewChild('dataGridContainer', { static: true }) dataGridContainer: ElementRef | null = null;
  @ViewChild('dataGrid', { static: true }) dataGrid: ClrDatagrid | null = null;
  @ViewChild('columnToggle', { static: true }) columnToggle: ClrDatagridColumnToggle | null = null;
  private binFolder: HTMLElement | null = null;

  constructor(
    private router: Router,
    private routerService: RouterService,
    private activatedRoute: ActivatedRoute,
    private teamsQuery: TeamsQuery,
    private projectService: ProjectService,
    public dragDropService: EntityDragDropNotificationService,
    private teamService: TeamService,
    private teamMemberService: TeamMembersService,
    private entitiesService: EntitiesService,
    private projectQuery: ProjectQuery,
    private manage: ManageService,
    _presenceStateService: PresenceStateService,// included to force tracking presence
  ) {
    this.showSizeColumn = true;

    this.dragDropService.isDragging$
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this),
      ).subscribe(isDragging => {
        if (isDragging) {
          this.dragDropService.dragStartSubject.next(this.selectedEntities);
          this.dragEntityCount = this.selectedEntities.length;
          this.dragTitle = this.selectedEntities[0]?.name ?? '';
        } else {
          this.clearSelections();
        }
      });

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(() => {
        this.clearSelections();
      }),
      untilDestroyed(this),
    ).subscribe();

    this.setupDragAndDropEvents();
  }

  get isReadOnly() {
    return this.teamMemberService.isPermissionFlagSet([Permission.CanUpdateEntities]);
  }

  folderId$ = this.activatedRoute.queryParams.pipe(map(params => params.folder));
  folder$ = this.folderId$.pipe(switchMap(folderId => folderId ? this.entitiesService.get(folderId) : of(undefined)));

  activeTeam$ = this.teamsQuery.selectActive();
  teamId$ = this.activeTeam$.pipe(map(team => team?._id));
  projectId$ = this.projectService.projectId$;

  get project() {
    return this.projectQuery.getActive();
  }

  @HostListener('click')
  clickInside() {
    this.clickWasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.clickWasInside) {
      this.clearSelections();
      this.markedForSelection = null;
    }
    this.clickWasInside = false;
  }

  saveDrawingsPerPage(pageSize: string | number) {
    this.drawingsPerPage = pageSize;
  }

  setupDragAndDropEvents() {
    const mouseUpDocument$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(
      tap((event) => this.onDocumentMouseUp(event)),
      untilDestroyed(this),
    );

    const mouseUpEntityGrid$ = this.mouseUpSubject$.pipe(
      filter(({ event }) => this.filterGeneralDragging(event)),
      tap(({ event, entity }) => this.onMouseUp(event, entity)),
    );

    const mouseUp$ = merge(
      mouseUpDocument$,
      mouseUpEntityGrid$,
    ).pipe(tap(() => {
      this.dragDropService.isDragging$.next(false);
      this.clickPosition = null;
    }));

    const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
      filter(event => this.filterDragTreshold(event)),
      tap(event => this.onMouseMove(event)),
      takeUntil(mouseUp$),
    );

    this.mouseDownSubject$.pipe(
      filter(({ event }) => this.filterGeneralDragging(event)),
      tap(({ event, entity }) => this.onMouseDown(event, entity)),
      switchMap(() => mouseMove$),
      untilDestroyed(this),
    ).subscribe();
  }

  @Input()
  get entities() {
    return this._entities;
  }

  set entities(entityList: EntityData[]) {
    this._entities = entityList;

    if (this.highlightItem) {
      const foundEntity = this.entities?.find((entity) => entity._id === this.highlightItem);
      if (foundEntity) {
        this.selectEntity(foundEntity);
      }
    }
  }
  _entities: EntityData[] = [];

  ngAfterViewInit() {
    this.resizeSubject$.pipe(
      auditTime(300),
      untilDestroyed(this),
    ).subscribe(() => {
      this.dataGrid?.resize();
    });

    // HACK: https://github.com/vmware/clarity/issues/3427
    const columnsService: ColumnsService = (this.columnToggle as any).columnsService;
    merge(...columnsService.columns.filter(colState$ => colState$.value.hideable).map(
      (obs, index) =>
        obs.asObservable().pipe(skip(1), map(data => ({ ...data, index }))))
    ).pipe(untilDestroyed(this))
      .subscribe(state => {
        switch (state.index) {
          case 0: this.showSizeColumn = !state.hidden; break;
          case 1: this.showCreatedColumn = !state.hidden; break;
          case 2: this.showUpdatedColumn = !state.hidden; break;
          case 3: this.showOpenedColumn = !state.hidden; break;
        }
      });
    this.binFolder = document.getElementById('binFolder');
  }

  get participantNumber() {
    if (belowBreakpointMD()) {
      return 3; // 160px
    }
    if (belowBreakpointLG()) {
      return 4; // 180px
    }
    return 6;
  }

  @HostListener('window:resize')
  resize() {
    this.resizeSubject$.next();
  }

  entityTrackBy(index: number, item: EntityData) {
    return item._id;
  }

  getDefaultName(type: string) {
    return getDefaultName(type);
  }

  openIfDragDropDisabled(entity: EntityData) {
    if (!this.enableDragDrop) {
      this.openEntity(null, entity);
    }
  }

  openEntity(event: Event | null, entity: EntityData) {
    if (this.isBinFolder) return;
    event?.stopImmediatePropagation();

    if (entity.type !== EntityType.Folder) {
      setOgEntityName(entity.name!);
      pushOpenedBy(entity.shortId, 'my-artworks');
    }

    this.routerService.navigateToEntity(entity, 'entities-page');
  }

  private onMouseDown(event: MouseEvent, entity: EntityData) {
    this.markedForSelection = entity;

    window.getSelection()?.removeAllRanges();

    this.clickPosition = { x: event.clientX, y: event.clientY };
  }

  onMouseUpOnGrid(event: MouseEvent) {
    this.onDragOverId = '';
  }

  private onMouseUp(event: MouseEvent, entity: EntityData) {
    if (this.dragDropService.isDragging$.value && entity.type === EntityType.Folder && !this.isEntitySelected(entity)) {
      this.dropped.emit(entity);

      this.onDragOverId = '';
      this.clearSelections();

      return;
    }

    if (entity._id?.toString() === this.markedForSelection?._id?.toString()) {
      if (event.ctrlKey || event.metaKey) {
        if (!this.isEntitySelected(entity)) this.addEntityToSelectedEntitiesList(entity);
        else this.deselectEntity(entity);
      } else if (event.shiftKey) {
        const displayedEntities = document.getElementsByTagName('clr-dg-row');

        if (!this.entities) return;

        const lastSelectedEntity =
          this.selectedEntities && this.selectedEntities.length > 0 ?
            this.selectedEntities[this.selectedEntities.length - 1] :
            null;

        let lastSelectedEntityIndex = 0;
        let currentlySelectedEntityIndex = 0;

        if (lastSelectedEntity) {
          lastSelectedEntityIndex = [...displayedEntities].findIndex((el) => el.id === lastSelectedEntity._id);
          currentlySelectedEntityIndex = [...displayedEntities].findIndex((el) => el.id === entity._id);

          const startIndex = lastSelectedEntityIndex > currentlySelectedEntityIndex ?
            currentlySelectedEntityIndex :
            lastSelectedEntityIndex;
          const endIndex = lastSelectedEntityIndex > currentlySelectedEntityIndex ?
            lastSelectedEntityIndex :
            currentlySelectedEntityIndex;

          if (startIndex > -1 && endIndex > startIndex) {
            for (let index = startIndex; index <= endIndex; index++) {
              let correctIndex = this.entities.findIndex((entity) => entity._id === displayedEntities[index].id);
              this.addEntityToSelectedEntitiesList(this.entities![correctIndex]);
            }
          }
        } else {
          this.selectEntity(entity);
        }
      } else {
        this.selectEntity(entity);
      }
    }

    this.markedForSelection = null;
  }

  onMouseEnter(entity: EntityData) {
    if (this.enableDragDrop && this.dragDropService.isDragging$.value && entity.type === EntityType.Folder && !this.isEntitySelected(entity)) {
      this.onDragOverId = entity._id;
    }
  }

  onMouseLeave(entity: EntityData) {
    if (this.onDragOverId === entity._id) {
      this.onDragOverId = '';
    }
  }

  selectEntity(entity: EntityData) {
    this.selectedEntities = [entity];
  }

  addEntityToSelectedEntitiesList(entity: EntityData) {
    if (!this.isEntitySelected(entity)) {
      this.selectedEntities.push(entity);
    }
  }

  addRangeToSelectedEntitiesList(untilEntity: EntityData) {
    this.selectedEntities.push(untilEntity);
  }

  deselectEntity(entity: EntityData) {
    remove(this.selectedEntities, e => e._id === entity._id);
  }

  isEntitySelected(entity: EntityData) {
    return !!this.selectedEntities.some(e => e._id === entity._id);
  }

  filterDragTreshold(event: MouseEvent) {
    const xMoved = Math.abs(event.clientX - (this.clickPosition?.x ?? 0));
    const yMoved = Math.abs(event.clientY - (this.clickPosition?.y ?? 0));
    return xMoved > this.moveForDragThreshold || yMoved > this.moveForDragThreshold;
  }

  filterGeneralDragging(event: MouseEvent) {
    return this.enableDragDrop && event.button === 0;
  }

  onMouseMove(event: MouseEvent) {
    if (this.markedForSelection) {
      this.addEntityToSelectedEntitiesList(this.markedForSelection);
      this.markedForSelection = null;
    }
    this.dragDropService.isDragging$.next(true);
    this.dragLeft = event.clientX + 10;
    this.dragTop = event.clientY + 15;
    window.getSelection()?.removeAllRanges(); // Needed for Safari
  }

  hasParent(element: HTMLElement | null, htmlClassName: string): boolean {
    if (!element || element.classList.contains('datagrid-host')) {
      return false;
    }

    if (element.classList.contains(htmlClassName)) {
      return true;
    }

    return this.hasParent(element.parentElement, htmlClassName);
  }

  onDocumentMouseUp(event: MouseEvent) {
    const isMouseEventInsideDataGrid = this.dataGridContainer?.nativeElement.contains(event.target);
    const isMoveToBin = this.binFolder?.contains(event.target as Node);

    if (isMoveToBin) {
      this.onAction(this.selectedEntities, { id: 'bin' });
    }
    if (!isMouseEventInsideDataGrid) {
      this.clearSelections();
    }
  }

  onDocumentMouseDown(event: MouseEvent) {
    const isMouseEventInsideDataGrid = this.dataGridContainer?.nativeElement.contains(event.target);
    if (!isMouseEventInsideDataGrid) {
      this.clearSelections();
    }
  }

  @HostListener('document:keyup', ['$event'])
  onDocumentKeyUp(event: KeyboardEvent) {
    if (!this.enableDragDrop) {
      return;
    }

    if (event.key === 'Escape') {
      this.clearSelections();
      this.markedForSelection = null;
    }
  }

  isDroppable(entity: EntityData) {
    return entity.type === EntityType.Folder && !this.isEntitySelected(entity);
  }

  clearSelections() {
    this.selectedEntities = [];
  }

  onDocumentStatusChanged(entity: EntityData, status: DocumentStatuses) {
    this.documentStatusChanged.emit({ entity, status });
  }

  onAction(entity: EntityData | EntityData[], button: EntityToolbarButton, menu?: ContextMenu) {
    if (this.reachedStorageLimit) {
      if (button.id === 'clone' || button.id === 'restore') {
        void this.manage.reachedStorageLimit();
        return;
      }
    }
    this.entityAction.emit({ entity, button });
    if (!button.clickedTitle) menu?.close();
  }

  getPresence(entityId: string) {
    return this.presence?.get(entityId);
  }

  thumbPath(entity: EntityData) {
    // TODO: fix for team drawings
    return createThumbPath(entity.shortId, entity.cacheId);
  }

  onSortByChange(event: ClrDatagridSortOrder, sortBy: SortBy) {
    this.sortBy.emit(sortBy);
    this[sortBy] = event;
  }

  getTeamNameForEntity(entity: EntityData) {
    return this.teamService.getTeam(entity.team)?.name ?? 'My Artdesk';
  }

  getProjectNameForEntity(entity: EntityData) {
    return typeof entity.project === 'string' ? this.projectService.getProject(entity.project)?.name : entity.project?.name;
  }

  getTeamAvatar(teamId: string) {
    return this.teamService.getTeam(teamId)?.avatar;
  }

  getEntityPath(entity: EntityData) {
    return getEntityPath(entity, this.teamService.getTeam(entity.team));
  }

  getQueryParamsForFolder = getQueryParamsForFolder;

  getLocationPath(entity: EntityData) {
    const team = this.teamService.getTeam(entity.team);
    const project = typeof entity.project === 'string' ? entity.project : entity.project?._id;

    if (team && project) {
      return pathToProject(team.slug, project);
    } else {
      return `/my/${PRODUCT_INFO.home?.route}`;
    }
  }

  getQueryParamsForLocation({ folder }: EntityData) {
    return folder ? { folder } : {};
  }

  getDeletionDate(updateDate: Date) {
    const date = new Date(updateDate);
    date.setDate(date.getDate() + 30);
    return moment(date).diff(moment(), 'days');
  }

  openDeletedEntity(entity: EntityData) {
    if (entity.type === 'Folder') {
      this.entityAction.emit({ entity, button: { id: 'restore-folder' } });
    }
  }
}
