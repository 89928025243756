import { Component } from '@angular/core';
import { contactSupportIntercom } from 'magma/common/utils';
import { TeamsQuery } from 'services/team.query';

@Component({
  selector: 'account-feedback',
  templateUrl: './account-feedback.component.pug',
  styleUrls: ['./account-feedback.component.scss'],
})
export class AccountFeedbackComponent {
  constructor(private teamsQuery: TeamsQuery) {
  }

  get hasTeams() {
    return this.teamsQuery.getCount() > 0;
  }

  reportABug() {
    contactSupportIntercom('Reporting a bug', () => window.location.href = '/my/feedback/bugs');  }
}
