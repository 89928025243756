import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AiModel } from 'magma/common/aiInterfaces';
import { faChevronDown, faChevronRight, faEllipsisV, faFileImport } from 'magma/generated/fa-icons';
import { ModalService } from 'services/modal.service';
import { RpcService } from 'services/rpc.service';
import { ToastService } from 'magma/services/toast.service';
import { AiService } from 'services/ai.service';
import { generateAiThumbnailImageUrl } from 'util/util';
import { TeamsQuery } from 'services/team.query';
import { map } from 'rxjs';
import { UserService } from 'services/user.service';

@UntilDestroy()
@Component({
  selector: 'ai-models',
  templateUrl: './ai-models.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './ai-models.component.scss',
  ],
})
export class AiModelsComponent {
  readonly importIcon = faFileImport;
  readonly chevronDown = faChevronDown;
  readonly chevronRight = faChevronRight;

  generateAiThumbnailImageUrl = generateAiThumbnailImageUrl;

  readonly faEllipsisV = faEllipsisV;

  models: AiModel[] = [];
  openedModels = new Set<string>();


  constructor(private aiService: AiService, private modals: ModalService, private rpc: RpcService,
    private toastService: ToastService, private teamsQuery: TeamsQuery, private userService: UserService) { }

  get teamId() {
    return this.teamsQuery.getActive()?._id ?? null;
  }

  toggleVisibility(modelId: string) {
    this.openedModels.has(modelId) ? this.openedModels.delete(modelId) : this.openedModels.add(modelId);
  }

  async ngOnInit() {
    await this.refresh();
    this.rpc.isConnected$.pipe(untilDestroyed(this)).subscribe(status => {
      if (status) this.refresh().catch(e => DEVELOPMENT && console.error(e));
    });
  }

  async refresh() {
    this.models = await this.aiService.getAiModels(this.teamId ?? null);
  }

  async enableModel(model: AiModel, enabled: boolean) {
    try {
      await this.aiService.enableAiModel(model.rId, enabled);
      await this.refresh();
      this.toastService.success({ message: 'Successfully updated model' });
    } catch (e) {
      this.toastService.error({ message: 'Failed to update model', subtitle: e.message });
    }
  }

  async uploadModel(file: File) {
    try {
      if (!file) throw Error('Missing file');
      if (await this.modals.uploadAiModel(file)) {
        await this.refresh();
        this.toastService.success({ message: 'Successfully uploaded new model' });
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to upload model', subtitle: e.message });
    }
  }

  async edit(model: AiModel) {
    try {
      if (await this.modals.editAiModel(model)) {
        await this.refresh();
        this.toastService.success({ message: 'Successfully updated model' });
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to update model', subtitle: e.message });
    }
  }

  async delete(model: AiModel) {
    try {
      await this.aiService.deleteAiModel(model.rId);
      await this.refresh();
      this.toastService.success({ message: 'Successfully deleted model' });
    } catch (e) {
      this.toastService.error({ message: 'Failed to delete model', subtitle: e.message });
    }
  }

  isShared(model: AiModel) {
    if (model.team) {
      return model.team !== this.teamId;
    } else {
      return model.owner !== this.userService.userId;
    }
  }
}
