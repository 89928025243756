import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { akitaDevtools, enableAkitaProdMode } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DAY } from 'magma/common/constants';
import { delay } from 'magma/common/promiseUtils';
import { storageGetItem, storageGetJson, storageRemoveItem } from 'magma/services/storage';
import { distinct, filter, skip, take } from 'rxjs/operators';
import { AuthService } from 'services/auth.service';
import { ModalService } from 'services/modal.service';
import { UserService } from 'services/user.service';
import { EntitiesService } from 'services/entities.service';
import { RecentEntitiesStore } from 'services/recent-entities/recent-entities.store';
import { RouterService } from 'services/router.service';
import { ErrorReporterService } from 'services/error-reporter.service';
import { getCookie } from 'magma/common/cookies';
import { ErrorReporter } from 'magma/services/errorReporter';
import { TeamSelectorService } from 'services/team-selector.service';
import { SubscriptionService } from 'magma/services/subscription';
import { belowBreakpointXL, ServerConstant } from './utils';
import { AFTER_LOGIN_REDIRECT_QUERY_KEY, AFTER_LOGIN_REDIRECT_URL_KEY } from './login-signup-page.component';
import { ServerConstantVars, UserData } from 'shared/interfaces';
import { TeamService } from 'services/team.service';
import { isAnonymousName } from 'magma/common/userUtils';
import { signUps } from 'magma/common/data';

const drawerState = trigger('drawerState', [
  transition('void => *', [
    style({ transform: 'translate3d(-100%, 0, 0)' }),
    animate('0.2s ease-in-out', style({ transform: 'translate3d(0, 0, 0)' })),
  ]),
  transition('* => void', [
    style({ transform: 'translate3d(0, 0, 0)' }),
    animate('0.2s ease-in-out', style({ transform: 'translate3d(-100%, 0, 0)' })),
  ]),
]);

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss'],
  animations: [drawerState],
})
export class AppComponent implements OnInit {
  @ServerConstant('vars') vars!: ServerConstantVars;

  teamSelectorMode = '';
  private teamSelectorOpen = false;
  private isMobile = false;
  constructor(
    ngZone: NgZone,
    private router: Router,
    private userService: UserService,
    private modals: ModalService,
    private entitiesService: EntitiesService,
    private recentEntitiesStore: RecentEntitiesStore,
    private subscriptionService: SubscriptionService,
    private errorReporter: ErrorReporter,
    private teamSelectorService: TeamSelectorService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService, // listed for initialization - DO NOT REMOVE!
    _errorReporterService: ErrorReporterService, // listed for initialization - DO NOT REMOVE!
    _teamService: TeamService, // listed for initialization - DO NOT REMOVE!
  ) {
    if (DEVELOPMENT) {
      akitaDevtools(ngZone, {});
    } else {
      enableAkitaProdMode();
    }

    if (this.auth.hasAuthToken) {
      const urlAfterLogin = storageGetItem(AFTER_LOGIN_REDIRECT_URL_KEY);
      const queryParams = storageGetJson<{ [key: string]: any; }>(AFTER_LOGIN_REDIRECT_QUERY_KEY);
      storageRemoveItem(AFTER_LOGIN_REDIRECT_URL_KEY);
      storageRemoveItem(AFTER_LOGIN_REDIRECT_QUERY_KEY);

      if (urlAfterLogin) {
        void this.router.navigate([urlAfterLogin], { queryParams });
      } else if (queryParams) {
        void this.router.navigate([], { relativeTo: this.activatedRoute, queryParams, queryParamsHandling: 'merge' });
      }
    }

    this.routerService.data$.pipe(untilDestroyed(this)).subscribe(data => {
      const mode = data.teamSelector ?? '';
      if (this.teamSelectorMode !== mode) {
        this.teamSelectorMode = mode;
        if (this.teamSelectorMode !== 'toggle') {
          this.teamSelectorOpen = false;
        }
      }
    });

    this.teamSelectorService.toggled$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.teamSelectorMode === 'toggle' || this.isMobile) {
        this.teamSelectorOpen = !this.teamSelectorOpen;
      } else {
        this.teamSelectorOpen = false;
      }
    });

    this.resized();
  }

  get showTeamSelector() {
    // console.log('showTeamSelector', this.teamSelectorMode, this.teamSelectorOpen, this.isMobile);
    if (this.teamSelectorMode === 'always') return this.isMobile ? this.teamSelectorOpen : true;
    if (this.teamSelectorMode === 'toggle') return this.teamSelectorOpen;
    return false;
  }

  get showBackdrop() {
    return this.animateDrawer && this.teamSelectorOpen;
  }

  get animateDrawer() {
    return this.teamSelectorMode === 'toggle' || this.isMobile;
  }

  closeTeamSelector() {
    this.teamSelectorOpen = false;
  }

  @HostListener('window:resize')
  resized() {
    this.isMobile = belowBreakpointXL();
  }

  ngOnInit() {
    this.userService.user$.pipe(
      skip(1), // TODO: why is this here ?
      filter((user): user is UserData => !!user),
      distinct(user => user._id),
      untilDestroyed(this)
    ).subscribe(async () => {
      if (this.routerService.data$.value.noReload) return;

      // TODO: do not reload recent entities if we don't need them
      // reload recent entities store when user changes
      this.recentEntitiesStore.remove();
      this.entitiesService.getRecentEntities().pipe(take(1)).subscribe();

      // TODO: what is this doing ?
      const urlTree = this.router.parseUrl(this.router.url);
      const { queryParams } = urlTree;
      if (Object.keys(queryParams).length) {
        urlTree.queryParams = {};
        const url = urlTree.toString();
        await this.router.navigateByUrl('', { skipLocationChange: true });
        void this.router.navigate([url], { queryParams });
      }
    });

    // show signup modal
    this.userService.user$.pipe(take(1), untilDestroyed(this)).subscribe(async user => {
      if (!user) return;

      if (!IS_HOSTED && !user.hideNewFeatureNotifications && user.newFeature === 'brushes' && user.lastNewFeature !== 'brushes') {
        this.modals.announcement();
      }

      if (!IS_HOSTED && getCookie('magma-plan')) {
        // this will fail if user already has a subscription (even if it's an inactive unpaid subscription)
        this.subscriptionService.handleUpgrade()
          .catch(e => this.errorReporter.reportError('Upgrade to pro from magma-plan cookie failed', e));
      } else if (user.userType === 'anonymous' && !['/error', '/login', '/sigup'].includes(this.router.url)) {
        const lastSignUp = +(storageGetItem('sign-up-time2') as any) || 0;
        // TODO: move referral logic to product definition?
        const ref = getCookie('referral');

        while (ref !== 'aggie') {
          try {
            const enforceSignup = !IS_HOSTED && lastSignUp !== 0 && (Date.now() - lastSignUp) > DAY && !this.vars.allowAnonymousUsers;

            if (enforceSignup) {
              this.modals.signUp(true);
            } else if (isAnonymousName(user.name)) {
              if (signUps.includes('saml')) {
                window.location.href = '/login';
              } else {
                this.modals.signUp();
              }
            }
            break;
          } catch (e) {
            await delay(1000);
          }
        }
      }
    });
  }
}
