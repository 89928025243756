import { Component } from '@angular/core';
import { blazeIcon } from 'magma/common/icons';
import { ManageService } from 'magma/services/manageService';
import { ModalService } from 'services/modal.service';

@Component({
  templateUrl: 'whats-new.component.pug',
  styleUrls: ['whats-new.component.scss'],
  host: { class: 'use-magma-styles' },
})
export class WhatsNewComponent {
  readonly blazeIcon = blazeIcon;
  constructor(private modals: ModalService, private manage: ManageService) {
  }
  newBrushes() {
    this.modals.newBrushes();
  }
  get showBanner() {
    return !this.manage.startedToolTrial('brushes');
  }
}
