import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerConstant } from 'components/utils';
import { EntitiesQuery } from 'services/entities.query';
import { ProjectQuery } from 'services/projects.query';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { EntityData, Participant, ProjectData, TeamData, EntityType, TeamType, HiddenProjectsDescriptions, UserData } from 'shared/interfaces';
import { EntityDragDropNotificationService } from 'services/entity-drag-drop-notification.service';
import { storageGetBoolean, storageGetJson, storageRemoveItem, storageSetBoolean, storageSetJson } from 'magma/services/storage';
import { ModalService } from 'services/modal.service';
import {
  faClock, faHashtag, faPlus, faChevronDown, faChevronUp, faPlusCircle, faExclamationTriangle, faUserPlus, faCogs,
  faSignOutAlt, faPodcast, faInfoCircle, faGlobe, faKey, blazeIcon, sparkIcon, farExclamationTriangle,
  faTrash, faLockAlt, exclamationIcon, faBookAlt, faMegaphone, xMarkCircleIcon, aiTool
} from 'magma/common/icons';
import { UserService } from 'services/user.service';
import { EntityActions } from '@datorama/akita';
import { getLastProject, routeToProject, routeToTeam, userStorageLimit } from 'shared/utils';
import { combineLatest, of, timer } from 'rxjs';
import type { LicenseJson } from '@codecharm/license-tools';
import { ToastService } from 'magma/services/toast.service';
import { IAppNotificationService } from 'magma/services/app-notification.service.interface';
import { contactSupportIntercom, getAvatarPath } from 'magma/common/utils';
import { PresenceService } from 'services/presence.service';
import { OnlineUsersService } from 'services/online-users.service';
import { TeamMembersQuery } from 'services/team-members.query';
import { TeamMembersService } from 'services/team-members.service';
import { Feature, NOTIFICATION_ARTDESK_ID, Permission, TREE_ARTDESK_ID, ProjectType } from 'magma/common/interfaces';
import { ManageService } from 'magma/services/manageService';
import { BillingService } from 'services/billing.service';
import { PortalSubscriptionService } from '../../services/subscription.service';
import { MAX_FREE_PRIVATE_ARTSPACE_MEMBERS } from 'shared/billing';
import { ProjectService } from 'services/projects.service';
import { disableCreatingTeams, disableJoiningAndLeavingTeams, disableMyArtdesk } from 'magma/common/data';
import { FeatureFlagService } from 'services/feature-flag.service';
import { BlogService } from '../../services/blog.service';
import { COMMUNITY_HUB_TEAM_NAME } from '../../../../shared/posts';

const homeRouteName = PRODUCT_INFO.home.route;
const { storageReadMoreLink, enterpriseSalesLink, contactUsLink } = PRODUCT_INFO;
const discordInviteLink = PRODUCT_INFO.discordInviteLinks?.drawingBuddiesPage;

@UntilDestroy()
@Component({
  selector: 'team-selector',
  templateUrl: 'team-selector.component.pug',
  styleUrls: ['team-selector.component.scss'],
  host: { class: 'use-magma-styles' },
})
export class TeamSelectorComponent {
  TeamType = TeamType;
  NOTIFICATION_ARTDESK_ID = NOTIFICATION_ARTDESK_ID;
  TREE_ARTDESK_ID = TREE_ARTDESK_ID;
  MAX_FREE_PRIVATE_ARTSPACE_MEMBERS = MAX_FREE_PRIVATE_ARTSPACE_MEMBERS;
  @ServerConstant() readonly license?: LicenseJson;
  @Output() close = new EventEmitter<void>();
  readonly storageReadMoreLink = storageReadMoreLink;
  readonly discordInviteLink = discordInviteLink;
  readonly contactUsLink = contactUsLink;
  readonly enterpriseSalesLink = enterpriseSalesLink;
  readonly plusIcon = faPlus;
  readonly downIcon = faChevronDown;
  readonly upIcon = faChevronUp;
  readonly keyIcon = faKey;
  readonly globeIcon = faGlobe;
  readonly warningIcon = faExclamationTriangle;
  readonly warningIcon2 = farExclamationTriangle;
  readonly newProjectIcon = faPlusCircle;
  readonly addUserIcon = faUserPlus;
  readonly settingsIcon = faCogs;
  readonly signOutIcon = faSignOutAlt;
  readonly liveNowIcon = faPodcast;
  readonly recentIcon = faClock;
  readonly projectIcon = faHashtag;
  readonly infoIcon = faInfoCircle;
  readonly proIcon = blazeIcon;
  readonly freeIcon = sparkIcon;
  readonly trashIcon = faTrash;
  readonly faLock = faLockAlt;
  readonly exclamationIcon = exclamationIcon;
  readonly aiIcon = aiTool;
  readonly dangerIcon = xMarkCircleIcon;
  readonly whatsNewIcon = faMegaphone;
  readonly faGlobe = faGlobe;
  myDocumentsName = `${PRODUCT_INFO.home.name}`;
  myDocumentsRoute = `/my/${homeRouteName}`;
  activeTeam$ = this.teamsQuery.selectActive();
  teams$ = this.teamsQuery.selectAll();
  teamProjects$ = this.activeTeam$.pipe(
    switchMap((team) => {
      if (!team) return [];

      return this.projectsQuery.selectAll().pipe(
        map(projects => projects.filter(p => p.team === team._id).sort(byOrder)),
      );
    }),
  );
  liveCount = 0;
  isDragging = false;
  dragOverProject: ProjectData | 'my' | undefined = undefined;
  dragOverTeam: string | undefined = undefined;
  actions$ = this.entitiesQuery.selectEntityAction([EntityActions.Add, EntityActions.Remove, EntityActions.Update]);

  get storageLimit() {
    return getStorageLimit(this.userService.user, this.teamsQuery.getActive());
  }

  get storageUsed() {
    return getStorageUsed(this.userService.user, this.teamsQuery.getActive());
  }

  canCreateTeams = !disableCreatingTeams;
  canLeaveTeam = !disableJoiningAndLeavingTeams;
  canDeleteEntities = true;
  canViewTeamMembers = false;

  onlineMembers: Participant[] = [];
  offlineMembers: Participant[] = [];
  memberTotal = 0;
  notificationCounters: Map<string, number> = new Map();
  selectedEntities: EntityData[] = [];
  memberAvatars: string[] | undefined = undefined;
  enableHomeButtonTransition = false;

  roles$ = this.teamMembersService.roles$.pipe(map(roles => roles.filter(r => r.type !== 'contributor')));

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public teamsQuery: TeamsQuery,
    private teamMembersQuery: TeamMembersQuery,
    private userService: UserService,
    private projectsQuery: ProjectQuery,
    private dragDropService: EntityDragDropNotificationService,
    private modals: ModalService,
    private entitiesQuery: EntitiesQuery,
    private toastService: ToastService,
    private notificationService: IAppNotificationService,
    private presenceService: PresenceService,
    private onlineUsersService: OnlineUsersService,
    private teamMembersService: TeamMembersService, // do not remove
    private manage: ManageService,
    private teamService: TeamService, // do not remove
    private billingService: BillingService,
    private subscriptionService: PortalSubscriptionService,
    private projectService: ProjectService,
    private featureFlagService: FeatureFlagService,
    private blogService: BlogService
  ) {
    timer(10).pipe(untilDestroyed(this)).subscribe(() => {
      this.enableHomeButtonTransition = true;
    });

    this.notificationService.notificationCounters$.pipe(untilDestroyed(this)).subscribe((counters) => {
      this.notificationCounters = counters;
    });

    this.dragDropService.dragListener$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(team => {
      this.isDragging = team !== undefined;
    });

    this.teamsQuery.selectActive().pipe(
      map(team => team?._id),
      distinctUntilChanged(),
      switchMap(teamId => this.presenceService.monitorEntitiesCount(teamId)),
      untilDestroyed(this),
    ).subscribe(count => this.liveCount = count);

    combineLatest([
      this.teamsQuery.selectActive().pipe(
        map(team => team?._id),
        distinctUntilChanged(),
        switchMap(teamId => teamId ? this.onlineUsersService.observeContext(teamId) : of(new Set<string>())),
      ),
      this.teamMembersQuery.selectAll().pipe(
        map(members => members.map<Participant>(member => ({
          ...member.user,
          role: this.teamMembersService.isTeamOwner(member.user._id) ? 'owner' : 'all',
          isOnline: false,
        }))),
      ),
    ]).pipe(
      untilDestroyed(this),
    ).subscribe(([onlineList, members]) => {
      this.onlineMembers = members.filter(p => onlineList.has(p._id));
      this.onlineMembers.forEach(m => m.isOnline = true);
      this.offlineMembers = members.filter(p => !onlineList.has(p._id));
      this.offlineMembers.forEach(m => m.isOnline = false);
      this.memberTotal = members.length;

      const seats = [...members, ...Array(5).fill(undefined)].slice(0, 5);
      this.memberAvatars = seats.map(user => user ? getAvatarPath(user?.avatar, 24) : '');
    });

    this.dragDropService.dragStartSubject.pipe(untilDestroyed(this)).subscribe(list => {
      this.selectedEntities = list;
    });

    this.dragDropService.isDragging$.pipe(untilDestroyed(this)).subscribe((isDragging) => {
      if (!isDragging) {
        this.selectedEntities = [];
      }
    });

    // have to wait for teamMembersService.roles$, not teamService.active() because it creates race
    this.teamMembersService.roles$.pipe(untilDestroyed(this)).subscribe(() => {
      const team = this.teamsQuery.getActive();

      const can = () => {
        if (!team) return true;

        if (this.teamMembersService.isPermissionFlagSet(Permission.CanDeleteEntities)) return true;

        for (const project of team.projects || []) { // HACK: projects is sometimes not iterable for some reason
          if (this.teamMembersService.isPermissionFlagSet(Permission.CanDeleteEntities, project)) {
            return true;
          }
        }

        return false;
      };

      this.canDeleteEntities = can();
      this.canViewTeamMembers = team ? this.teamMembersService.isPermissionFlagSet(Permission.CanViewTeamMembers) : true;
    });
  }

  get teamType() {
    return this.activeTeam && this.billingService.getTeamType(this.activeTeam);
  }

  get isActiveTeamStarter() {
    return this.teamType === TeamType.Private || this.teamType === TeamType.Public;
  }

  get isPrivateTeam() {
    return this.teamType === TeamType.Private;
  }

  get noUserLimit() {
    return this.activeTeam?.featureFlags?.includes(Feature.NoUserLimit);
  }

  get isNotProPlusTeam() {
    return !this.activeTeam || this.isActiveTeamStarter;
  }

  get isSelfPaid() {
    return (this.user?.proSources?.length === 1) && (this.user?.proSources[0] === 'paid');
  }

  get isPro() {
    return !!this.user?.pro;
  }

  get isTeamPro() {
    return !!this.teamsQuery.getActive()?.pro;
  }

  get canManageTeam() {
    return this.teamMembersService.isPermissionFlagSet(Permission.CanManageTeam);
  }

  get canManageTeamBilling() {
    return this.teamMembersService.isPermissionFlagSet(Permission.CanManageTeamBilling);
  }

  get hasDreamboothFeature() {
    // TODO it will work only for user feature flags
    return this.featureFlagService.isFeatureSupported(Feature.AiDreambooth);
  }

  get activeTeam() {
    return this.teamsQuery.getActive();
  }

  get activeTeamIdForNotifications() {
    return this.activeTeam?._id ?? NOTIFICATION_ARTDESK_ID;
  }

  get activeTeamName() {
    if (this.isCommunityHub) {
      return COMMUNITY_HUB_TEAM_NAME;
    } else if (this.activeTeam?.name) {
      return this.activeTeam.name;
    } else {
      return 'My Artdesk';
    }
  }

  get hideResources() {
    return storageGetBoolean('hide-resources');
  }

  set hideResources(value) {
    storageSetBoolean('hide-resources', value);
  }

  get user() {
    return this.userService.user;
  }

  get usersClosed() {
    return storageGetBoolean('team-selector-users-closed');
  }
  set usersClosed(value) {
    storageSetBoolean('team-selector-users-closed', value);
  }

  get canReorderProjects() {
    return this.teamMembersService.isPermissionFlagSet(Permission.CanUpdateProjects);
  }

  async ngOnInit() {
    const url = this.router.url;
    if (url.includes('upgrade-storage') || storageGetBoolean('open-upgrade-storage')) {
      if (this.activeTeam) {
        this.upgradeTeamSubscription();
      } else {
        this.upgradeToPro('upgrade-storage');
      }
      storageRemoveItem('open-upgrade-storage');
    }
  }

  get storageIsFull() {
    return this.storageUsed >= this.storageLimit;
  }

  get storageAlmostFull() {
    const used = this.storageUsed;
    const limit = this.storageLimit;
    return used >= (limit * 0.9) && used < limit;
  }

  get storagePercentage() {
    const percentage = Math.floor(this.storageUsed * 100 / this.storageLimit);
    return percentage > 100 ? 100 : percentage;
  }

  get hasNoLimitStorage() {
    return !this.manage.isStorageLimitActive(this.activeTeam);
  }

  contactUs() {
    contactSupportIntercom('Business inquiries');
  }

  storageLimitReachedAlert(team?: TeamData) {
    if (!this.manage.isStorageLimitActive(team)) return false;

    const user = this.userService.user;
    const used = getStorageUsed(user, team);
    const limit = getStorageLimit(user, team);
    return used >= limit;
  }

  artspaceMarkedForDeletion(team: TeamData) {
    return !!team.deletionDate;
  }

  isTeamLocked(team: TeamData) {
    return team.requireSamlAuthentication;
  }

  async reorderProjects(projects: ProjectData[]) {
    if (!projects.length) return;
    await this.projectService.reorder(projects[0].team, projects.map(p => p._id));
  }

  isActiveProject(projectId: string) {
    const activeProject = this.projectsQuery.getActiveId() === projectId;
    const isCommunityHubProject = !!this.blogService.communityHubProjects.some(project => project._id === projectId);
    const urlMatch = this.router.url.includes(projectId);
    return (isCommunityHubProject || activeProject) && urlMatch;
  }

  droppedOn(project: ProjectData) {
    this.dragDropService.dragListener$
      .pipe(
        take(1),
        tap(() => this.dragDropService.dropped(project._id)),
        untilDestroyed(this),
      ).subscribe();
  }

  async droppedOnTeam(team: TeamData | null) {
    if (this.selectedEntities.length === 0) return;

    const selectedFolder = await this.modals.selectFolder({
      pickedFolderIds: this.selectedEntities.filter(e => e.type === EntityType.Folder).map(e => e._id),
      teamId: team?._id,
      showArtdesk: !team && !disableMyArtdesk,
    });

    if (selectedFolder) {
      this.dragDropService.dropped(selectedFolder.projectId, selectedFolder.folderId);
    }
  }

  createTeam() {
    void this.modals.createTeam();
  }

  openMyArtdesk() {
    this.openTeam(undefined);
  }

  openTeamSettings() {
    void this.router.navigate(routeToTeam(this.activeTeam!.slug, 'settings'));
    this.closeDrawer();
  }

  openTeamSettingsBilling() {
    void this.router.navigate(routeToTeam(this.activeTeam!.slug, ['settings', 'billing']));
    this.closeDrawer();
  }

  upgradeTeamSubscription() {
    if (!this.activeTeam) throw new Error('Trying to upgrade team subscription with no active team');
    const { _id: teamId, slug: teamSlug } = this.activeTeam;
    void this.modals.upgradeTeamSubscriptionModal({
      teamId,
      teamSlug,
      alreadyOnProPlan: !this.isActiveTeamStarter
    });
  }

  async manageSubscription() {
    void this.subscriptionService.manageSubscription();
    this.closeDrawer();
  }

  openTeam(team: TeamData | undefined) {
    if (team) {
      if (team.requireSamlAuthentication) {
        void this.router.navigate(routeToTeam(team.slug, 'reauthenticate'));
        this.closeDrawer();
        return;
      }

      const projectId = getLastProject(team.slug) ?? this.projectsQuery.getAll().find(p => p.team === team._id)?._id;
      if (projectId) {
        const projectEntity = this.projectsQuery.getEntity(projectId);
        void this.router.navigate(routeToProject(team.slug, projectEntity?.team === team._id ? projectId : undefined));
      } else {
        void this.router.navigate(routeToTeam(team.slug));
      }
    } else {
      void this.router.navigate(['/my']);
    }
  }

  async leaveTeam() {
    const team = this.activeTeam;
    if (!team) return;

    try {
      const isOwner = this.teamMembersService.isPermissionFlagSet([Permission.isTeamOwner]);
      const isLastOwner = isOwner && this.teamMembersService.countTeamMembersWithOwnerRole() <= 1;
      if (await this.modals.leaveTeam({ teamName: team.name, isLastOwner })) {
        if (isLastOwner) {
          void this.router.navigate(routeToTeam(team.slug, ['settings', 'members']));
        } else {
          await this.teamService.leaveTeam(team._id);
          void this.router.navigate(['/my']);
        }
      }
    } catch (e) {
      this.toastService.error({ message: `Failed to leave "${team.name}" artspace`, subtitle: e.message });
    }
  }

  async inviteUsers() {
    if (this.activeTeam) {
      await this.modals.inviteTeamMembers({
        team: this.activeTeam,
        canManageInvites: this.teamMembersService.isPermissionFlagSet([Permission.CanManageInvites]),
      });
    }
  }

  async createProject() {
    const team = this.activeTeam;
    if (team) {
      const project = await this.modals.createProject(team._id);
      if (project) {
        void this.router.navigate(routeToProject(team.slug, project._id));
        this.closeDrawer();
      }
    }
  }

  upgradeToPro(reason: string) {
    this.manage.upgrade(reason);
  }

  async newFolder() {
    const parentFolderId = this.activatedRoute.snapshot.queryParams.folder;
    const created = await this.modals.createNewFolder(parentFolderId);
    if (created) this.closeDrawer();
  }

  closeDrawer() {
    this.close.emit();
  }

  routeToProject(team: TeamData, project: ProjectData) {
    return routeToProject(team.slug, project._id);
  }

  routeToCommunityHub(projectId?: string) {
    if (!projectId) {
      return `/community-hub`;
    } else {
      return `/community-hub/${projectId}`;
    }
  }

  routeToRecent(team: TeamData) {
    return routeToTeam(team.slug, 'recent');
  }

  async openResourcesLinksModal() {
    this.modals.resourcesLinks();
  }

  isDescriptionHidden(projectId: string) {
    let hiddenProjects = storageGetJson<HiddenProjectsDescriptions>('hidden-project-descriptions');
    return hiddenProjects?.ids[projectId];
  }

  showProjectDescription(projectId: string) {
    let hiddenProjects = storageGetJson<HiddenProjectsDescriptions>('hidden-project-descriptions') ?? { ids: {} };
    delete hiddenProjects.ids[projectId];
    storageSetJson('hidden-project-descriptions', hiddenProjects);
  }

  contentPageStr = ProjectType.ContentPage;
  contentPageIcon = faBookAlt;
  get isCommunityHub() {
    return window.location.pathname.includes('community-hub');
  }
  get communityHubProjects() {
    return this.blogService.communityHubProjects;
  }
}

function byOrder<T extends { order?: number }>(a: T, b: T) {
  return (a.order ?? 0) - (b.order ?? 0);
}

function getStorageUsed(user: UserData | undefined | null, team: TeamData | undefined) {
  return team ? (team.storageUsage?.used ?? 0) : (user?.storageUsage ?? 0);
}

function getStorageLimit(user: UserData | undefined | null, team: TeamData | undefined) {
  return team ? (team.storageUsage?.limit ?? 1) : (user ? userStorageLimit(user) : 1);
}
